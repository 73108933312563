import * as React from 'react';
import { useStores } from '../../stores';
import { Tab, Tabs } from '@mui/material';
import { Publisher } from '../../domain/Publisher';
import { observer } from 'mobx-react-lite';
import { useParams } from "react-router-dom";
import { FieldServiceReport } from '../../domain/FieldServiceReport';
import FieldServiceReportDialog from '../../modules/publishers/dialogs/FieldServiceReportDialog';
import { useTranslation } from "react-i18next";
import { useConfirm } from 'material-ui-confirm';
import S4ReportsTable from './S4ReportsTable';
import S4ReportsGrid from './S4ReportsGrid';
import { useOpenDialog } from '../../hooks/useOpenDialog';

interface Props {
    publisherId?: number;
}

const S4ReportsComponent = observer((props: Props) => {
    const { t } = useTranslation();
    const confirm = useConfirm();
    const { publisherStore, reportStore } = useStores();
    const paramId = useParams().id;
    const publisherId = (paramId && +paramId) || props.publisherId;
    const [publisher, setPublisher] = React.useState<Publisher | undefined>();
    const [selectedReport, setSelectedReport] = React.useState<FieldServiceReport | undefined>();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [tab, setTab] = React.useState(0);

    React.useEffect(() => {
        publisherStore.getPublisher(publisherId!, (p) => {
            setPublisher(p);
            reportStore.getPublisherServiceReports(publisherId!);
        });
    }, [publisherStore, reportStore]);

    const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const handleReportClick = (id: number) => {
        const report = reportStore.serviceReports[publisherId!] &&
            reportStore.serviceReports[publisherId!]
                .find(report => report.id === id);
        setSelectedReport(report);
        openDialog("submit-report");
    }

    const deleteReport = (id: number) => {
        confirm({
            title: t("are_you_sure"),
            description: `${t("this_will_permanently_delete")}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            reportStore.deleteReport(id, publisherId!);
        });
    }

    const handleCloseDialog = () => {
        setSelectedReport(undefined);
        closeDialog();
    }

    const reports = (): FieldServiceReport[] => {
        return reportStore.serviceReports[publisherId!] ? reportStore.serviceReports[publisherId!] : [];
    }

    return (
        <>
            <Tabs value={tab} onChange={handleTabChange}>
                <Tab label={"S-21"} />
                <Tab label={t("list")} />
            </Tabs>
            <div style={{ backgroundColor: "white" }} role="tabpanel" hidden={tab !== 0}>
                <S4ReportsTable
                    reports={reports()}
                    editable={true}
                    onEdit={handleReportClick}
                    onDelete={deleteReport}
                />
            </div>
            <div role="tabpanel"
                style={{
                    backgroundColor: "white",
                    height: tab !== 1 ? 0 : 700,
                    visibility: tab !== 1 ? "hidden" : "visible" //workaround to avoid a datagrid+tab bug which crops the table after disabling 'hidden' attribute
                }}>
                <S4ReportsGrid
                    reports={reports()}
                    onEdit={handleReportClick}
                    onDelete={deleteReport}
                />
            </div>
            <FieldServiceReportDialog
                isOpen={isOpen("submit-report")}
                publisher={publisher!}
                report={selectedReport}
                onClose={handleCloseDialog} />
        </>
    );
});

export default S4ReportsComponent;