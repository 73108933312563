import * as React from 'react';
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { useTranslation } from "react-i18next";
import { LatLng } from 'leaflet';
import { Territory } from '../../../domain/Territory';
import TerritoryMap from '../map/TerritoryMap';
import { boundsId } from '../mapUtils';
import { withLazyMounting } from '../../../components/hoc/LazyDialog';

interface Props {
    territory: Territory;
    isOpen: boolean;
    onClose: () => void;
    onAssign?: (territory: Territory) => void;
}

const ShowTerritoryDialog = (props: Props) => {
    const { t } = useTranslation();
    const [openNotes, setOpenNotes] = React.useState(false);

    const handleAssign = () => {
        props.onClose();
        props.onAssign && props.onAssign(props.territory!);
    }

    const bounds = JSON.parse(props.territory.boundary) as LatLng[][];
    return (
        <Dialog open={props.isOpen} fullWidth={true} maxWidth="xl" onClose={props.onClose}>
            <Container maxWidth="xl">
                <DialogTitle>
                    {props.territory.title}
                    {(!!props.onAssign && props.territory) && (
                        <IconButton onClick={handleAssign}>
                            <AssignmentIndIcon />
                        </IconButton>)}
                </DialogTitle>
                <DialogContent>
                    <Box style={{ height: "60vh" }}>
                        <TerritoryMap
                            style={{ height: "100%", width: "100%" }}
                            polygons={bounds.map((arr: LatLng[]) => ({
                                id: boundsId(arr, props.territory),
                                bounds: arr,
                                territory: props.territory!
                            }))}
                            currentTerritory={props.territory}
                        />
                    </Box>
                </DialogContent>

                <DialogActions>
                    {props.territory.notes && <Button onClick={() => setOpenNotes(true)}>{t("notes")}</Button>}
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                </DialogActions>
            </Container>
            <Dialog open={openNotes} onClose={() => setOpenNotes(false)} >
                <DialogTitle>{t("notes")}</DialogTitle>
                <DialogContent>
                    <Typography component="pre">
                        {props.territory.notes}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenNotes(false)}>{t("close")}</Button>
                </DialogActions>
            </Dialog>
        </Dialog >
    );
};

export default withLazyMounting(ShowTerritoryDialog);