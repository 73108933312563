import { Table, TableBody, TableContainer } from '@mui/material';
import { ScheduleEntry } from '../../domain/ScheduleEntry';
import { ScheduleWeek } from '../../domain/ScheduleWeek';
import ScheduleTableBlockIntroHeader from './schedule-table/ScheduleTableBlockIntroHeader';
import ScheduleTableSingleRow from './schedule-table/ScheduleTableSingleRow';
import { prepareAssigned } from './utils/scheduleUtils';


interface Props {
    week?: ScheduleWeek;
    entries: ScheduleEntry[];
    editable?: boolean;
    onEntryUpdate?: (entry: ScheduleEntry) => void;
    onEntryEdit?: (entry: ScheduleEntry) => void;
    onEntryHistory?: (entry: ScheduleEntry) => void;
}

const ScheduleFieldServiceTab = (props: Props) => {
    const assigned = prepareAssigned(props.entries);
    return (
        <TableContainer>
            <Table size="small">
                <TableBody>
                <ScheduleTableBlockIntroHeader name={props.week && props.week.name} />
                    {props.entries.map((entry: ScheduleEntry) => (
                        <ScheduleTableSingleRow
                            key={entry.id}
                            entry={entry}
                            assigned={assigned}
                            editable={props.editable}
                            onEntryUpdate={props.onEntryUpdate}
                            onEntryEdit={props.onEntryEdit}
                            onEntryHistory={props.onEntryHistory}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ScheduleFieldServiceTab;