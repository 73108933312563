import * as React from 'react';
import { TextField } from '@mui/material';
import { ScheduleEntry } from '../../../domain/ScheduleEntry';
import PublisherTextField from './PublisherTextField';
import { useTranslation } from 'react-i18next';

interface Props {
    entry: ScheduleEntry;
    editable: boolean;
    assigned: number[];
    onEntryUpdate?: (entry: ScheduleEntry) => void;
}

const ScheduleSpeakerInput = (props: Props) => {
    const { entry } = props;
    const { t } = useTranslation();
    const [speakerText, setSpeakerText] = React.useState<string | undefined>(entry.customSpeaker);

    React.useEffect(() => {
        setSpeakerText(entry.customSpeaker);
    }, [entry.customSpeaker]);

    if (entry.customSpeaker && entry.customSpeaker.length > 0) {
        if (props.editable) {
            return (
                <TextField
                    margin="normal"
                    id="customSpeaker"
                    label={t("speaker")}
                    fullWidth
                    variant="standard"
                    value={speakerText || ''}
                    onChange={(e) => setSpeakerText(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            entry.customSpeaker = speakerText;
                            props.onEntryUpdate && props.onEntryUpdate(entry)
                        }
                    }}
                />
            )
        } else {
            return <span> {props.entry.customSpeaker} </span>
        }
    } else {
        let field = !!entry.impromptuId ? 'impromptu' : 'speaker';
        return (
            <PublisherTextField
                entry={props.entry}
                field={field}
                editable={props.editable}
                assigned={props.assigned}
                onEntryUpdate={props.onEntryUpdate}
            />
        )
    }
};

export default ScheduleSpeakerInput;