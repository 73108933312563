import { Dialog, DialogTitle, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useTranslation } from "react-i18next";
import { withLazyMounting } from '../../../components/hoc/LazyDialog';
import { AccountingEntry } from '../../../domain/AccountingEntry';
import moment from 'moment';

interface Props {
    isOpen: boolean;
    entry: AccountingEntry;
    onClose: () => void;
}

const AccountingShowEntryDialog = (props: Props) => {
    const { t } = useTranslation();

    return (
        <Dialog fullWidth open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("accounting_entry")}</DialogTitle>
            <Table size="small">
                <TableBody>
                    <TableRow >
                        <TableCell>{t("date")}</TableCell>
                        <TableCell><u>{moment(props.entry.date).format('DD.MM')}</u></TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell>{t("document")}</TableCell>
                        <TableCell>{props.entry.document}</TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell>{t("type")}</TableCell>
                        <TableCell>{`${t(props.entry.type)} / ${t(props.entry.side)}`}</TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell>{t("amount")}</TableCell>
                        <TableCell><b>{props.entry.amount}</b></TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell>{t("counterparty")}</TableCell>
                        <TableCell>{props.entry.counterparty}</TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell>{t("description")}</TableCell>
                        <TableCell>{props.entry.description}</TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell>{t("code")}</TableCell>
                        <TableCell>{props.entry.code}</TableCell>
                    </TableRow>
                    {props.entry.notes && <TableRow >
                        <TableCell>{t("notes")}</TableCell>
                        <TableCell>{props.entry.notes}</TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
        </Dialog>
    );
};

export default withLazyMounting(AccountingShowEntryDialog);