import { FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getLocale } from '../../../utils/localeUtils';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { CongregationInfo } from '../../../domain/CongregationInfo';

const workdays = ["monday", "tuesday", "wednesday", "thursday", "friday"];
const weekends = ["saturday", "sunday"];

interface Props {
    congrInfo: CongregationInfo[];
    formik: any;
}

const MeetingTimeTable = (props: Props) => {
    const { t } = useTranslation();

    const meetingWorkdayDay = props.congrInfo.find(info => info.name === "Meeting Workday Day");
    const meetingWorkdayTime = props.congrInfo.find(info => info.name === "Meeting Workday Time");
    const meetingWeekendDay = props.congrInfo.find(info => info.name === "Meeting Weekend Day");
    const meetingWeekendTime = props.congrInfo.find(info => info.name === "Meeting Weekend Time");

    const toDate = (timeString: string) => {
        const [hours, minutes] = timeString.split(':').map(Number);
        const now = new Date();
        now.setHours(hours, minutes, 0, 0);
        return now;
    };

    const toTimeStr = (date: Date) => moment(date).format("HH:mm");

    const value = (info: CongregationInfo) => (props.formik.values as any)[info.id] || '';

    const dateValue = (info: CongregationInfo) => toDate(value(info));

    return (
        <>
            <Typography sx={{ mb: 2 }} variant="h5" >
                {t("meeting_time")}
            </Typography>
            {meetingWorkdayDay && <FormControl sx={{ mb: 2 }} fullWidth>
                <InputLabel>{t(meetingWorkdayDay.name)}</InputLabel>
                <Select
                    value={value(meetingWorkdayDay)}
                    id={String(meetingWorkdayDay.id)}
                    name={String(meetingWorkdayDay.id)}
                    label={t(meetingWorkdayDay.name)}
                    onChange={props.formik.handleChange}
                >
                    {workdays.map(day => <MenuItem key={day} value={day}>{t(day)}</MenuItem>)}
                </Select>
            </FormControl>}
            {meetingWorkdayTime && <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                <TimePicker
                    label={t("Meeting Workday Time")}
                    ampm={false}
                    openTo='hours'
                    views={['hours', 'minutes']}
                    desktopModeMediaQuery={isMobile ? "Mobile" : "Desktop"}
                    value={dateValue(meetingWorkdayTime)}
                    onChange={value => {
                        props.formik.setFieldValue(String(meetingWorkdayTime.id), value && toTimeStr(value));
                    }}
                    renderInput={(params) => <TextField {...params} sx={{ mb: 2 }} fullWidth name="date" />}
                />
            </LocalizationProvider>}
            {meetingWeekendDay && <FormControl sx={{ mb: 2 }} fullWidth>
                <InputLabel>{t(meetingWeekendDay.name)}</InputLabel>
                <Select
                    value={value(meetingWeekendDay)}
                    id={String(meetingWeekendDay.id)}
                    name={String(meetingWeekendDay.id)}
                    label={t(meetingWeekendDay.name)}
                    onChange={props.formik.handleChange}
                >
                    {weekends.map(day => <MenuItem key={day} value={day}>{t(day)}</MenuItem>)}
                </Select>
            </FormControl>}
            {meetingWeekendTime && <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                <TimePicker
                    label={t("Meeting Weekend Time")}
                    ampm={false}
                    openTo='hours'
                    views={['hours', 'minutes']}
                    desktopModeMediaQuery={isMobile ? "Mobile" : "Desktop"}
                    value={dateValue(meetingWeekendTime)}
                    onChange={value => {
                        props.formik.setFieldValue(String(meetingWeekendTime.id), value && toTimeStr(value));
                    }}
                    renderInput={(params) => <TextField {...params} sx={{ mb: 2 }} fullWidth name="date" />}
                />
            </LocalizationProvider>}
        </>
    );
};

export default MeetingTimeTable;