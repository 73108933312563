import { TableCell, TableRow } from '@mui/material';
import { useTranslation } from "react-i18next";

interface Props {
    name?: string;
}

const ScheduleTableBlockIntroHeader = (props: Props) => {
    const { t } = useTranslation();

    return (
        <TableRow>
            <TableCell colSpan={1} style={{ color: "white", backgroundColor: "#000", fontSize: "1.3em" }}>{props.name}</TableCell>
            <TableCell colSpan={1} style={{ color: "white", backgroundColor: "#000" }} >{t("speaker")}</TableCell>
            <TableCell colSpan={1} style={{ color: "white", backgroundColor: "#000" }} >{t("companion")}</TableCell>
            <TableCell colSpan={1} style={{ color: "white", backgroundColor: "#000" }} ></TableCell>
        </TableRow>
    );
};

export default ScheduleTableBlockIntroHeader;