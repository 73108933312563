import * as React from 'react';
import Panel from '../panel/Panel';
import { useStores } from '../../stores';
import { Button, Container, LinearProgress, Paper } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import Can from '../../components/Can';
import { useOpenDialog } from '../../hooks/useOpenDialog';
import { AccountingEntry } from '../../domain/AccountingEntry';
import moment from 'moment';
import AccountingEntryDialog from './dialogs/AccountingEntryDialog';
import AccountingStartingPointDialog from './dialogs/AccountingStartingPointDialog';
import { AccountingStartingPoint } from '../../domain/AccountingStartingPoint';
import AccountingBoxEntryDialog from './dialogs/AccountingBoxEntryDialog';
import { isMobile } from 'react-device-detect';
import AccountingTable from './table/AccountingTable';
import AccountingDownloadReportToolbar from './AccountingDownloadReportToolbar';
import MobileAccountingTable from './table/mobile/MobileAccountingTable';
import AccountingSummaryDialog from './dialogs/AccountingSummaryDialog';

const AccountingPage = observer(() => {
    const { t } = useTranslation();
    const { serverStore, accountingStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [empty, setEmpty] = React.useState<boolean>(false);
    const [currentDate, setCurrentDate] = React.useState<Date>(moment().subtract(1, "month").toDate());
    const fileInput = React.useRef<HTMLInputElement>(null);

    const handleEmptyAccounting = (empty: boolean) => {
        setEmpty(empty);
    }

    const handleDateChange = (date: Date) => {
        setCurrentDate(date);
    }

    const handleSummaryOpen = () => {
        openDialog("summary-dialog");
    }

    const createStartingPoint = (startingPoint: AccountingStartingPoint) => {
        accountingStore.createStartingPoint(startingPoint, () => {
            closeDialog();
            setEmpty(false);
            accountingStore.getAll();
        });
    }

    const handleFileSelected = async (event: any) => {
        const file = event.target.files[0];
        accountingStore.uploadBankXml(file);
    }

    const saveNewEntries = (entries: AccountingEntry[]) => {
        accountingStore.saveBatch(entries);
    }

    const saveNewEntry = (entry: AccountingEntry) => {
        accountingStore.createEntry(entry);
    }

    const headerItems = [];
    if (empty) {
        headerItems.push(
            <Can key="add_starting_point" do="create" on='Accounting'>
                <Button type="button" color="inherit" onClick={() => { openDialog("starting-point-dialog") }} >
                    {t("add_starting_point")}
                </Button>
            </Can>
        );
    } else {
        if (!isMobile) {
            headerItems.push(
                <Can key="add_contribution_box_entries" do="create" on='Accounting'>
                    <Button type="button" color="inherit" onClick={() => { openDialog("contribution-box-dialog") }} >
                        {t("add_entry_from_contribution_box")}
                    </Button>
                </Can>
            );
            headerItems.push(
                <Can key="add_entry" do="create" on='Accounting'>
                    <Button type="button" color="inherit" onClick={() => { openDialog("new-entry-dialog") }} >
                        {t("add_entry")}
                    </Button>
                </Can>
            );
            headerItems.push(
                <Can key="import_xml" do="create" on='Accounting'>
                    <Button color="inherit" onClick={() => fileInput.current && fileInput.current.click()} >
                        {t("import_xml")}
                        <input ref={fileInput} type="file" hidden onChange={handleFileSelected} />
                    </Button>
                </Can>
            );
        } else {
            headerItems.push(
                <Can key="show_entry" do="read" on='Accounting'>
                    <Button type="button" color="inherit" onClick={handleSummaryOpen} >
                        {t("summary")}
                    </Button>
                </Can>
            );
        }
    }

    return (
        <Panel
            title={t("accounting")}
            headerItems={headerItems}
        >
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                {!isMobile && <AccountingDownloadReportToolbar date={currentDate} />}
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    {serverStore.serverRequestLoading && <LinearProgress />}
                    {isMobile ?
                        <MobileAccountingTable
                            date={currentDate}
                            onEmptyTable={handleEmptyAccounting}
                            onDateChange={handleDateChange}
                            onSummaryOpen={handleSummaryOpen}
                        /> :
                        <AccountingTable
                            date={currentDate}
                            onEmptyTable={handleEmptyAccounting}
                            onDateChange={handleDateChange}
                            onSummaryOpen={handleSummaryOpen}
                        />}
                </Paper>
            </Container>

            <AccountingStartingPointDialog
                isOpen={isOpen("starting-point-dialog")}
                onSubmit={createStartingPoint}
                onClose={closeDialog} />
            <AccountingBoxEntryDialog
                date={currentDate}
                isOpen={isOpen("contribution-box-dialog")}
                onSubmit={saveNewEntries}
                onClose={closeDialog} />
            <AccountingEntryDialog
                date={currentDate}
                isOpen={isOpen("new-entry-dialog")}
                onSubmit={saveNewEntry}
                onClose={closeDialog} />
            <AccountingSummaryDialog
                isOpen={isOpen("summary-dialog")}
                date={currentDate}
                onClose={closeDialog}
            />
        </Panel >
    );
});

export default AccountingPage;