import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { useStores } from '../../stores';
import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { Publisher } from '../../domain/Publisher';
import Panel from '../panel/Panel';

const CartsAnalysisPage = observer(() => {
    const { t } = useTranslation();
    const { publisherStore } = useStores();

    return (
        <Panel
            title={t("analysis")}
            headerItems={[]}>
            <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <List sx={{ width: '100%' }}>
                        {publisherStore.publishers && publisherStore.publishers
                            .filter((publ: Publisher) => publ.assignments.map(assign => assign.id).includes(6)) // cart assignment
                            .map((publ: Publisher) => {
                                return (
                                    <div key={publ.id}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>{publ.lastName.slice(0, 1) + publ.firstName.slice(0, 1)}</Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={`${publ.lastName} ${publ.firstName}`}
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            {publ.serviceGroup.name}
                                                        </Typography>
                                                        {publ.serviceAssignment && ` - ${t(publ.serviceAssignment)}`}
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                        <Divider variant="inset" component="li" />
                                    </div>
                                )
                            })}
                    </List>
                </Paper>
            </Container>
        </Panel>
    );
});

export default CartsAnalysisPage;