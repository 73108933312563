import { Collapse, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Box } from '@mui/system';
import { Publisher } from '../../../domain/Publisher';

interface Props {
    open: boolean;
    publishers: Publisher[];
    onPublisherEdit: (publisher: Publisher) => void;
}

const FieldServiceGroupPublishersTable = (props: Props) => {
    const { t } = useTranslation();

    return (
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={props.open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                        <Typography variant="h6" gutterBottom component="div">
                            {t("publishers")}
                        </Typography>
                        <Table size="small">
                            <TableBody>
                                {props.publishers.map((publisher, i) => (
                                    <TableRow key={publisher.id} onClick={() => props.onPublisherEdit(publisher)}>
                                        <TableCell>{i + 1}</TableCell>
                                        <TableCell>
                                            {`${publisher.lastName} ${publisher.firstName}`}
                                        </TableCell>
                                        <TableCell>{publisher.inactive && t("inactive")}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
};

export default FieldServiceGroupPublishersTable;