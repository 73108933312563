import { useStores } from '../../../stores';
import { Autocomplete, Box, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { ScheduleEntry } from '../../../domain/ScheduleEntry';
import { Publisher } from '../../../domain/Publisher';

interface Props {
    entry: ScheduleEntry;
    field: string;
    editable: boolean;
    assigned: number[];
    onEntryUpdate?: (entry: ScheduleEntry) => void;
}

const PublisherTextField = observer((props: Props) => {
    const { t } = useTranslation();
    const { publisherStore } = useStores();

    const { entry, field } = props;
    let options: Publisher[] = [];
    if (entry.assignmentId) {
        options = publisherStore.publishers.filter(publisher => {
            if (field === "companion") {
                if (entry.type === "ApplyYourself") {
                    return publisher.assignments.map(a => a.id).includes(9); // assistant
                } else if (entry.assignmentId === 13 || entry.assignmentId === 15) { // Watchtower/BibleStudyCongregation
                    return publisher.assignments.map(a => a.id).includes(5); // reader
                } else if (entry.assignmentId === 7) { // Chairman
                    return publisher.assignments.map(a => a.id).includes(4); // Prayer
                }
            }
            return publisher.assignments.map(a => a.id).includes(entry.assignmentId!);
        }).sort((a, b) => a.lastName.localeCompare(b.lastName))
    } else {
        options = publisherStore.publishers
            .filter(publisher => !publisher.inactive)
            .filter(publisher => {
                if (entry.participants === "elders") {
                    if (field === "companion") {
                        return publisher.gender === "male";
                    } else {
                        return publisher.appointments.some(appointment => appointment.id === 1);
                    }
                } if (entry.participants === "appointed") {
                    if (field === "companion") {
                        return publisher.gender === "male";
                    } else {
                        return publisher.appointments.some(appointment => appointment.id === 1 || appointment.id === 2);
                    }
                } else if (entry.participants === "brothers") {
                    return publisher.gender === "male";
                } else if (entry.participants === "sisters") {
                    return publisher.gender === "female";
                } else if (entry.participants === "no") {
                    return false;
                } else return true;
            }).sort((a, b) => a.lastName.localeCompare(b.lastName))
    }


    if (props.editable) {
        return (
            <Autocomplete
                size='small'
                disablePortal
                id={field}
                options={options}
                getOptionLabel={(publisher: Publisher) => publisher.firstName ? `${publisher.lastName} ${publisher.firstName}` : ''}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={(entry as any)[field]}
                renderOption={(htmlprops, publisher) => {
                    const style = props.assigned.includes(publisher.id) ? { color: "red" } : {};
                    return (
                        <Box key={publisher.id} component="li" style={style} {...htmlprops}>
                            {publisher.firstName ? `${publisher.lastName} ${publisher.firstName}` : ''}
                        </Box>
                    )
                }}
                renderInput={(params) => <TextField style={{ margin: "2px" }} {...params} margin="normal" label={t(field)} />}
                onChange={(e, publisher) => {
                    (entry as any)[`${field}Id`] = publisher?.id;
                    (entry as any)[field] = publisher!;
                    props.onEntryUpdate && props.onEntryUpdate(entry);
                }}
            />
        )
    } else {
        return (
            <span>
                {(entry as any)[field] ? `${(entry as any)[field].firstName} ${(entry as any)[field].lastName}` : ''}
            </span>
        )
    }
});

export default PublisherTextField;