import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Panel from '../../panel/Panel';
import { useStores } from '../../../stores';
import { Button, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import MapIcon from '@mui/icons-material/Map';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { Publisher } from '../../../domain/Publisher';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from "react-router-dom";
import PublisherForm from '../PublisherForm';
import FieldServiceReportDialog from '../dialogs/FieldServiceReportDialog';
import { FieldServiceReport } from '../../../domain/FieldServiceReport';
import S4ReportsComponent from '../../../components/s4reports/S4ReportsComponent';
import { useTranslation } from "react-i18next";
import { useConfirm } from "material-ui-confirm";
import GenerateS21Dialog from '../../../components/GenerateS21Dialog';
import PublisherSummary from './PublisherSummary';
import PublisherScheduleDialog from '../dialogs/PublisherScheduleDialog';
import { useOpenDialog } from '../../../hooks/useOpenDialog';
import PublisherTerritoriesDialog from '../dialogs/PublisherTerritoriesDialog';

const PublisherDetailsPage = observer(() => {
    const { t } = useTranslation();
    const { publisherStore, reportStore, panelStore } = useStores();
    const confirm = useConfirm();
    const navigate = useNavigate();
    const id = Number(useParams().id);
    const [publisher, setPublisher] = React.useState<Publisher | undefined>();
    const [selectedReport, setSelectedReport] = React.useState<FieldServiceReport | undefined>();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();

    React.useEffect(() => {
        publisherStore.getPublisher(id!, (p) => {
            setPublisher(p);
            reportStore.getPublisherServiceReports(id!);
            panelStore.setPublishersPageCurrent(p.id);
        });
    }, [id]);

    const handlePublisherSubmit = (publisher: Publisher) => {
        publisherStore.updatePublisher(id, publisher, () => {
            publisherStore.getPublisher(id!, (p) => {
                setPublisher(p);
                publisherStore.getAllPublishers();
            });
        });
    }

    const handleCloseDialog = () => {
        setSelectedReport(undefined);
        closeDialog();
    }

    const handleDeactivateClick = () => {
        confirm({
            title: t("are_you_sure"),
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            publisherStore.deactivatePublisher(id, () => {
                navigate('/publishers')
            });

        });
    }

    const handleDeleteClick = () => {
        confirm({
            title: t("are_you_sure"),
            description: t("this_will_permanently_delete_the_publisher"),
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            publisherStore.deletePublisher(id);
            navigate('/publishers');
        });
    }

    const goNext = () => {
        const index = publisherStore.publishers
            .map(publ => publ.id)
            .indexOf(panelStore.publishersPage.current);
            
        if (publisherStore.publishers.length > index + 1) {
            const nextPublisher = publisherStore.publishers[index + 1];
            navigate(`/publishers/${nextPublisher.id}`);
        }
    }

    const goPrevious = () => {
        const index = publisherStore.publishers
            .map(publ => publ.id).indexOf(panelStore.publishersPage.current);
        if (index - 1 >= 0) {
            const nextPublisher = publisherStore.publishers[index - 1];
            navigate(`/publishers/${nextPublisher.id}`);
        }
    }

    const headerItems = [
        <Button
            key="next_btn"
            type="button"
            color="inherit"
            onClick={goPrevious}>
            {t("prev.")}
        </Button>,
        <Button
            key="prev_btn"
            type="button"
            color="inherit"
            onClick={goNext}>
            {t("next.")}
        </Button>
    ]

    return (
        <Panel title={t("publisher_details")} headerItems={headerItems}>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={6}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} >
                            {publisher &&
                                <PublisherForm
                                    publisher={publisher}
                                    onSubmit={handlePublisherSubmit}>
                                    <Button color="primary" variant="contained" fullWidth type="submit">
                                        {t("submit")}
                                    </Button>
                                </PublisherForm>}

                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={4} lg={6}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} >
                            <PublisherSummary publisher={publisher} />

                            <Button
                                variant="contained"
                                size="large"
                                color="success"
                                startIcon={<SummarizeOutlinedIcon />}
                                onClick={() => openDialog("open-s4")}>
                                {t("submit_a_report")}
                            </Button>
                            <Button
                                variant="contained"
                                size="large"
                                color="info"
                                startIcon={<SummarizeIcon />}
                                onClick={() => openDialog("generate-s21")}>
                                {t("download_s_21")}
                            </Button>
                            <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                startIcon={<CalendarTodayIcon />}
                                onClick={() => openDialog("open-schedule")} >
                                {t("schedule")}
                            </Button>
                            <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                startIcon={<MapIcon />}
                                onClick={() => openDialog("open-territories")} >
                                {t("territories")}
                            </Button>

                            <Button
                                variant="contained"
                                size="large"
                                color="warning"
                                startIcon={<ClearIcon />}
                                onClick={handleDeactivateClick}>
                                {t("deactivate_publisher")}
                            </Button>
                            <Button
                                variant="contained"
                                size="large"
                                color="error"
                                startIcon={<DeleteIcon />}
                                onClick={handleDeleteClick}>
                                {t("delete_publisher")}
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <S4ReportsComponent />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            {publisher && <GenerateS21Dialog
                isOpen={isOpen("generate-s21")}
                publisherId={publisher!.id}
                onClose={handleCloseDialog} />}
            {publisher && <FieldServiceReportDialog
                isOpen={isOpen("open-s4")}
                publisher={publisher!}
                report={selectedReport}
                onClose={handleCloseDialog} />}
            {publisher && <PublisherScheduleDialog
                isOpen={isOpen("open-schedule")}
                publisherId={publisher!.id}
                onClose={handleCloseDialog} />}
            {publisher && <PublisherTerritoriesDialog
                isOpen={isOpen("open-territories")}
                publisherId={publisher!.id}
                onClose={handleCloseDialog} />}
        </Panel >
    );
});

export default PublisherDetailsPage;