import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Panel from '../panel/Panel';
import { useStores } from '../../stores';
import { Button, LinearProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { User } from '../../domain/User';
import { useTranslation } from "react-i18next";
import Can from '../../components/Can';
import NewUserDialog from './dialogs/NewUserDialog';
import UpdateUserDialog from './dialogs/UpdateUserDialog';
import { useOpenDialog } from '../../hooks/useOpenDialog';
import { useConfirm } from 'material-ui-confirm';
import SendPushNotificationDialog from './dialogs/SendPushNotificationDialog';
import { isMobileOnly } from 'react-device-detect';
import MobileUsersTable from './MobileUsersTable';
import UsersTable from './UsersTable';

const UsersPage = observer(() => {
    const { t } = useTranslation();
    const { serverStore, userStore, publisherStore } = useStores();
    const confirm = useConfirm();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [selectedUser, setSelectedUser] = React.useState<User>();

    React.useEffect(() => {
        userStore.getAllUsers();
    }, [userStore]);

    const close = () => {
        setSelectedUser(undefined);
        closeDialog()
    }

    const open = (type: string, user: User) => {
        setSelectedUser(user);
        openDialog(type);
    }

    const deleteUser = (user: User) => {
        confirm({
            title: t("are_you_sure"),
            description: `${t("this_will_permanently_delete")} ${user.username}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            userStore.deleteUser(user);
        });
    }

    return (
        <Panel
            title={t("users")}
            headerItems={[
                <Can key="new_user_btn" do="create" on='User'>
                    <Button
                        id="btn_new_user"
                        type="button"
                        color="inherit"
                        onClick={() => openDialog("new-user")}>
                        {t("add_new_user")}
                    </Button>
                </Can>
            ]}>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    {isMobileOnly ?
                        <MobileUsersTable
                            users={userStore.users || []}
                            publishers={publisherStore.publishers}
                            onUpdateUser={(user: User) => open("update-user", user)}
                            onPushNotificationSend={(user: User) => open("send-push", user)}
                        /> :
                        <UsersTable
                            users={userStore.users || []}
                            publishers={publisherStore.publishers}
                            onUpdateUser={(user: User) => open("update-user", user)}
                            onDeleteUser={deleteUser}
                            onPushNotificationSend={(user: User) => open("send-push", user)}
                        />}
                    {serverStore.serverRequestLoading && <LinearProgress />}
                </Paper>
            </Container>
            <NewUserDialog
                isOpen={isOpen("new-user")}
                onClose={close}
            />
            <UpdateUserDialog
                user={selectedUser!}
                isOpen={isOpen("update-user")}
                onClose={close}
            />
            <SendPushNotificationDialog
                user={selectedUser!}
                isOpen={isOpen("send-push")}
                onClose={close}
            />
        </Panel >
    );
});

export default UsersPage;