import { useTranslation } from "react-i18next";
import { ScheduleEntry } from '../../../../domain/ScheduleEntry';
import ScheduleTableMobileSingleRow from './ScheduleTableMobileSingleRow';

interface Props {
    entries: ScheduleEntry[];
    assigned: number[];
    editable?: boolean;
    onEntryUpdate?: (entry: ScheduleEntry) => void;
    onEntryEdit?: (entry: ScheduleEntry) => void;
    onEntryHistory?: (entry: ScheduleEntry) => void;
}

const ScheduleTableMobileCollapseRow = (props: Props) => {
    const { t } = useTranslation();
    return (
        <>
            {props.entries.map(entry => (
                <ScheduleTableMobileSingleRow
                    key={entry.id}
                    name={`${props.entries[0].name} (${t("number_hall", { number: entry.room })})`}
                    entry={entry}
                    assigned={props.assigned}
                    editable={props.editable}
                    onEntryUpdate={props.onEntryUpdate}
                    onEntryEdit={props.onEntryEdit}
                    onEntryHistory={props.onEntryHistory}
                />
            ))}
        </>
    );
};

export default ScheduleTableMobileCollapseRow;