import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { ServerStore } from './ServerStore';
import { AccountingEntry } from '../domain/AccountingEntry';
import { AccountingStartingPoint } from '../domain/AccountingStartingPoint';
import { AccountingSummary } from '../domain/AccountingSummary';
import { AccountingAnalysis } from '../domain/AccountingAnalysis';

export class AccountingStore {
    rootStore: RootStore;
    serverStore: ServerStore;
    entries: AccountingEntry[];

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        this.entries = [];
        makeAutoObservable(this);
    }

    createEntry = (entry: AccountingEntry, callback?: () => void) => {
        this.serverStore.post('/accounting', entry, action(() => {
            this.getAll();
            callback && callback();
        }));
    }

    updateEntry = (id: number, entry: AccountingEntry, callback?: () => void) => {
        this.serverStore.patch(`/accounting/${id}`, entry, action(() => {
            this.getAll();
            callback && callback();
        }));
    }

    saveBatch = (entries: AccountingEntry[], callback?: () => void) => {
        this.serverStore.post(`/accounting/save-batch`, entries, action(() => {
            setTimeout(() => {
                this.getAll();
                this.rootStore.notificationsStore.add('accounting_entries_are_updated', "success");
                callback && callback();
            }, 1000);

        }));
    }

    createStartingPoint = (startingPoint: any, callback: () => void) => {
        this.serverStore.post('/accounting/start', startingPoint, action(() => {
            this.getAll();
            callback();
        }));
    }

    getAll = (callback?: () => void) => {
        this.serverStore.get('/accounting', action((entries: AccountingEntry[]) => {
            this.entries = entries;
            callback && callback();
        }));
    }

    getStartingPoint = (date: string, callback: (startingPoint: AccountingStartingPoint) => void) => {
        this.serverStore.get('/accounting/start?date=' + date, action((startingPoint: AccountingStartingPoint) => {
            callback(startingPoint);
        }));
    }

    getSummary = (date: string, type: "month" | "year", callback: (summary: AccountingSummary) => void) => {
        const url = `/accounting/summary?date=${date}&type=${type}`;
        this.serverStore.get(url, action((summary: AccountingSummary) => {
            callback(summary);
        }));
    }

    getAnalysis = (callback: (analysis: AccountingAnalysis[]) => void) => {
        this.serverStore.get('/accounting/analysis', action((analysis: AccountingAnalysis[]) => {
            callback(analysis);
        }));
    }

    deleteEntry = (id: number) => {
        this.serverStore.delete(`/accounting/${id}`, action(() => {
            this.getAll();
        }));
    }

    uploadBankXml = (file: any, callback?: () => void) => {
        this.serverStore.upload('/accounting/upload/xml', file, () => {
            this.getAll();
            callback && callback();
        });
    }

    downloadS24 = (data: any) => {
        this.serverStore.download('/accounting/s-24', data);
    }

    downloadS26 = (data: any) => {
        this.serverStore.download('/accounting/s-26', data);
    }

    downloadS30 = (data: any) => {
        this.serverStore.download('/accounting/s-30', data);
    }

    downloadTO62 = (data: any) => {
        this.serverStore.download('/accounting/to-62', data);
    }
}