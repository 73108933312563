import * as React from 'react';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Can from '../../../components/Can';
import { useTranslation } from "react-i18next";
import SideBarMenuCollapseItem from '../SideBarMenuCollapseItem';
import SideBarSubMenuItem from '../SideBarSubMenuItem';
import { AbilityContext } from '../../../components/Can';

const SideBarExtraMenu = () => {
    const { t } = useTranslation();
    const ability = React.useContext(AbilityContext);

    const isFilesMenuEnabled = process.env.REACT_APP_FILES_FEATURE_ENABLED === "true";
    const isAccountingMenuEnabled = process.env.REACT_APP_ACCOUNTING_FEATURE_ENABLED === "true";

    if (ability.can("read", "Knowledge") || ability.can("read", "Files") || ability.can("read", "Accounting")) {
        return (
            <SideBarMenuCollapseItem icon={<ControlPointIcon />} type="extra" text={t("extra")}>
                <Can do="read" on='Knowledge'>
                    <SideBarSubMenuItem id="side_menu_item_knowledge" to="/knowledge" text={t("knowledge")} />
                </Can>
                {isFilesMenuEnabled && <Can do="read" on='Files'>
                    <SideBarSubMenuItem id="side_menu_item_files" to="/files" text={t("files")} />
                </Can>}
                {isAccountingMenuEnabled && <Can do="read" on='Accounting'>
                    <SideBarSubMenuItem id="side_menu_item_accounting" to="/accounting" text={t("accounting")} />
                </Can>}
            </SideBarMenuCollapseItem>
        );
    } else return null;
}

export default SideBarExtraMenu;