import { Paper, Table, TableBody, TableContainer, Toolbar, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useStores } from '../../../stores';
import { TerritoryGroup } from '../../../domain/TerritoryGroup';
import TerritoryGroupTableRow from '../management/TerritoryGroupTableRow';
import { Territory } from '../../../domain/Territory';
import TerrioriesForRequestTable from './TerrioriesForRequestTable';
import { TerritoryRequest } from '../../../domain/TerritoryRequest';
import MyTerritoryRequestsTable from './MyTerritoryRequestsTable';

interface Props {
    requests: TerritoryRequest[];
    onTerritoryShow: (territory: Territory) => void;
    onTerritoryRequest: (territory: Territory) => void;
    onTerritoryRequestDeletion: (request: TerritoryRequest) => void;
}

const MobileTerritoryRequestingComponent = observer((props: Props) => {
    const { t } = useTranslation();
    const { territoryStore } = useStores();

    return (
        <>
            <Paper style={{ height: "50vh" }} sx={{ p: 2, display: 'flex', flexDirection: 'column' }} >
                <Toolbar>
                    <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div">
                        {t("territory_groups")}
                    </Typography>
                </Toolbar>
                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            {territoryStore.groups && territoryStore.groups
                                .filter(group => !group.hidden)
                                .map((group: TerritoryGroup) => (
                                    <TerritoryGroupTableRow
                                        key={group.id}
                                        group={group}
                                        editable={false}
                                    >
                                        <TerrioriesForRequestTable
                                            territories={group.territories || []}
                                            onTerritoryFocus={props.onTerritoryShow}
                                            onTerritoryRequest={props.onTerritoryRequest}
                                        />
                                    </TerritoryGroupTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Paper style={{ height: "30vh" }} sx={{ mt: 2, p: 2, display: 'flex', flexDirection: 'column' }} >
                <Toolbar>
                    <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div">
                        {t("my_requests")}
                    </Typography>
                </Toolbar>
                <MyTerritoryRequestsTable
                    requests={props.requests}
                    onTerritoryShow={props.onTerritoryShow}
                    onRequestDelete={props.onTerritoryRequestDeletion}
                />
            </Paper>
        </>
    );
});

export default MobileTerritoryRequestingComponent;