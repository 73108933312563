import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { TerritoryAssignment } from '../../../../domain/TerritoryAssignment';
import { useOpenDialog } from '../../../../hooks/useOpenDialog';
import { Territory } from '../../../../domain/Territory';

interface Props {
    history: TerritoryAssignment[];
    onTerritoryShow: (territory: Territory) => void;
}

const MobileTerritoryAssignmentHistoryTable = (props: Props) => {
    const { t } = useTranslation();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [selectedAssignment, setSelectedAssignment] = React.useState<TerritoryAssignment | undefined>();

    const handleOpenDialog = (assignment: TerritoryAssignment) => {
        setSelectedAssignment(assignment);
        openDialog("assignment-dialog");
    }

    const handleCloseDialog = () => {
        closeDialog();
        setSelectedAssignment(undefined);
    }

    const title = (assign: TerritoryAssignment) => `${assign.territory!.territoryGroup!.name} - ${assign.territory!.title}`;

    const publisher = (assign: TerritoryAssignment) => assign.publisher ? `${assign.publisher.lastName} ${assign.publisher.firstName}` : t("someone");

    const territory = (assign: TerritoryAssignment) => {
        const terrTitle = assign.territory?.title;
        const group = assign.territory?.territoryGroup && assign.territory?.territoryGroup.name;
        if (group) {
            return `${group} - ${terrTitle}`;
        }
        return terrTitle;
    }

    return (
        <>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell><b>{t("territory")}</b></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.history.map(assign => {
                        return <TableRow key={assign.id}>
                            <TableCell onClick={() => props.onTerritoryShow(assign.territory!)}>
                                {title(assign)}
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => handleOpenDialog(assign)}>
                                    <InfoIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
            {selectedAssignment && <Dialog open={isOpen("assignment-dialog")} fullWidth={true} onClose={closeDialog}>
                <DialogTitle>{t("territory")}</DialogTitle>
                <DialogContent>
                    <div><b>{`${t("publisher")}: `}</b>{publisher(selectedAssignment)}</div>
                    <div><b>{`${t("territory")}: `}</b>{territory(selectedAssignment)}</div>
                    <br />
                    <div><b>{`${t("taken_at")}: `}</b>{moment(selectedAssignment.takenAt).format("DD/MM/YYYY")}</div>
                    <div><b>{`${t("returned_at")}: `}</b>{moment(selectedAssignment.returnedAt).format("DD/MM/YYYY")}</div>
                    <div><b>{`${t("days_gone")}: `}</b>{moment().diff(moment(selectedAssignment.takenAt), 'days')}</div>
                    <br />
                    {selectedAssignment.notes && <div><b>{`${t("notes")}: `}</b>{selectedAssignment.notes}</div>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>{t("cancel")}</Button>
                </DialogActions>
            </Dialog>}
        </>
    );
};

export default MobileTerritoryAssignmentHistoryTable;