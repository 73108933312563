import * as React from 'react';
import { Collapse, List } from "@mui/material";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { PropsWithChildren } from '../props';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../stores';

interface Props {
    icon: React.ReactNode;
    type: string;
    text: string;
}

const SideBarMenuCollapseItem = observer((props: PropsWithChildren<Props>) => {
    const { panelStore } = useStores();
    const [openMenu, setOpenMenu] = React.useState((panelStore.sideBarMenu as any)[props.type]);

    const handleClick = () => {
        setOpenMenu(!openMenu);
        panelStore.setSideBarMenuOpen(props.type, !openMenu);
    };

    return (
        <>
            <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                    {props.icon}
                </ListItemIcon>
                <ListItemText primary={props.text} />
                {openMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse in={openMenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding dense>
                    {props.children}
                </List>
            </Collapse>
        </>
    )
});

export default SideBarMenuCollapseItem;