import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Panel from '../panel/Panel';
import { useStores } from '../../stores';
import { Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import SsidChartIcon from '@mui/icons-material/SsidChart';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import { IconButton } from '@mui/material';
import { ScheduleWeek } from '../../domain/ScheduleWeek';
import Can from '../../components/Can';
import ScheduleWeekDialog from './dialogs/ScheduleWeekDialog';
import { useOpenDialog } from '../../hooks/useOpenDialog';
import ScheduleContent from './ScheduleContent';
import ScheduleEpubImportDialog from './dialogs/ScheduleEpubImportDialog';
import ScheduleDownloadExcelDialog from './dialogs/ScheduleDownloadExcelDialog';
import ScheduleEntryCreateDialog from './dialogs/ScheduleEntryCreateDialog';
import { ScheduleEntry } from '../../domain/ScheduleEntry';
import { isMobile } from 'react-device-detect';
import { useConfirm } from 'material-ui-confirm';
import LoadingDimmer from '../../components/LoadingDimmer';
import ScheduleEntryDialog from './dialogs/ScheduleEntryDialog';
import ScheduleAutoArrangementButton from './ScheduleAutoArrangementButton';
import { useNavigate } from 'react-router-dom';
import SchedulePublisherHistoryDialog from './dialogs/SchedulePublisherHistoryDialog';
import SchedulePageMenu from './SchedulePageMenu';
import ScheduleCreateFieldServiceEntryDialog from './dialogs/ScheduleCreateFieldServiceEntryDialog';

const SchedulePage = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { scheduleStore, appointmentStore } = useStores();
    const confirm = useConfirm();
    const [generateLoading, setGenerateLoading] = React.useState<boolean>(false);
    const [selectedEntry, setSelectedEntry] = React.useState<ScheduleEntry | undefined>();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();

    const openScheduleAnalysis = () => {
        navigate('analysis');
    }

    const openSpeechSchedule = () => {
        navigate('speech');
    }

    const handleEntryCreate = (entry: ScheduleEntry) => {
        scheduleStore.createEntry({ ...entry, weekId: scheduleStore.currentWeek!.id! });
    }

    const saveWeekUpdate = (week: ScheduleWeek) => {
        const { entries, ...rest } = week;
        scheduleStore.updateWeek(rest as ScheduleWeek);
    }

    const saveUpdatedEntry = (entry: ScheduleEntry) => {
        scheduleStore.updateEntry(entry);
    }

    const handleEntryEdit = (entry: ScheduleEntry) => {
        setSelectedEntry(entry);
        openDialog("entry-dialog")
    }

    const handleEntryHistory = (entry: ScheduleEntry) => {
        setSelectedEntry(entry);
        openDialog("history-dialog")
    }

    const handleCloseDialog = () => {
        setSelectedEntry(undefined);
        closeDialog();
    }

    const handleEntryDelete = (entry: ScheduleEntry) => {
        confirm({
            title: t("are_you_sure"),
            description: `${t("this_will_permanently_delete")}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            scheduleStore.deleteEntry(entry);
        });
    }

    const handleAutoArrangement = (type: string) => {
        setGenerateLoading(true);
        if (scheduleStore.currentWeek) {
            scheduleStore.generateWeek(scheduleStore.currentWeek!.id!, type, () => {
                setGenerateLoading(false);
            });
        }
    }

    return (
        <Panel
            title={t("schedule")}
            headerItems={SchedulePageMenu({
                onExcelDownload: () => openDialog("excel-dialog"),
                onEpubUpload: () => openDialog("epub-dialog"),
                onScheduleWeekEdit: () => openDialog("week-dialog"),
                onCreateScheduleEntry: () => openDialog("create-entry-dialog"),
                onCreateFieldServiceEntry: () => openDialog("create-field-service-entry-dialog"),
                onCreateSpeechEntry: () => openDialog("create-entry-dialog")
            })}>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    {!isMobile && (
                        <Can do="manage" on='ScheduleEntry'>
                            <div style={{ marginLeft: "auto", marginRight: 10 }} >
                                <Tooltip title={t("analysis")}>
                                    <IconButton onClick={openScheduleAnalysis}>
                                        <SsidChartIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={t("speeches")}>
                                    <IconButton onClick={openSpeechSchedule}>
                                        <SpeakerNotesIcon />
                                    </IconButton>
                                </Tooltip>
                                <ScheduleAutoArrangementButton
                                    onAutoArrangementSelect={handleAutoArrangement}
                                />
                            </div>
                        </Can>
                    )}

                    <ScheduleContent
                        onEntryUpdate={saveUpdatedEntry}
                        onEntryEdit={handleEntryEdit}
                        onEntryHistory={handleEntryHistory}
                    />
                </Paper>
            </Container>

            <ScheduleEpubImportDialog
                isOpen={isOpen("epub-dialog")}
                onClose={handleCloseDialog}
            />
            <ScheduleDownloadExcelDialog
                isOpen={isOpen("excel-dialog")}
                onClose={handleCloseDialog}
            />
            <ScheduleEntryCreateDialog
                isOpen={isOpen("create-entry-dialog")}
                onSubmit={handleEntryCreate}
                onClose={handleCloseDialog}
            />
            <ScheduleCreateFieldServiceEntryDialog
                isOpen={isOpen("create-field-service-entry-dialog")}
                onSubmit={handleEntryCreate}
                onClose={handleCloseDialog}
            />
            {scheduleStore.currentWeek && <ScheduleWeekDialog
                week={scheduleStore.currentWeek}
                isOpen={isOpen("week-dialog")}
                onSubmit={saveWeekUpdate}
                onClose={handleCloseDialog}
            />}
            {selectedEntry && <ScheduleEntryDialog
                entry={selectedEntry}
                assignments={appointmentStore.assignments}
                isOpen={isOpen("entry-dialog")}
                onSubmit={saveUpdatedEntry}
                onDelete={handleEntryDelete}
                onClose={handleCloseDialog}
            />}

            {selectedEntry && <SchedulePublisherHistoryDialog
                entry={selectedEntry}
                isOpen={isOpen("history-dialog")}
                onClose={handleCloseDialog}
            />}

            <LoadingDimmer enabled={generateLoading} />
        </Panel >
    );
});

export default SchedulePage;