import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CachedIcon from '@mui/icons-material/Cached';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { TerritoryAssignment } from '../../../../domain/TerritoryAssignment';
import { Territory } from '../../../../domain/Territory';
import Can from '../../../../components/Can';

interface Props {
    history: TerritoryAssignment[];
    onTerritoryShow: (territory: Territory) => void;
    onTerritoryAssign: (territory: Territory) => void;
    onAssignmentEdit: (assignment: TerritoryAssignment) => void;
    onAssignmentsRefresh: () => void;
}

const TerritoryAssignmentHistoryTable = (props: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <Toolbar>
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="h5"
                >
                    {t("history_of_assignments")}
                </Typography>
                <Can do="manage" on='Admin'>
                    <IconButton onClick={props.onAssignmentsRefresh}>
                        <CachedIcon />
                    </IconButton>
                </Can>
            </Toolbar>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("group")}</TableCell>
                        <TableCell>{t("title")}</TableCell>
                        <TableCell>{t("publisher")}</TableCell>
                        <TableCell>{t("notes")}</TableCell>
                        <TableCell>{t("taken_at")}</TableCell>
                        <TableCell>{t("returned_at")}</TableCell>
                        <TableCell>{t("days_gone")}</TableCell>
                        <TableCell>{t("actions")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.history.map(assignment => (
                        <TableRow key={assignment.id}>
                            <TableCell>{assignment.territory!.territoryGroup!.name}</TableCell>
                            <TableCell style={{ maxWidth: "350px" }}><b>{assignment.territory!.title} </b></TableCell>
                            <TableCell>{assignment.publisher ? `${assignment.publisher!.lastName} ${assignment.publisher!.firstName}` : t("someone")}</TableCell>
                            <TableCell style={{ maxWidth: "200px" }}>{assignment.notes}</TableCell>
                            <TableCell>{moment(assignment.takenAt).format("DD/MM/YYYY")}</TableCell>
                            <TableCell>{moment(assignment.returnedAt).format("DD/MM/YYYY")}</TableCell>
                            <TableCell><b>{moment(assignment.returnedAt).diff(moment(assignment.takenAt), 'days')}</b></TableCell>
                            <TableCell>
                                <IconButton onClick={() => props.onTerritoryShow(assignment.territory!)}>
                                    <VisibilityIcon />
                                </IconButton>
                                <IconButton onClick={() => props.onTerritoryAssign(assignment.territory!)}>
                                    <AssignmentIndIcon />
                                </IconButton>
                                <IconButton onClick={() => props.onAssignmentEdit(assignment)}>
                                    <EditIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

export default TerritoryAssignmentHistoryTable;