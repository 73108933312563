import { TableCell, TableRow } from '@mui/material';
import Can from '../../../components/Can';
import { AccountingEntry } from '../../../domain/AccountingEntry';
import { sum } from '../../../utils/dateUtils';
import { border } from './accountingTableUtils';

interface Props {
    entries: AccountingEntry[];
}

const AccountingTableSumRow = (props: Props) => {

    const calculateIncomeSum = (type: string) => {
        const filtered = props.entries.filter(entry =>
            (entry.transferDirection === type && entry.side === "transfer") ||
            (entry.type === type && entry.side === "income")
        );
        return sum(filtered, "amount");
    }

    const calculateExpensesSum = (type: string) => {
        const filtered = props.entries.filter(entry =>
            entry.type === type &&
            (entry.side === "expenses" || entry.side === "transfer")
        );
        return sum(filtered, "amount");
    }

    const calculateIncomeTotalSum = () => {
        return sum(props.entries.filter(entry => entry.side === "income"), "amount");
    }

    const calculateExpensesTotalSum = () => {
        return sum(props.entries.filter(entry => entry.side === "expenses"), "amount");
    }


    return (
        <TableRow style={{ backgroundColor: "#2e7d3214" }}>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell sx={border} />
            <TableCell sx={border} />
            <TableCell sx={border} align="center"><b>{calculateIncomeSum("cash").toFixed(2)}</b></TableCell>
            <TableCell sx={border} align="center"><b>{calculateExpensesSum("cash").toFixed(2)}</b></TableCell>
            <TableCell sx={border} align="center"><b>{calculateIncomeSum("bank").toFixed(2)}</b></TableCell>
            <TableCell sx={border} align="center"><b>{calculateExpensesSum("bank").toFixed(2)}</b></TableCell>
            <TableCell sx={border} align="center"><b>{calculateIncomeTotalSum().toFixed(2)}</b></TableCell>
            <TableCell sx={border} align="center"><b>{calculateExpensesTotalSum().toFixed(2)}</b></TableCell>
            <Can do="manage" on='Accounting'>
                <TableCell />
            </Can>
        </TableRow>
    );
};

export default AccountingTableSumRow;