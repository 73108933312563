import * as React from 'react';
import { useStores } from '../../../../stores';
import { Fab, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { observer } from 'mobx-react-lite';
import { useOpenDialog } from '../../../../hooks/useOpenDialog';
import { AccountingEntry } from '../../../../domain/AccountingEntry';
import moment from 'moment';
import MonthSwitcher from '../../../../components/MonthSwitcher';
import { color, format } from '../accountingTableUtils';
import { useTranslation } from 'react-i18next';
import AccountingShowEntryDialog from '../../dialogs/AccountingShowEntryDialog';
import AccountingS24Dialog from '../../dialogs/AccountingS24Dialog';
import AccountingBoxEntryDialog from '../../dialogs/AccountingBoxEntryDialog';


interface Props {
    date: Date;
    onEmptyTable: (empty: boolean) => void;
    onDateChange: (newDate: Date) => void;
    onSummaryOpen: () => void;
}

const MobileAccountingTable = observer((props: Props) => {
    const { t } = useTranslation();
    const { accountingStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [entries, setEntries] = React.useState<AccountingEntry[]>([]);
    const [selectedEntry, setSelectedEntry] = React.useState<AccountingEntry | undefined>(undefined);
    const [boxEntries, setBoxEntries] = React.useState<AccountingEntry[] | undefined>(undefined);

    React.useEffect(() => {
        accountingStore.getAll(() => {
            if (!accountingStore.entries.length) {
                props.onEmptyTable(true);
            } else {
                filterEntries(props.date);
            }
        });
    }, [accountingStore]);

    React.useEffect(() => {
        filterEntries(props.date);
    }, [accountingStore.entries]);

    const filterEntries = (date: Date) => {
        const currentEntries: AccountingEntry[] = accountingStore.entries.filter(entry => {
            const curDate = moment(date).format("YYYY-MM");
            const entryDate = moment(entry.date).format("YYYY-MM");
            if (curDate === entryDate) {
                return entry;
            }
        });
        setEntries(currentEntries)
    };

    const handleDateUpdate = (date: Date) => {
        props.onDateChange(date);
        filterEntries(date);
    }

    const openEntryDialog = (entry: AccountingEntry) => {
        setSelectedEntry(entry);
        openDialog("show-entry")
    }

    const handleCloseDialog = () => {
        setSelectedEntry(undefined);
        setBoxEntries(undefined);
        closeDialog();
    }

    const saveNewEntries = (entries: AccountingEntry[]) => {
        accountingStore.saveBatch(entries, () => {
            setBoxEntries(entries);
            openDialog("s24-dialog");
        });
    }

    return (
        <TableContainer>
            <MonthSwitcher date={props.date} onDateUpdate={handleDateUpdate} />
            <Table size="small">
                <TableBody>
                    {entries.map((entry: AccountingEntry) => (
                        <TableRow key={entry.id} style={{ backgroundColor: color(entry) }} onClick={() => openEntryDialog(entry)} >
                            <TableCell>{moment(entry.date).format('DD.MM')}</TableCell>
                            <TableCell>{entry.document}</TableCell>
                            <TableCell>{`${t(entry.type)} / ${t(entry.side)}`}</TableCell>
                            <TableCell><b>{format(entry.amount)}</b></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Fab
                sx={{ position: 'fixed', bottom: 10, right: 10 }}
                color="primary"
                onClick={() => openDialog("box-entry")}
            >
                <AddIcon />
            </Fab>
            <AccountingShowEntryDialog
                entry={selectedEntry!}
                isOpen={isOpen("show-entry")}
                onClose={handleCloseDialog} />
            <AccountingBoxEntryDialog
                date={props.date}
                isOpen={isOpen("box-entry")}
                onSubmit={saveNewEntries}
                onClose={handleCloseDialog} />
            {boxEntries && <AccountingS24Dialog
                isOpen={isOpen("s24-dialog")}
                entries={boxEntries}
                onClose={handleCloseDialog} />
            }
        </TableContainer>
    );
});

export default MobileAccountingTable;