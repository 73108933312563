import { IconButton, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { Territory } from '../../../domain/Territory';

interface Props {
    territories: Territory[];
    onTerritoryFocus: (territory: Territory) => void;
    onTerritoryRequest: (territory: Territory) => void;
}

const TerrioriesForRequestTable = (props: Props) => {
    return (
        <Box>
            <Table>
                <TableBody>
                    {props.territories.map((territory, i) => (
                        <TableRow key={territory.id}>
                            <TableCell padding='none' >{i + 1}</TableCell>
                            <TableCell onClick={() => props.onTerritoryFocus(territory)}>
                                {`${territory.title}`}
                            </TableCell>
                            <TableCell padding='none'>
                                <IconButton size='small' onClick={() => props.onTerritoryRequest(territory)}>
                                    <AssignmentIndIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};

export default TerrioriesForRequestTable;