import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Publisher, ServiceAssignment } from '../../domain/Publisher';
import { FieldServiceReport } from '../../domain/FieldServiceReport';
import { DataGrid, GridRowModel, GridActionsCellItem } from '@mui/x-data-grid';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from "react-i18next";
import ReportTableToolbar from './ReportTableToolbar';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { emptyReport, reportClass, serviceAssignmentColor } from '../../utils/reportColorUtils';
import { getGridLocale } from '../../utils/localeUtils';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useStores } from '../../stores';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
    },
}));

interface Props {
    publishers: Publisher[];
    onSubmit: (report: FieldServiceReport) => void;
    onDateChange?: (date: Date) => void;
    onAssignmentChange?: (assignment: ServiceAssignment | string) => void;
    onReportSelect?: (publisher: Publisher, report?: FieldServiceReport) => void;
}

const GroupReportsTable = observer((props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { panelStore, notificationsStore } = useStores();
    const [filter, setFilter] = React.useState<ServiceAssignment | string>(panelStore.groupReportPage.filter);
    const [date, setDate] = React.useState<Date>(panelStore.groupReportPage.date);
    const [newReports, setNewReports] = React.useState<FieldServiceReport[]>([]);

    React.useEffect(() => {
        updateReports(date);
    }, [props.publishers, filter]);

    const handleReportSelect = (params: any) => {
        if (isMobile) {
            const publisher = props.publishers.find(publ => publ.id === params.id);
            const report = newReports.find(report => report.publisherId === params.id)
            props.onReportSelect && props.onReportSelect(publisher!, report);
        } else {
            navigate(`/publishers/${params.id}`);
        }
    }

    const filterReports = (report: FieldServiceReport): boolean => {
        if (filter === "All") {
            return true;
        } else if (filter === "Publishers") {
            return !report.serviceAssignment;
        } else if (filter === "All Pioneers") {
            return report.serviceAssignment === ServiceAssignment.RegularPioneer || 
            report.serviceAssignment === ServiceAssignment.SpecialPioneer ||
            report.serviceAssignment === ServiceAssignment.Missionary;
        } else {
            return report.serviceAssignment === filter;
        }
    }

    const handleFilterChange = (filter: ServiceAssignment | string) => {
        setFilter(filter);
        panelStore.setGroupReportPageFilter(filter);
        props.onAssignmentChange && props.onAssignmentChange(filter);
    }

    const filterPublishers = (publisher: Publisher) => {
        if (filter === "All") {
            return publisher.deletedAt ? moment(publisher.deletedAt).isAfter(moment(date)) : true;
        } else {
            return !!newReports.find(rep => rep.publisherId === publisher.id);
        }
    }

    const handlePageChange = (page: number) => {
        panelStore.setGroupReportPagePage(page);
    }

    const updateReports = (date: Date) => {
        setDate(date);
        panelStore.setGroupReportPageDate(date);
        props.onDateChange && props.onDateChange(date);
        const reports: FieldServiceReport[] = [];
        props.publishers.forEach((publisher: Publisher) => {
            if (publisher.serviceReports) {
                const report = publisher.serviceReports!
                    .filter(filterReports)
                    .find(report => {
                        const reportDate = moment(report.date).format("YYYY-MM");
                        const selectedDate = moment(date).format("YYYY-MM");
                        return reportDate === selectedDate;
                    });
                if (report) {
                    reports.push(report);
                }
            }

        });
        setNewReports(reports);
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const empty = !newRow.empty;
        const serviceAssignment = newRow.serviceAssignment === '' ? null : newRow.serviceAssignment;

        if (!serviceAssignment && newRow.hours > 0) {
            notificationsStore.add(t("publisher_without_service_assignment_should_not_have_hours_in_report"), "error");
            return { id: newRow.id };
        }

        const notes = newRow.notes ? newRow.notes : (serviceAssignment === ServiceAssignment.AuxiliaryPioneer ? t("auxiliary_pioneer_short") : '');
        const newReport = {
            date: date.toISOString(),
            publisherId: newRow.id,
            hours: newRow.hours || 0,
            bibleStudies: newRow.bibleStudies || 0,
            serviceAssignment,
            notes,
            empty
        } as FieldServiceReport;

        const prevReport = newReports.find(val => val.publisherId === newReport.publisherId);
        if (prevReport && prevReport.id) {
            newReport.id = prevReport.id;
        }

        setNewReports(arr => [...(arr.filter(val => val.publisherId !== newRow.id)), newReport])
        props.onSubmit(newReport);
        return newRow;
    };

    const processRowUpdateError = (error: any) => {
        console.error(error);
    }

    const visibleColumns = () => {
        if (isMobileOnly) {
            return {
                hours: false,
                bibleStudies: false,
                serviceAssignment: false,
                notes: false
            } as any
        }
        return {} as any;
    }

    const isColumnEditable = isMobileOnly ? false : true;

    interface ServiceAssignmentOption { value: ServiceAssignment | string, label: string };
    const serviceAssignmentOptions: ServiceAssignmentOption[] = Object.values(ServiceAssignment).map(value => ({ value, label: t(value) }));
    serviceAssignmentOptions.unshift({ value: "", label: t("no")})

    return (
        <StyledDataGrid
            sx={{
                '& .report-row-empty': {
                    bgcolor: () => '#ffec1a0D'
                },
                '& .report-row-regular-pioneer': {
                    bgcolor: () => serviceAssignmentColor(ServiceAssignment.RegularPioneer)
                },
                '& .report-row-aux-pioneer': {
                    bgcolor: () => serviceAssignmentColor(ServiceAssignment.AuxiliaryPioneer)
                },
                '& .report-row-special-pioneer': {
                    bgcolor: () => serviceAssignmentColor(ServiceAssignment.SpecialPioneer)
                },
                '& .report-row-non-regular': {
                    bgcolor: () => emptyReport()
                },
            }}
            localeText={getGridLocale().components.MuiDataGrid.defaultProps.localeText}
            editMode="row"
            rowHeight={30}
            pageSize={60}
            page={panelStore.groupReportPage.page}
            onPageChange={handlePageChange}
            experimentalFeatures={{ newEditingApi: true }}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={processRowUpdateError}
            initialState={{ columns: { columnVisibilityModel: visibleColumns() } }}
            getRowClassName={(params: any) => {
                const report = newReports.find(r => r.publisherId === params.id);
                return `report-row-${reportClass(report!)}`;
            }}
            rows={
                props.publishers
                    .filter(filterPublishers)
                    .map((publisher: Publisher) => {
                        const report = newReports.find(rep => rep.publisherId === publisher.id);
                        if (report) {
                            return {
                                id: publisher.id,
                                name: `${publisher.lastName} ${publisher.firstName}`,
                                hours: report.hours === 0 ? undefined : report.hours,
                                bibleStudies: report.bibleStudies === 0 ? undefined : report.bibleStudies,
                                serviceAssignment: report.serviceAssignment ? report.serviceAssignment : '',
                                empty: !report.empty,
                                notes: report.notes
                            }
                        } else {
                            return { id: publisher.id, name: `${publisher.lastName} ${publisher.firstName}` };
                        }
                    })
            }
            columns={[
                { field: 'name', headerName: t('name'), flex: 1.5 },
                { field: 'empty', headerName: t('was_preaching'), type: 'boolean', flex: 1.5, editable: isColumnEditable },
                { field: 'hours', headerName: t('hours'), type: "number", flex: 1, editable: isColumnEditable, renderCell: (params: any) => <strong>{params.value}</strong> },
                { field: 'bibleStudies', headerName: t('bible_studies'), type: "number", flex: 1, editable: isColumnEditable },
                { field: 'serviceAssignment', headerName: t('service_assignment'), flex: 1, type: 'singleSelect', editable: isColumnEditable, valueOptions: serviceAssignmentOptions, renderCell: (params) => t(params.value) },
                { field: 'notes', headerName: t('notes'), flex: 1, editable: isColumnEditable },
                { field: 'actions', type: 'actions', flex: 0.1, getActions: (params: any) => [<GridActionsCellItem icon={<InfoIcon />} label="Info" onClick={() => handleReportSelect(params)} />] },
            ]}
            components={{ Toolbar: ReportTableToolbar }}
            componentsProps={{
                toolbar: {
                    date,
                    filter,
                    reports: newReports,
                    publishers: props.publishers.filter(filterPublishers),
                    onDateUpdate: updateReports,
                    onFilterChange: handleFilterChange
                }
            }}
        />
    );
});

export default GroupReportsTable;