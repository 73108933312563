import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import Can from '../../../components/Can';
import { useTranslation } from "react-i18next";
import SideBarMenuItem from '../SideBarMenuItem';

const SideBarCartMenu = () => {
    const { t } = useTranslation();
    const isCartsMenuEnabled = process.env.REACT_APP_CART_WITNESSING_FEATURE_ENABLED === "true";
    if (isCartsMenuEnabled) {
        return (
            <Can do="read" on='Cart'>
                <SideBarMenuItem id="side_menu_item_carts" to='/carts' text={t("carts")}>
                    <DocumentScannerOutlinedIcon />
                </SideBarMenuItem>
            </Can>
        )
    } else return null;
}

export default SideBarCartMenu;