import * as React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { useTranslation } from "react-i18next";
import Can from '../../components/Can';
import { isMobile } from 'react-device-detect';

interface Props {
    onExcelDownload: () => void;
    onEpubUpload: () => void;
    onScheduleWeekEdit: () => void;
    onCreateScheduleEntry: () => void;
    onCreateFieldServiceEntry: () => void;
    onCreateSpeechEntry: () => void;
}

const SchedulePageMenu = (props: Props) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const call = (callback: () => void) => {
        handleClose();
        callback();
    }

    const headerItems = () => {
        const items = [];
        if (!isMobile) {
            items.push(
                <Can key="download_excel_schedule" do="manage" on='ScheduleWeek'>
                    <Button
                        color="inherit"
                        type="button"
                        onClick={props.onExcelDownload}>
                        {t("download_excel")}
                    </Button>
                </Can>
            );

            items.push(
                <Can key="import_epub_btn" do="manage" on='ScheduleEntry'>
                    <Button
                        color="inherit"
                        type="button"
                        onClick={props.onEpubUpload}>
                        {t("import_epub")}
                    </Button>
                </Can>
            )
        }

        items.push(
            <Can key="update_week_schedule" do="manage" on='ScheduleWeek'>
                <Button
                    color="inherit"
                    type="button"
                    onClick={props.onScheduleWeekEdit}>
                    {t("update_week_schedule")}
                </Button>
            </Can>
        );

        items.push(
            <Can key="create_entry-btn" do="manage" on='ScheduleEntry'>
                <Button
                    color="inherit"
                    type="button"
                    onClick={handleClick}>
                    {t("create_entry")}
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => call(props.onCreateScheduleEntry)}>{t("basic_schedule_entry")}</MenuItem>
                    <MenuItem onClick={() => call(props.onCreateFieldServiceEntry)}>{t("field_service")}</MenuItem>
                    <MenuItem disabled onClick={() => call(props.onCreateSpeechEntry)}>{t("speech")}</MenuItem>
                </Menu>
            </Can>
        )

        return items;
    }



    return headerItems();
};

export default SchedulePageMenu;