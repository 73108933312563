import * as React from 'react';
import { IconButton, TableCell, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { FieldServiceGroup } from '../../../domain/FieldServiceGroup';
import Can from '../../../components/Can';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Publisher } from '../../../domain/Publisher';
import FieldServiceGroupPublishersTable from './FieldServiceGroupPublishersTable';

interface Props {
    group: FieldServiceGroup;
    publishers: Publisher[];
    onGroupEdit: (group: FieldServiceGroup) => void;
    onPublisherEdit: (publisher: Publisher) => void;
}

const MobileFieldServiceGroupTableRow = (props: Props) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton size="small" onClick={() => setOpen(!open)} >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{props.group.name}</TableCell>
                <Can do="update" on='Groups'>
                    <TableCell>
                        <IconButton onClick={() => props.onGroupEdit(props.group)}>
                            <EditIcon />
                        </IconButton>
                    </TableCell>
                </Can>
            </TableRow>
            <FieldServiceGroupPublishersTable 
                open={open}
                publishers={props.publishers}
                onPublisherEdit={props.onPublisherEdit}
            />
        </>
    );
};

export default MobileFieldServiceGroupTableRow;