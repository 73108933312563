import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Panel from '../panel/Panel';
import { useStores } from '../../stores';
import { Button, LinearProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useOpenDialog } from '../../hooks/useOpenDialog';
import Can from '../../components/Can';
import LiteratureTable from './table/LiteratureTable';
import ManageLiteratureTypesDialog from './dialogs/ManageLiteratureTypesDialog';
import LiteratureSummary from './summary/LiteratureSummary';
import { isMobileOnly } from 'react-device-detect';
import MobileLiteratureSummary from './summary/MobileLiteratureSummary';
import MobileLiteratureTable from './table/MobileLiteratureTable';

const LiteraturePage = observer(() => {
    const { t } = useTranslation();
    const { serverStore, publisherStore, literatureStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();

    React.useEffect(() => {
        if (!publisherStore.groups) {
            publisherStore.getAllServiceGroups();
        }
        if (!literatureStore.literatureTypes.length) {
            literatureStore.getLiteratureTypes();
            literatureStore.getLiteratureRequests();
        }
    }, [literatureStore, publisherStore]);

    return (
        <Panel
            title={t("literature")}
            headerItems={[
                <Can key="manage_literature_btn" do="manage" on='Literature'>
                    <Button
                        type="button"
                        color="inherit"
                        onClick={() => openDialog("manage-literature")}>
                        {t("literature")}
                    </Button>
                </Can>
            ]}>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                {serverStore.serverRequestLoading && <LinearProgress />}
                <Paper sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
                    {isMobileOnly ?
                        <MobileLiteratureSummary
                            types={literatureStore.literatureTypes}
                            requests={literatureStore.literatureRequests}
                        /> :
                        <LiteratureSummary
                            types={literatureStore.literatureTypes}
                            requests={literatureStore.literatureRequests}
                        />}
                </Paper>
            </Container>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
                    {isMobileOnly ?
                        <MobileLiteratureTable
                            groups={publisherStore.groups}
                            publishers={publisherStore.publishers}
                            types={literatureStore.literatureTypes}
                            requests={literatureStore.literatureRequests}
                        /> :
                        <LiteratureTable
                            groups={publisherStore.groups}
                            publishers={publisherStore.publishers}
                            types={literatureStore.literatureTypes}
                            requests={literatureStore.literatureRequests}
                        />}
                </Paper>
            </Container>

            <ManageLiteratureTypesDialog
                isOpen={isOpen("manage-literature")}
                onClose={closeDialog}
            />
        </Panel >
    );
});

export default LiteraturePage;