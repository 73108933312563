import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { useFormik } from 'formik';
import { useStores } from '../../../stores';
import { Button, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import MeetingTimeTable from './MeetingTimeTable';

interface Props {
    index: number;
    tab: number;
}

const CongregationTab = observer((props: Props) => {
    const { t } = useTranslation();
    const { congregationInfoStore } = useStores();

    React.useEffect(() => {
        congregationInfoStore.getAll();
    }, [congregationInfoStore]);

    const initialValues = congregationInfoStore.congregationInfo.reduce((obj, cur) => ({ ...obj, [cur.id]: cur.value }), {})
    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        onSubmit: (values) => {
            const infos = Object.keys(values).map(key => ({ id: key, value: (values as any)[key] }));
            congregationInfoStore.saveInfos(infos);
        },
    });

    const congregationInfos = congregationInfoStore.congregationInfo.filter(info => !info.name.includes("Meeting"));

    return (
        <div
            role="tabpanel"
            hidden={props.tab !== props.index}>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <form onSubmit={formik.handleSubmit}>
                        {congregationInfos.map(info => <TextField
                            key={info.id}
                            sx={{ mb: 2 }}
                            fullWidth
                            id={String(info.id)}
                            name={String(info.id)}
                            label={t(info.name)}
                            value={(formik.values as any)[info.id] || ''}
                            onChange={formik.handleChange}
                            error={(formik.touched as any)[info.id] && Boolean((formik.errors as any)[info.id])}
                            helperText={(formik.touched as any)[info.id] && (formik.errors as any)[info.id]}
                        />)}
                        <MeetingTimeTable
                            congrInfo={congregationInfoStore.congregationInfo}
                            formik={formik}
                        />
                        <Button color="primary" variant="contained" fullWidth type="submit">
                            {t("submit")}
                        </Button>
                    </form>
                </Paper>
            </Container>
        </div>
    );
});

export default CongregationTab;