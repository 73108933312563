import * as React from 'react';
import { Card, CardActions, CardContent, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../stores';

const PreferencesDefaultTabCard = observer(() => {
    const { localStorageStore } = useStores();
    const [tab, setTab] = React.useState<number>(localStorageStore.getHomePageTab());
    const { t } = useTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt((event.target as HTMLInputElement).value);
        setTab(value);
        localStorageStore.setHomePageTab(value);
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="div">
                    {t("default_tab_in_home_page")}
                </Typography>
            </CardContent>
            <CardActions>
                <FormControl>
                    <RadioGroup
                        row
                        value={tab}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="0" control={<Radio />} label={t("carts")} />
                        <FormControlLabel value="1" control={<Radio />} label={t("territories")} />
                        <FormControlLabel value="2" control={<Radio />} label={t("schedule_short")} />
                    </RadioGroup>
                </FormControl>
            </CardActions>
        </Card>
    );
});

export default PreferencesDefaultTabCard;