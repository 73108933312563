import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { ServerStore } from './ServerStore';

export class WebPushStore {
    rootStore: RootStore;
    serverStore: ServerStore;

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        makeAutoObservable(this);
    }

    registerServiceWorker = () => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .register('/service-worker.js')
                .then(() => {
                    console.log('Service Worker registered');
                    this.subscribeOnStart();
                })
                .catch((error) => {
                    console.error('Service Worker registration failed:', error);
                });
        }
    }

    getSubscription = async () => {
        const registration = await navigator.serviceWorker.ready;
        return registration.pushManager.getSubscription();
    }

    subscribeOnStart = async () => {
        const browser = 'Notification' in window;
        const granted = Notification.permission === "granted";
        const webPushEnabled = process.env.REACT_APP_WEB_PUSH_FEATURE_ENABLED === "true";
        const vapidKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;
        if (browser && granted && webPushEnabled && vapidKey) {
            await this.sendSubscription(vapidKey, false);
        }
    }

    subscribeUser = async () => {
        if ('Notification' in window) {
            const isWebPushEnabled = process.env.REACT_APP_WEB_PUSH_FEATURE_ENABLED === "true";
            const vapidKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;
            if (isWebPushEnabled && vapidKey) {
                const permissionGranted = Notification.permission === "granted";
                if (permissionGranted) {
                    await this.sendSubscription(vapidKey);
                } else {
                    const permission = await Notification.requestPermission();
                    if (permission === 'granted') {
                        await this.sendSubscription(vapidKey);
                    } else {
                        this.rootStore.notificationsStore.add("permission_is_not_granted_for_push_notifications", 'error');
                    }
                }
            } else {
                this.rootStore.notificationsStore.add("configuration_for_push_notifications_is_not_provided", 'error');
            }
        } else {
            this.rootStore.notificationsStore.add("not_able_to_set_up_push_notifications", 'error');
        }
    }

    unsubscribeUser = async () => {
        const subscription = await this.getSubscription();
        if (subscription) {
            const result = await subscription.unsubscribe();
            if (result) {
                this.serverStore.post('/push/unsubscribe', {}, action(() => {
                    this.rootStore.userStore.getMyUser();
                    this.rootStore.notificationsStore.add("push_notifications_disabled", 'success');
                }));
            }
        }
    }

    private async sendSubscription(vapidKey: string, notify = true) {
        const subscription = await this.getSubscription();
        if (!subscription) {
            const registration = await navigator.serviceWorker.ready;
            const newSubscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: this.urlBase64ToUint8Array(vapidKey),
            });
    
            this.serverStore.post('/push/subscribe', JSON.stringify(newSubscription), action(() => {
                this.rootStore.userStore.getMyUser();
                if (notify) {
                    this.rootStore.notificationsStore.add("push_notifications_enabled", 'success');
                }
            }));
        } else {
            if (notify) {
                this.rootStore.notificationsStore.add("push_notifications_already_enabled", 'success');
            }
        }
    }

    sendPushNotification = (userId: number, message: string) => {
        this.serverStore.post('/push/send', { userId, message }, action(() => {

        }));
    }

    urlBase64ToUint8Array = (base64String: string) => {
        const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

}