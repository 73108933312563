import * as React from 'react';
import Panel from '../panel/Panel';
import { Tab, Tabs } from '@mui/material';
import { useTranslation } from "react-i18next";
import CongregationTab from './congregation-info/CongregationTab';
import BackupTab from './backup/BackupTab';
import ProfileSettingsTab from './ProfileSettingsTab';
import Can from '../../components/Can';
import { AbilityContext } from '../../components/Can';
import PersonalDataTab from './PersonalDataTab';
import { isMobile } from 'react-device-detect';
import PreferencesTab from './preferences/PreferencesTab';

const SettingsPage = () => {
    const { t } = useTranslation();
    const [tab, setTab] = React.useState(0);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const tabMenu = [
        <Tab key="profile" label={t("profile")} />,
        <Tab key="preferences" label={t("preferences")} />,
        <Tab key="personal_data" label={t("personal_data")} />
    ];
    const ability = React.useContext(AbilityContext);
    if (ability.can("manage", "CongregationSettings") && !isMobile) {
        tabMenu.push(<Tab key="congregation" label={t("congregation")} />);
        tabMenu.push(<Tab key="backup" label={t("backup")} />);
    }

    return (
        <Panel title={t("settings")}>
            <Tabs value={tab} onChange={handleChange} variant="scrollable" scrollButtons="auto">
                {tabMenu}
            </Tabs>
            <ProfileSettingsTab index={0} tab={tab} />
            <PreferencesTab index={1} tab={tab} />
            <PersonalDataTab index={2} tab={tab} />
            <Can do="manage" on='CongregationSettings'>
                <CongregationTab index={3} tab={tab} />
                <BackupTab index={4} tab={tab} />
            </Can>
        </Panel >
    );
};

export default SettingsPage;