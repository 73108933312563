import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import PublisherScheduleDialog from '../../publishers/dialogs/PublisherScheduleDialog';
import { useOpenDialog } from '../../../hooks/useOpenDialog';
import { Publisher } from '../../../domain/Publisher';
import { useStores } from '../../../stores';


const ScheduleStudentsTab = observer(() => {
    const { t } = useTranslation();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const { publisherStore } = useStores();
    const [selectedPublisher, setSelectedPublisher] = React.useState<Publisher | undefined>();

    const handlePublisherClick = (publisher: Publisher) => {
        setSelectedPublisher(publisher);
        openDialog("schedule-history");
    }

    return (
        <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h5">{t("students")}</Typography>

                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            {publisherStore.publishers && publisherStore.publishers
                                .filter(publisher => {
                                    return publisher.assignments.find(a => a.id === 8) // student
                                })
                                .map((publisher: Publisher, i: number) => (
                                    <TableRow key={publisher.id}>
                                        <TableCell>{i + 1}</TableCell>
                                        <TableCell onClick={() => handlePublisherClick(publisher)}>{`${publisher.lastName} ${publisher.firstName}`}</TableCell>
                                        <TableCell>{publisher.appointments.map(a => t(a.name))[0]}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            {selectedPublisher && <PublisherScheduleDialog
                isOpen={isOpen("schedule-history")}
                publisherId={selectedPublisher!.id}
                onClose={closeDialog} />}
        </Container>
    );
});

export default ScheduleStudentsTab;