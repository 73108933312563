import * as React from 'react';
import { observer } from 'mobx-react-lite';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { LinearProgress, Tab, Tabs } from '@mui/material';
import { CartWitnessingPlace } from '../../domain/CartWitnessingPlace';
import { useStores } from '../../stores';
import CartWitnessingCalendarTab from './CartWitnessingCalendarTab';

interface Props {
    onPlaceChange?: (place: CartWitnessingPlace) => void;
    extraToolbar?: React.ReactNode;
}

const CartWitnessingContent = observer((props: Props) => {
    const { serverStore, cartWitnessingStore } = useStores();
    const [tab, setTab] = React.useState(0);

    React.useEffect(() => {
        cartWitnessingStore.getAll();
    }, [cartWitnessingStore]);

    React.useEffect(() => {
        updatePlace(0);
    }, [cartWitnessingStore.places.length]);

    const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
        updatePlace(newValue);
    };

    const updatePlace = (index: number) => {
        if (cartWitnessingStore.places.length) {
            const newPlace = cartWitnessingStore.places[index];
            if (props.onPlaceChange) {
                props.onPlaceChange(newPlace);
            }
        }
        setTab(index);
    }

    return (
        <>
            <Tabs
                value={tab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="inherit"
                variant="fullWidth">
                {cartWitnessingStore.places.map(place =>
                    <Tab key={place.id} label={place.name} />)}
            </Tabs>
            {serverStore.serverRequestLoading && <LinearProgress />}
            {cartWitnessingStore.places.map((place, i) => {
                return (
                    <div key={place.id} role="tabpanel" hidden={tab !== i}>
                        <CartWitnessingCalendarTab place={place} />
                    </div>
                )
            })}
            {props.extraToolbar && props.extraToolbar}
        </>
    );
});

export default CartWitnessingContent;