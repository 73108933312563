import { TextField, Container, Paper, Grid, Select, MenuItem, SelectChangeEvent, FormControl, InputLabel } from '@mui/material';
import { useTranslation } from "react-i18next";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getLocale } from '../../../utils/localeUtils';

interface Props {
    from: Date;
    to: Date;
    sort: string;
    onRangeChange: (from: Date, to: Date) => void;
    onSortChange: (sort: string) => void;
}

const ScheduleAnalysisToolbar = (props: Props) => {
    const { t } = useTranslation();

    const handleSortChange = (event: SelectChangeEvent) => {
        props.onSortChange(event.target.value as string);
    };

    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Grid container>
                    <Grid item xs={4} padding={1}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                            <DatePicker
                                label={t("from")}
                                value={props.from}
                                onChange={value => {
                                    props.onRangeChange(value!, props.to);
                                }}
                                renderInput={(params) => <TextField {...params} margin="normal" name="from" />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4} padding={1}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                            <DatePicker
                                label={t("to")}
                                value={props.to}
                                onChange={value => {
                                    props.onRangeChange(props.from, value!);
                                }}
                                renderInput={(params) => <TextField {...params} margin="normal" name="to" />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4} padding={1}></Grid>
                </Grid>
                <FormControl fullWidth>
                    <InputLabel>{t("sorting")}</InputLabel>
                    <Select
                        value={props.sort}
                        label={t("sorting")}
                        onChange={handleSortChange}
                    >
                        <MenuItem value={"default"}>{t("default")}</MenuItem>
                        <MenuItem value={"last_name"}>{t("surname")}</MenuItem>
                    </Select>
                </FormControl>
            </Paper>
        </Container>
    );
};

export default ScheduleAnalysisToolbar;