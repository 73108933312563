import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link } from "react-router-dom";
import { useStores } from "../../stores";
import { isMobile } from 'react-device-detect';
import { observer } from 'mobx-react-lite';

interface Props {
    id: string;
    to: string;
    text: string;
}

const SideBarSubMenuItem = observer((props: Props) => {
    const { panelStore } = useStores();

    const handleClick = () => {
        if (isMobile) {
            panelStore.setCollapsed(true);
        }
    };

    return (
        <ListItemButton
            id={props.id}
            sx={{ pl: 4 }}
            component={Link}
            to={props.to}
            onClick={handleClick}
        >
            <ListItemText primary={props.text} />
        </ListItemButton>
    )
});

export default SideBarSubMenuItem;