import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { useStores } from '../../stores';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { Publisher } from '../../domain/Publisher';

const AppointmentsTab = observer(() => {
    const { t } = useTranslation();
    const { publisherStore } = useStores();

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>{t("publisher")}</b></TableCell>
                                <TableCell><b>{t("appointments")}</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {publisherStore.publishers && publisherStore.publishers
                                .filter(publ => publ.appointments.length > 0)
                                .map((publ: Publisher) => (
                                    <TableRow key={publ.id}>
                                        <TableCell>{`${publ.firstName} ${publ.lastName}`}</TableCell>
                                        <TableCell>{publ.appointments.map(app => t(app.name)).join(", ")}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Container>
    );
});

export default AppointmentsTab;