import { AbilityBuilder, Ability, AbilityClass } from '@casl/ability';
import { Role } from '../domain/User';

type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete';
type Subjects = 'Publisher' | 'User' | 'UserRole' | 'Users' | 'Groups' | 'Attendance' | 'GroupReports' |
    'OverallReport' | 'Accounting' | 'Cart' | 'CartWitnessingPlace' | 'CartWitnessingRecord' | 'Settings' | 'CongregationSettings' | 
    'Literature' |'ScheduleEntry' | 'ScheduleWeek' | 'all' | 'Activity' | 'Knowledge' | 'CongregationEvent' | 'Territory' | 'TerritoryGroup' | 
    'Admin' | 'Files' | 'CongregationState';

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

export default function defineRulesFor(roles: Role[]) {
    const { can, cannot, rules } = new AbilityBuilder(AppAbility);

    for (const role of roles) {
        if (role.name === 'admin') {
            can('manage', 'all');
            return rules;
        }
        if (role.name === 'secretary') {
            can('manage', 'all');
            return rules;
        }
        if (role.name === 'elder') {
            can('read', 'all');
            can('manage', 'Publisher');
            can('read', 'Accounting');
            can('manage', 'Cart');
            can('manage', 'CartWitnessingPlace');
            can('read', 'ScheduleEntry');
            can('read', 'ScheduleWeek');
            can('manage', 'Settings');
            can('read', 'Literature');
            can('read', 'Territory');
            can('manage', 'Knowledge');
            can('manage', 'Files');
            can('manage', 'CongregationEvent');
            can('read', 'CongregationState');
        }
        if (role.name === 'overseer') {
            can('read', 'all');
        }
        if (role.name === 'user' || role.name === 'servant') {
            can('read', 'User');
            cannot('update', 'UserRole');
            can('read', 'Groups');
            can('read', 'Cart');
            can('read', 'Territory');
            can('read', 'ScheduleEntry');
            can('read', 'ScheduleWeek');
            can('manage', 'Settings');
            can('read', 'CongregationEvent');
        }
        if (role.name === 'servant') {
            can('read', 'GroupReports');
        }
        if (role.name === "cart_editor") {
            can('manage', 'CartWitnessingRecord');
        }
        if (role.name === "cart_place_editor") {
            can('manage', 'CartWitnessingPlace');
        }
        if (role.name === "accountant") {
            can('manage', 'Accounting');
        }
        if (role.name === "schedule_editor") {
            can('manage', 'ScheduleEntry');
            can('manage', 'ScheduleWeek');
        }
        if (role.name === "attendance_editor") {
            can('manage', 'Attendance');
        }
        if (role.name === "literature_editor") {
            can('manage', 'Literature');
        }
        if (role.name === "accountant_reader") {
            can('read', 'Accounting');
        }
        if (role.name === "congregation_event_editor") {
            can('manage', 'CongregationEvent');
        }
        if (role.name === "territory_editor") {
            can('manage', 'Territory');
            can('manage', 'TerritoryGroup');
        }
        if (role.name === "user_editor") {
            can('manage', 'User');
            can('manage', 'Users');
            can('manage', 'UserRole');
        }
    }

    return rules;
}

export function buildAbilityFor(roles: Role[]): AppAbility {
    return new AppAbility(defineRulesFor(roles));
}
