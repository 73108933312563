import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Can from '../../../components/Can';
import { useTranslation } from "react-i18next";
import SideBarMenuItem from '../SideBarMenuItem';

const SideBarUsersMenu = () => {
    const { t } = useTranslation();
    return (
        <Can do="read" on='Users'>
            <SideBarMenuItem id="side_menu_item_users" to='/users' text={t("users")}>
                <AccountCircleIcon />
            </SideBarMenuItem>
        </Can>
    )
}

export default SideBarUsersMenu;