import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../stores';
import { Territory } from '../../../../domain/Territory';
import { Publisher } from '../../../../domain/Publisher';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isMobileOnly } from 'react-device-detect';
import { useOpenDialog } from '../../../../hooks/useOpenDialog';
import ReturnTerritoryDialog from '../../dialogs/ReturnTerritoryDialog';
import TerritoryAssignmentsTable from './TerritoryAssignmentsTable';
import MobileTerritoryAssignmentsTable from './MobileTerritoryAssignmentsTable';
import { TerritoryAssignment } from '../../../../domain/TerritoryAssignment';
import EditAssignmentDialog from '../../dialogs/EditAssignmentDialog';
import ShowTerritoryDialog from '../../dialogs/ShowTerritoryDialog';


const TerritoriesAssignmentTab = observer(() => {
    const { publisherStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [publishers, setPublishers] = React.useState<Publisher[]>([]);
    const [selectedTerritory, setSelectedTerritory] = React.useState<Territory | null>(null);
    const [selectedAssignment, setSelectedAssignment] = React.useState<TerritoryAssignment | null>(null);

    React.useEffect(() => {
        publisherStore.getPublishersWithTerritoryAssignments((data) => {
            setPublishers(data);
        })
    }, [publisherStore]);

    const openTerritoryDialog = React.useCallback((territory: Territory) => {
        setSelectedTerritory(territory);
        openDialog("show-territory");
    }, []);

    const openReturnTerritoryDialog = React.useCallback((territory: Territory) => {
        setSelectedTerritory(territory);
        openDialog("return-territory");
    }, []);

    const openEditAssignmentDialog = React.useCallback((assignment: TerritoryAssignment) => {
        setSelectedAssignment(assignment);
        openDialog("edit-assignment")
    }, []);

    const handleCloseDialog = React.useCallback(() => {
        closeDialog();
        setSelectedTerritory(null);
        setSelectedAssignment(null);
    }, []);

    const handleTerritoryReturned = React.useCallback(() => {
        publisherStore.getPublishersWithTerritoryAssignments((data) => {
            setPublishers(data);
        })
    }, []);


    const handleAssignmentChange = React.useCallback(() => {
        publisherStore.getPublishersWithTerritoryAssignments((data) => {
            setPublishers(data);
        })
    }, []);

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            {publishers.map(publ => {
                return <Accordion key={publ.id} defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography><b>{`${publ.lastName} ${publ.firstName}`}</b> - {publ.territoryAssignments!.length}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {isMobileOnly ? (
                            <MobileTerritoryAssignmentsTable
                                publisher={publ}
                                onShowTerritory={openTerritoryDialog}
                                onTerritoryReturn={openReturnTerritoryDialog}
                            />
                        ) : (
                            <TerritoryAssignmentsTable
                                publisher={publ}
                                onShowTerritory={openTerritoryDialog}
                                onTerritoryReturn={openReturnTerritoryDialog}
                                onAssignmentEdit={openEditAssignmentDialog}
                            />
                        )}

                    </AccordionDetails>
                </Accordion>
            })}
            <ShowTerritoryDialog
                territory={selectedTerritory!}
                isOpen={isOpen("show-territory")}
                onClose={handleCloseDialog}
            />
            <ReturnTerritoryDialog
                territory={selectedTerritory!}
                isOpen={isOpen("return-territory")}
                onClose={handleCloseDialog}
                onTerritoryReturned={handleTerritoryReturned}
            />
            <EditAssignmentDialog
                assignment={selectedAssignment!}
                isOpen={isOpen("edit-assignment")}
                onClose={handleCloseDialog}
                disableReturnedAt
                onAssignmentChange={handleAssignmentChange}
            />
        </Container>
    );
});

export default TerritoriesAssignmentTab;