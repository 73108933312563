import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Panel from '../panel/Panel';
import { useStores } from '../../stores';
import { isMobileOnly } from 'react-device-detect';
import { Button, LinearProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { FieldServiceGroup } from '../../domain/FieldServiceGroup';
import { useOpenDialog } from '../../hooks/useOpenDialog';
import GroupUpdateDialog from './dialogs/GroupUpdateDialog';
import GroupCreateDialog from './dialogs/GroupCreateDialog';
import Can, { AbilityContext } from '../../components/Can';
import { useNavigate } from 'react-router-dom';
import FieldServiceGroupTable from './table/FieldServiceGroupTable';
import MobileFieldServiceGroupTable from './table/MobileFieldServiceGroupTable';
import { Publisher } from '../../domain/Publisher';
import { useConfirm } from 'material-ui-confirm';

const GroupsPage = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { serverStore, publisherStore } = useStores();
    const ability = React.useContext(AbilityContext);
    const confirm = useConfirm();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [selectedGroup, setSelectedGroup] = React.useState<FieldServiceGroup>();

    React.useEffect(() => {
        if (!publisherStore.groups) {
            publisherStore.getAllServiceGroups();
        }
    }, [publisherStore]);

    const goToPublisher = (publisher: Publisher) => {
        if (ability.can("update", "Publisher")) {
            navigate(`/publishers/${publisher.id}`)
        }
    }

    const goToReports = (group: FieldServiceGroup) => {
        navigate(`/group-reports/${group.id}`)
    }

    const open = (group: FieldServiceGroup) => {
        setSelectedGroup(group);
        openDialog("update-group")
    }

    const handleDelete = (id: number) => {
        confirm({
            title: t("are_you_sure"),
            description: `${t("this_will_permanently_delete")} ${selectedGroup!.name}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            publisherStore.deleteServiceGroup(id);
            setSelectedGroup(undefined);
            closeDialog();
        });
    }

    const handleClose = () => {
        setSelectedGroup(undefined);
        closeDialog();
    }

    return (
        <Panel
            title={t("groups")}
            headerItems={[
                <Can key="new_group_btn" do="create" on='FieldServiceGroup'>
                    <Button
                        type="button"
                        color="inherit"
                        onClick={() => openDialog("create-group")}>
                        {t("create_new_group")}
                    </Button>
                </Can>
            ]}>
            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    {isMobileOnly ?
                        <MobileFieldServiceGroupTable
                            groups={publisherStore.groups}
                            publishers={publisherStore.publishers}
                            onGroupEdit={open}
                            onPublisherEdit={goToPublisher}
                        /> :
                        <FieldServiceGroupTable
                            groups={publisherStore.groups}
                            publishers={publisherStore.publishers}
                            onGroupEdit={open}
                            onPublisherEdit={goToPublisher}
                            onGoToReports={goToReports}
                        />}
                    {serverStore.serverRequestLoading && <LinearProgress />}
                </Paper>
            </Container>
            <GroupCreateDialog
                isOpen={isOpen("create-group")}
                onClose={handleClose}
            />
            <GroupUpdateDialog
                isOpen={isOpen("update-group")}
                group={selectedGroup}
                onDelete={handleDelete}
                onClose={handleClose}
            />
        </Panel >
    );
});

export default GroupsPage;