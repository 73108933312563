import * as React from 'react';
import Panel from '../../panel/Panel';
import { Tabs, Tab } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import ScheduleSchoolAnalysisTab from './ScheduleSchoolAnalysisTab';
import { useStores } from '../../../stores';
import ScheduleAnalysis from '../../../domain/ScheduleAnalysis';
import moment from 'moment';
import ScheduleAnalysisToolbar from './ScheduleAnalysisToolbar';
import ScheduleStudentsTab from './ScheduleStudentsTab';

const ScheduleAnalysisPage = observer(() => {
    const { t } = useTranslation();
    const { scheduleStore } = useStores();
    const [from, setFrom] = React.useState(moment().startOf("month").startOf("week").toDate());
    const [to, setTo] = React.useState(moment().startOf("week").toDate());
    const [sort, setSort] = React.useState("default");
    const [tab, setTab] = React.useState(0);
    const [analysis, setAnalysis] = React.useState<ScheduleAnalysis | undefined>(undefined);

    React.useEffect(() => {
        scheduleStore.getAnalysis(from, to, sort, (analysis) => {
            setAnalysis(analysis);
        });
    }, [from, to, sort, scheduleStore]);

    const updateDates = (from: Date, to: Date) => {
        setFrom(from);
        setTo(to);
    }

    const updateSort = (sort: string) => {
        setSort(sort);
    }

    const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };


    return (
        <Panel
            title={t("schedule_analysis")}
            headerItems={[]}>
            <Tabs value={tab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
                <Tab label={t("school")} />
                <Tab label={t("students")} />
            </Tabs>
            <div role="tabpanel" hidden={tab !== 0}>
                <ScheduleAnalysisToolbar
                    from={from}
                    to={to}
                    sort={sort}
                    onRangeChange={updateDates}
                    onSortChange={updateSort}
                />
                <ScheduleSchoolAnalysisTab analysis={analysis} />
            </div>
            <div role="tabpanel" hidden={tab !== 1}>
                <ScheduleStudentsTab />
            </div>
        </Panel >
    );
});

export default ScheduleAnalysisPage;