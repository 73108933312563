import { ScheduleEntry } from '../../../domain/ScheduleEntry';
import ScheduleTableBlockHeader from './ScheduleTableBlockHeader';
import ScheduleTableSingleRow from './ScheduleTableSingleRow';
import ScheduleTableCollapseRow from './ScheduleTableCollapseRow';
import ScheduleTableBlockIntroHeader from './ScheduleTableBlockIntroHeader';
import { ScheduleWeek } from '../../../domain/ScheduleWeek';
import { EntryObject } from './ScheduleTableEntry';

interface Props {
    entries: EntryObject[];
    name: string;
    week?: ScheduleWeek;
    assigned: number[];
    editable?: boolean;
    onEntryUpdate?: (entry: ScheduleEntry) => void;
    onEntryEdit?: (entry: ScheduleEntry) => void;
    onEntryHistory?: (entry: ScheduleEntry) => void;
}

const ScheduleTableBlock = (props: Props) => {
    return (
        <>
            {props.name === "intro" ? <ScheduleTableBlockIntroHeader name={props.week && props.week.name} /> : <ScheduleTableBlockHeader name={props.name!} />}
            {props.entries.map((object: EntryObject) => {
                if (object.entries.length === 1) {
                    return (
                        <ScheduleTableSingleRow
                            key={object.name}
                            entry={object.entries[0]}
                            assigned={props.assigned}
                            editable={props.editable}
                            onEntryUpdate={props.onEntryUpdate}
                            onEntryEdit={props.onEntryEdit}
                            onEntryHistory={props.onEntryHistory}
                        />)
                } else {
                    return (
                        <ScheduleTableCollapseRow
                            key={object.name}
                            entries={object.entries}
                            assigned={props.assigned}
                            editable={props.editable}
                            onEntryUpdate={props.onEntryUpdate}
                            onEntryEdit={props.onEntryEdit}
                            onEntryHistory={props.onEntryHistory}
                        />
                    )
                }
            })}
        </>
    );
};

export default ScheduleTableBlock;