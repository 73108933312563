import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Panel from '../panel/Panel';
import { useStores } from '../../stores';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { AlertRecord } from '../../stores/AlertStore';

const AlertsPage = observer(() => {
    const { t } = useTranslation();
    const { alertStore } = useStores();

    return (
        <Panel
            title={t("notifications")}
            headerItems={[]}>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>{t("type")}</TableCell>
                                    <TableCell>{t("notification")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {alertStore.alerts && alertStore.alerts
                                    .map((alert: AlertRecord, i: number) => (
                                        <TableRow key={i}>
                                            <TableCell>{i+1}</TableCell>
                                            <TableCell>{alert.type}</TableCell>
                                            <TableCell>{alert.message}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Container>
        </Panel>
    );
});

export default AlertsPage;