import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, TextareaAutosize } from '@mui/material';
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { ScheduleEntry } from '../../../domain/ScheduleEntry';
import { withLazyMounting } from '../../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    onSubmit: (entry: ScheduleEntry) => void;
    onClose: () => void;
}

const ScheduleCreateFieldServiceEntryDialog = (props: Props) => {
    const { t } = useTranslation();

    const schema = yup.object().shape({
        name: yup.string().required(t("field_is_required", { name: t("name") })),
    });
    const formik = useFormik({
        validationSchema: schema,
        enableReinitialize: true,
        initialValues: {
            participants: "brothers",
            type: "field-service",
            subType: "intro",
            position: 1,
            room: 1,
            companionNeeded: false
        } as ScheduleEntry,
        onSubmit: (entry, { resetForm }) => {
            props.onSubmit(entry);
            resetForm();
            props.onClose();
        }
    });

    const { values } = formik;
    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("field_service")}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={10} padding={1}>
                            <TextField
                                margin="normal"
                                id="name"
                                label={t("name")}
                                fullWidth
                                variant="standard"
                                value={values.name || ''}
                                onChange={formik.handleChange}
                            />
                        </Grid>

                        <Grid item xs={2} padding={1}>
                            <TextField
                                margin="normal"
                                id="position"
                                label={t("position")}
                                type="number"
                                fullWidth
                                variant="standard"
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                value={values.position || ''}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    </Grid>

                    <TextareaAutosize
                        id="notes"
                        value={values.notes || ''}
                        minRows={6}
                        placeholder={t("notes")}
                        style={{ width: "100%" }}
                        onChange={formik.handleChange}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button type='submit'>{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default withLazyMounting(ScheduleCreateFieldServiceEntryDialog);