import { IconButton, TableCell, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { Territory } from '../../../domain/Territory';
import { isMobile } from 'react-device-detect';

interface Props {
    index: number;
    territory: Territory;
    onTerritoryFocus: (territory: Territory) => void;
    onTerritoryAssign: (territory: Territory) => void;
    onTerritoryEdit: (territory: Territory) => void;
}

const TerritoryTableRow = (props: Props) => {

    return (
        <TableRow>
            <TableCell padding='none' >{props.index + 1}</TableCell>
            <TableCell onClick={() => props.onTerritoryFocus(props.territory)}>
                {`${props.territory.title}`}
            </TableCell>
            <TableCell padding='none'>
                {!isMobile && <IconButton size='small' onClick={() => props.onTerritoryEdit(props.territory)}>
                    <EditIcon />
                </IconButton>}
                <IconButton size='small' onClick={() => props.onTerritoryAssign(props.territory)}>
                    <AssignmentIndIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default TerritoryTableRow;