import { useStores } from '../../../stores';
import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getLocale } from '../../../utils/localeUtils';
import moment from 'moment';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

const OverseerReportsDialog = observer((props: Props) => {
    const { t } = useTranslation();
    const { reportStore } = useStores();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { year: new Date() },
        onSubmit: (values) => {
            reportStore.exportOverseerReports(values);
            props.onClose();
        },
    });

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>{t("download_reports_for_circuit_overseer")}</DialogTitle>
                <DialogContent>

                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                        <DatePicker
                            label={t("year")}
                            views={['year']}
                            maxDate={new Date()}
                            value={formik.values.year}
                            onChange={value => {
                                formik.setFieldValue('year', value ? moment(value).format('YYYY-MM') : value);
                            }}
                            renderInput={(params) => <TextField {...params} margin="normal" name="year" />}
                        />
                    </LocalizationProvider>

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button color="primary" type="submit">{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});

export default OverseerReportsDialog;