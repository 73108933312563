import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Panel from '../panel/Panel';
import { useStores } from '../../stores';
import { Button, IconButton, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { observer } from 'mobx-react-lite';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from "react-i18next";
import Can from '../../components/Can';
import { useOpenDialog } from '../../hooks/useOpenDialog';
import { useConfirm } from 'material-ui-confirm';
import FileRenameDialog from './dialogs/FileRenameDialog';
import ShowFileDialog from './dialogs/ShowFileDialog';
import { isMobile } from 'react-device-detect';

const ALLOWED_TYPES = ['application/pdf', 'image/jpeg', 'image/png'];

const FilesPage = observer(() => {
    const { t } = useTranslation();
    const { serverStore, filesStore, notificationsStore } = useStores();
    const confirm = useConfirm();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [selectedFile, setSelectedFile] = React.useState<string>();
    const fileInput = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        filesStore.getFileList();
    }, [filesStore]);

    const uploadFile = (event: any) => {
        const file = event.target.files[0];
        if (file && ALLOWED_TYPES.includes(file.type)) {
            filesStore.uploadFile(file);
        } else {
            notificationsStore.add(t("wrong_file_type"), "error");
        }
    }

    const downloadFile = (name: string) => {
        filesStore.downloadFile(name);
    }

    const openShowFileDialog = (file: string) => {
        setSelectedFile(file);
        openDialog("file-show-dialog");
    }

    const openRenameDialog = (file: string) => {
        setSelectedFile(file);
        openDialog("file-rename-dialog");
    }

    const handleCloseDialog = () => {
        setSelectedFile(undefined);
        closeDialog()
    }

    const deleteFile = (file: string) => {
        confirm({
            title: t("are_you_sure"),
            description: `${t("this_will_permanently_delete")} ${file}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            filesStore.deleteFile(file);
        });
    }

    const headerItems = () => {
        if (isMobile) {
            return [];
        } else {
            return [
                <Can key="upload_file_btn" do="create" on='Files'>
                    <Button
                        id="upload_file_btn"
                        type="button"
                        color="inherit"
                        onClick={() => fileInput.current && fileInput.current.click()}>
                        {t("upload_file")}
                        <input ref={fileInput} type="file" hidden onChange={uploadFile} />
                    </Button>
                </Can>
            ];
        }
    }

    return (
        <Panel
            title={t("files")}
            headerItems={headerItems()}>
            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    {serverStore.serverRequestLoading && <LinearProgress />}
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>{t("name")}</TableCell>
                                    <TableCell>{t("actions")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filesStore.files && filesStore.files
                                    .map((file: string, i: number) => (
                                        <TableRow key={file}>
                                            <TableCell>{i + 1}</TableCell>
                                            <TableCell>{file}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => openShowFileDialog(file)}>
                                                    <VisibilityIcon color="primary" />
                                                </IconButton>
                                                <IconButton onClick={() => downloadFile(file)}>
                                                    <DownloadIcon color="primary" />
                                                </IconButton>
                                                <IconButton onClick={() => openRenameDialog(file)}>
                                                    <EditIcon color="primary" />
                                                </IconButton>
                                                <IconButton onClick={() => deleteFile(file)}>
                                                    <DeleteIcon color="error" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Container>
            <ShowFileDialog
                isOpen={isOpen("file-show-dialog")}
                fileName={selectedFile!}
                onClose={handleCloseDialog}
            />
            <FileRenameDialog
                isOpen={isOpen("file-rename-dialog")}
                fileName={selectedFile!}
                onClose={handleCloseDialog}
            />
        </Panel >
    );
});

export default FilesPage;