import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { useStores } from '../../stores';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { Attendance } from '../../domain/Attendance';
import AttendanceChart from './AttendanceChart';
import { LinearProgress } from '@mui/material';

interface Props {
    onEdit: (attendance: Attendance) => void;
}

const AttendanceChartTab = observer((props: Props) => {
    const { t } = useTranslation();
    const { serverStore, attendanceStore } = useStores();

    const handleChartClick = (id: number) => {
        const all = [...attendanceStore.weekends, ...attendanceStore.workdays];
        const attendance = all.find(attend => attend.id === id);
        if (attendance) {
            props.onEdit(attendance);
        }
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 700 }}>
                {serverStore.serverRequestLoading && <LinearProgress />}
                <h2>{t("workdays")}</h2>
                <AttendanceChart attends={attendanceStore.workdays} onBarClick={handleChartClick} />
                <h2>{t("weekends")}</h2>
                <AttendanceChart attends={attendanceStore.weekends} onBarClick={handleChartClick} />
            </Paper>
        </Container>
    );
});

export default AttendanceChartTab;