import { IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { TerritoryRequest } from '../../../domain/TerritoryRequest';
import { useTranslation } from 'react-i18next';
import { Territory } from '../../../domain/Territory';

interface Props {
    requests: TerritoryRequest[];
    onTerritoryShow: (territory: Territory) => void;
    onRequestDelete: (request: TerritoryRequest) => void;
}

const MyTerritoryRequestsTable = (props: Props) => {
    const { t } = useTranslation();

    if (props.requests.length > 0) {
        return (
            <TableContainer>
                <Table size="small">
                    <TableBody>
                        {props.requests.map((request, i) => (
                            <TableRow key={i}>
                                <TableCell padding='none' >{i + 1}</TableCell>
                                <TableCell>
                                    {`${request.territory!.title}`}
                                </TableCell>
                                <TableCell padding='none'>
                                    <IconButton color="primary" onClick={() => props.onTerritoryShow(request.territory!)}>
                                        <VisibilityIcon />
                                    </IconButton>
                                    <IconButton color="error" size='small' onClick={() => props.onRequestDelete(request)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    } else {
        return <Typography>{t("no_territory_requests_yet")}</Typography>
    }
};

export default MyTerritoryRequestsTable;