import SettingsIcon from '@mui/icons-material/Settings';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import Can from '../../../components/Can';
import { useTranslation } from "react-i18next";
import SideBarMenuItem from '../SideBarMenuItem';

const SideBarSettingsMenu = () => {
    const { t } = useTranslation();
    return (
        <>
            <Can do="update" on='Settings'>
                <SideBarMenuItem id="side_menu_item_settings" to='/settings' text={t("settings")}>
                    <SettingsIcon />
                </SideBarMenuItem>
            </Can>
            <Can do="manage" on='Activity'>
                <SideBarMenuItem id="side_menu_item_activity" to='/activity' text={t("activity")}>
                    <PeopleOutlineIcon />
                </SideBarMenuItem>
            </Can>
        </>
    )
}

export default SideBarSettingsMenu;