import * as React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { AbilityContext } from '../../../../components/Can';
import { ScheduleEntry } from '../../../../domain/ScheduleEntry';
import PublisherTextField from '../PublisherTextField';
import ScheduleSpeakerInput from '../ScheduleSpeakerInput';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../stores';

interface Props {
    name?: string;
    entry: ScheduleEntry;
    assigned: number[];
    editable?: boolean;
    onEntryUpdate?: (entry: ScheduleEntry) => void;
    onEntryEdit?: (entry: ScheduleEntry) => void;
    onEntryHistory?: (entry: ScheduleEntry) => void;
}

const ScheduleTableMobileSingleRow = observer((props: Props) => {
    const { publisherStore } = useStores();
    const ability = React.useContext(AbilityContext);

    let isEditable = ability.can("manage", "ScheduleEntry");
    if (props.editable !== undefined) {
        isEditable = props.editable;
    }

    const myPublisher = publisherStore.myPublisher;
    const isMyPublisher = myPublisher && (myPublisher.id === props.entry.speakerId || myPublisher.id === props.entry.companionId);
    const isImpromptu = !!props.entry.impromptuId;

    const backgroundColor = isMyPublisher ? "#2e7d3214" : isImpromptu ? "#c628281c" : undefined
    return (
        <>
            <TableRow style={{ backgroundColor }}>
                <TableCell sx={{ fontWeight: 700, fontSize: '0.80rem' }}>{props.name || props.entry.name}</TableCell>
            </TableRow>
            <TableRow sx={{ borderBottom: '2px solid rgba(0, 0, 0, 0.1)' }} >
                <TableCell>
                    {isEditable ? (
                        <>
                            <ScheduleSpeakerInput
                                entry={props.entry}
                                editable={isEditable}
                                assigned={props.assigned}
                                onEntryUpdate={props.onEntryUpdate}
                            />

                            {props.entry.companionNeeded &&
                                <PublisherTextField
                                    entry={props.entry}
                                    field="companion"
                                    editable={isEditable}
                                    assigned={props.assigned}
                                    onEntryUpdate={props.onEntryUpdate}
                                />}
                        </>
                    ) : (
                        <>
                            <ScheduleSpeakerInput
                                entry={props.entry}
                                editable={isEditable}
                                assigned={props.assigned}
                                onEntryUpdate={props.onEntryUpdate}
                            />
                            {props.entry.speaker && props.entry.companionNeeded && ' | '}
                            <PublisherTextField
                                entry={props.entry}
                                field="companion"
                                editable={isEditable}
                                assigned={props.assigned}
                                onEntryUpdate={props.onEntryUpdate}
                            />
                        </>
                    )}
                </TableCell>
            </TableRow>

        </>

    );
});

export default ScheduleTableMobileSingleRow;