import { Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from "react-i18next";
import { ActivityRecord } from '../../domain/ActivityRecord';
import moment from 'moment';

interface Props {
    records: ActivityRecord[];
}

const ActivityErrorTab = (props: Props) => {
    const { t } = useTranslation();

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("date")}</TableCell>
                                <TableCell>{t("user")}</TableCell>
                                <TableCell>{t("records")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.records
                                .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                                .map((record: ActivityRecord) => (
                                    <TableRow key={record.date + ""}>
                                        <TableCell>{moment(record.date).format("HH:mm")}</TableCell>
                                        <TableCell><b>{record.user}</b></TableCell>
                                        <TableCell>{record.record}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Container>
    );
};

export default ActivityErrorTab;