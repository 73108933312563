import { IconButton, TableCell, TableFooter, TableRow } from '@mui/material';
import Can from '../../../components/Can';
import { AccountingEntry } from '../../../domain/AccountingEntry';
import { sum } from '../../../utils/dateUtils';
import { AccountingStartingPoint } from '../../../domain/AccountingStartingPoint';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { border, format } from './accountingTableUtils';

interface Props {
    date: Date;
    entries: AccountingEntry[];
    startingPoint: AccountingStartingPoint;
    onSummaryOpen: () => void;
}

const AccountingTableFooter = (props: Props) => {

    const calculateIncomeSum = (type: string) => {
        const filtered = props.entries.filter(entry =>
            (entry.transferDirection === type && entry.side === "transfer") ||
            (entry.type === type && entry.side === "income")
        );
        return sum(filtered, "amount");
    }

    const calculateExpensesSum = (type: string) => {
        const filtered = props.entries.filter(entry =>
            entry.type === type &&
            (entry.side === "expenses" || entry.side === "transfer")
        );
        return sum(filtered, "amount");
    }


    const calculateEndSum = (type: string) => {
        const income = (props.startingPoint as any)[type] + calculateIncomeSum(type);
        const expenses = calculateExpensesSum(type);
        return income - expenses;
    }

    const cashSum = calculateEndSum("cash");
    const bankSum = calculateEndSum("bank");
    const totalSum = (cashSum + bankSum);


    return (
        <TableFooter>
            <TableRow  >
                <TableCell sx={border} align="left" colSpan={5}></TableCell>
                <TableCell sx={border} style={{ backgroundColor: "#1565c021" }} align="center" colSpan={2}>
                    <h2>{format(cashSum)}</h2>
                </TableCell>
                <TableCell sx={border} style={{ backgroundColor: "#1565c021" }} align="center" colSpan={2}>
                    <h2>{format(bankSum)}</h2>
                </TableCell>
                <TableCell sx={border} style={{ backgroundColor: "#1565c021" }} align="center" colSpan={2}>
                    <h2>{format(totalSum)}</h2>
                </TableCell>
                <Can do="manage" on='Accounting'>
                    <TableCell align="center" colSpan={1} style={{ backgroundColor: "#1565c021" }}>
                        <IconButton onClick={props.onSummaryOpen}>
                            <SummarizeIcon />
                        </IconButton>
                    </TableCell>
                </Can>
            </TableRow>
        </TableFooter>
    );
};

export default AccountingTableFooter;