import * as React from 'react';
import { Container } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useStores } from '../../../stores';
import { useOpenDialog } from '../../../hooks/useOpenDialog';
import { Territory } from '../../../domain/Territory';
import Panel from '../../panel/Panel';
import RequestTerritoryDialog from '../dialogs/RequestTerritoryDialog';
import { TerritoryRequest } from '../../../domain/TerritoryRequest';
import { isMobileOnly } from 'react-device-detect';
import MobileTerritoryRequestingComponent from './MobileTerritoryRequestingComponent';
import TerritoryRequestingComponent from './TerritoryRequestingComponent';
import ShowTerritoryDialog from '../dialogs/ShowTerritoryDialog';

const TerritoryRequestPage = observer(() => {
    const { t } = useTranslation();
    const { territoryStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [territoryRequests, setTerritoryRequests] = React.useState<TerritoryRequest[]>([]);
    const [currentTerritory, setCurrentTerritory] = React.useState<Territory | undefined>();
    const [selectedTerritory, setSelectedTerritory] = React.useState<Territory | undefined>();

    React.useEffect(() => {
        if (territoryStore.groups.length === 0) {
            territoryStore.getAllGroups();
        }
        territoryStore.getMyTerritoryRequests((requests) => {
            setTerritoryRequests(requests);
        })
    }, [territoryStore]);


    const handleCloseDialog = () => {
        closeDialog();
        setSelectedTerritory(undefined);
    }

    const openRequestDialog = (territory?: Territory) => {
        if (territory) {
            setSelectedTerritory(territory);
            setCurrentTerritory(territory);
            openDialog("request-territory");
        }
    }

    const openShowTerritoryDialog = (territory?: Territory) => {
        if (territory) {
            setSelectedTerritory(territory);
            openDialog("show-territory");
        }
    }

    const focusTerritory = (territory: Territory) => {
        setCurrentTerritory(territory);
    }

    const handleTerritoryRequest = () => {
        territoryStore.getMyTerritoryRequests((requests) => {
            setTerritoryRequests(requests);
        });
    }

    const deleteRequest = (request: TerritoryRequest) => {
        territoryStore.deleteTerritoryRequest(request.id, handleTerritoryRequest);
    }

    return (
        <Panel title={t("request")} headerItems={[]}>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                {isMobileOnly ? <MobileTerritoryRequestingComponent
                    requests={territoryRequests}
                    onTerritoryShow={openShowTerritoryDialog}
                    onTerritoryRequest={openRequestDialog}
                    onTerritoryRequestDeletion={deleteRequest}
                /> : <TerritoryRequestingComponent
                    requests={territoryRequests}
                    currentTerritory={currentTerritory}
                    onTerritoryShow={openShowTerritoryDialog}
                    onTerritorySelection={openRequestDialog}
                    onTerritoryFocus={focusTerritory}
                    onTerritoryRequest={openRequestDialog}
                    onTerritoryRequestDeletion={deleteRequest}
                />}

            </Container>
            <RequestTerritoryDialog
                territory={selectedTerritory!}
                isOpen={isOpen("request-territory")}
                onTerritoryRequest={handleTerritoryRequest}
                onClose={handleCloseDialog}
            />
            <ShowTerritoryDialog
                territory={selectedTerritory!}
                isOpen={isOpen("show-territory")}
                onClose={handleCloseDialog}
            />
        </Panel >
    );
});

export default TerritoryRequestPage;