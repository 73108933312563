import { Table, TableBody, TableContainer } from '@mui/material';
import { ScheduleEntry } from '../../domain/ScheduleEntry';
import { ScheduleWeek } from '../../domain/ScheduleWeek';
import { prepareAssigned } from './utils/scheduleUtils';
import ScheduleTableEntry from './schedule-table/ScheduleTableEntry';
import { ScheduleView } from './ScheduleContent';


interface Props {
    week?: ScheduleWeek;
    entries: ScheduleEntry[];
    editable?: boolean;
    view: ScheduleView;
    onEntryUpdate?: (entry: ScheduleEntry) => void;
    onEntryEdit?: (entry: ScheduleEntry) => void;
    onEntryHistory?: (entry: ScheduleEntry) => void;
}

const ScheduleWeekendTab = (props: Props) => {
    const assigned = prepareAssigned(props.entries);
    return (
        <TableContainer>
            <Table size="small">
                <TableBody>
                    <ScheduleTableEntry
                        name="intro"
                        week={props.week}
                        entries={props.entries.filter(entry => entry.subType !== "assignment")}
                        assigned={assigned}
                        editable={props.editable}
                        view={props.view}
                        onEntryUpdate={props.onEntryUpdate}
                        onEntryEdit={props.onEntryEdit}
                        onEntryHistory={props.onEntryHistory} />
                    <ScheduleTableEntry
                        name="assignment"
                        week={props.week}
                        entries={props.entries.filter(entry => entry.subType === "assignment")}
                        assigned={assigned}
                        editable={props.editable}
                        view={props.view}
                        onEntryUpdate={props.onEntryUpdate}
                        onEntryEdit={props.onEntryEdit}
                        onEntryHistory={props.onEntryHistory} />
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ScheduleWeekendTab;