import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { useStores } from '../../stores';
import { observer } from 'mobx-react-lite';
import S88ReportsTable from '../../components/s88reports/S88ReportsTable';
import Panel from '../panel/Panel';
import { useTranslation } from 'react-i18next';

const PivotAttendancePage = observer(() => {
    const { t } = useTranslation();
    const { reportStore } = useStores();
    const [reports, setReports] = React.useState<any[]>([]);

    React.useEffect(() => {
        reportStore.getPivotAttendance((data: any[]) => {
            setReports(data);
        });
    }, [reportStore]);

    return (
        <Panel
            title={t("pivot_reports")}
            headerItems={[]}
        >
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <S88ReportsTable values={reports} />
                </Paper>
            </Container>
        </Panel >
    );
});

export default PivotAttendancePage;