import * as React from 'react';
import { useStores } from '../../../stores';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { User } from '../../../domain/User';
import { useTranslation } from "react-i18next";
import { withLazyMounting } from '../../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    user: User;
    onClose: () => void;
}

const SendPushNotificationDialog = observer((props: Props) => {
    const { t } = useTranslation();
    const { webPushStore, notificationsStore } = useStores();
    const [message, setMessage] = React.useState<string>("");

    const handleChange = (e: any) => {
        setMessage(e.target.value);
    }

    const sendPushNotification = () => {
        if (message.length) {
            webPushStore.sendPushNotification(props.user.id, message);
            notificationsStore.add(t("message_is_sent"), "success");
            props.onClose();
        }
    }

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
                <DialogTitle>{t("send_notification")}</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="normal"
                        id="message"
                        label={t("message")}
                        fullWidth
                        variant="standard"
                        onChange={handleChange}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button color="primary" onClick={sendPushNotification}>{t("submit")}</Button>
                </DialogActions>
        </Dialog>
    );
});

export default withLazyMounting(SendPushNotificationDialog);