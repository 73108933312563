import Container from '@mui/material/Container';
import { Card, CardContent, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";

interface Props {
    index: number;
    tab: number;
}

const PersonalDataTab = observer((props: Props) => {
    const { t } = useTranslation();

    return (
        <div role="tabpanel" hidden={props.tab !== props.index} >
            <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            {t("personal_data")}
                        </Typography>
                        <p>{t("personal_data_long_text_1")}</p>
                        <p>{t("personal_data_long_text_2")}</p>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
});

export default PersonalDataTab;