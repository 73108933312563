import * as React from 'react';
import { Container, Paper, TableContainer, TablePagination } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useStores } from '../../../../stores';
import { TerritoryAssignment } from '../../../../domain/TerritoryAssignment';
import { Territory } from '../../../../domain/Territory';
import { useOpenDialog } from '../../../../hooks/useOpenDialog';
import AssignTerritoryDialog from '../../dialogs/AssignTerritoryDialog';
import ShowTerritoryDialog from '../../dialogs/ShowTerritoryDialog';
import EditAssignmentDialog from '../../dialogs/EditAssignmentDialog';
import { isMobileOnly } from 'react-device-detect';
import { useConfirm } from 'material-ui-confirm';
import MobileTerritoryAssignmentHistoryTable from './MobileTerritoryAssignmentHistoryTable';
import TerritoryAssignmentHistoryTable from './TerritoryAssignmentHistoryTable';

const PAGE_SIZE = 10;

const TerritoryAssignmentHistoryTab = observer(() => {
    const { t } = useTranslation();
    const confirm = useConfirm();
    const { territoryStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [page, setPage] = React.useState<number>(1);
    const [history, setHistory] = React.useState<TerritoryAssignment[]>([]);
    const [selectedTerritory, setSelectedTerritory] = React.useState<Territory | undefined>();
    const [selectedAssignment, setSelectedAssignment] = React.useState<TerritoryAssignment | undefined>();

    React.useEffect(() => {
        territoryStore.getHistory(page, PAGE_SIZE, (data) => {
            setHistory(data);
        })
    }, [page]);

    const doRefresh = () => {
        confirm({
            title: t("are_you_sure"),
            description: `${t("do_you_want_to_fetch_assignment_data_from_th")}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            territoryStore.refreshTerritoryAssignments(3);
        });

    }

    const handleChangePage = (_: any, newPage: number) => {
        setPage(newPage + 1);
    };

    const handleAssignmentChange = () => {
        territoryStore.getHistory(page, PAGE_SIZE, (data) => {
            setHistory(data);
        })
    }

    const handleAssignmentDeletion = () => {
        territoryStore.getHistory(page, PAGE_SIZE, (data) => {
            setHistory(data);
        })
    }

    const openTerritoryDialog = (territory: Territory) => {
        setSelectedTerritory(territory);
        openDialog("show-territory");
    }

    const openAssignmentDialog = (territory: Territory) => {
        setSelectedTerritory(territory);
        openDialog("assign-territory");
    }

    const openEditAssignmentDialog = (assignment: TerritoryAssignment) => {
        setSelectedAssignment(assignment);
        openDialog("edit-assignment");
    }

    const handleCloseDialog = () => {
        closeDialog();
        setSelectedTerritory(undefined);
        setSelectedAssignment(undefined);
    }

    return (
        <>
            <Container maxWidth="xl" sx={{ mb: 4 }}>
                <TableContainer component={Paper}>
                    {isMobileOnly ?
                        <MobileTerritoryAssignmentHistoryTable
                            history={history}
                            onTerritoryShow={openTerritoryDialog}
                        /> :
                        <TerritoryAssignmentHistoryTable
                            history={history}
                            onTerritoryShow={openTerritoryDialog}
                            onTerritoryAssign={openAssignmentDialog}
                            onAssignmentEdit={openEditAssignmentDialog}
                            onAssignmentsRefresh={doRefresh}
                        />}
                </TableContainer>
                <TablePagination
                    component="div"
                    count={-1}
                    rowsPerPageOptions={[]}
                    rowsPerPage={PAGE_SIZE}
                    page={page - 1}
                    onPageChange={handleChangePage}
                />
            </Container>
            <ShowTerritoryDialog
                territory={selectedTerritory!}
                isOpen={isOpen("show-territory")}
                onClose={handleCloseDialog}
            />
            <AssignTerritoryDialog
                territory={selectedTerritory!}
                isOpen={isOpen("assign-territory")}
                onAssignmentEdit={openEditAssignmentDialog}
                onClose={handleCloseDialog}
            />
            <EditAssignmentDialog
                assignment={selectedAssignment!}
                isOpen={isOpen("edit-assignment")}
                onClose={handleCloseDialog}
                onAssignmentChange={handleAssignmentChange}
                onAssignmentDeletion={handleAssignmentDeletion}
            />
        </>

    );
});

export default TerritoryAssignmentHistoryTab;