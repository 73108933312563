import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Publisher } from '../../../domain/Publisher';
import { useTranslation } from "react-i18next";
import moment from 'moment';

interface Props {
    publisher?: Publisher;
}

const PublisherSummary = (props: Props) => {
    const { t } = useTranslation();

    if (props.publisher) {
        const publisher = props.publisher!;
        const age = publisher.birthDate ? moment().diff(moment(publisher.birthDate), 'years') : t('no');
        const baptised = publisher.baptisedAt ? moment().diff(moment(publisher.baptisedAt), 'years') : t('no');
        const active = publisher.inactive ? 'no' : 'yes';

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{t('age')}</TableCell>
                            <TableCell align="center">{t('baptised')}</TableCell>
                            <TableCell align="center">{t('active')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            <TableCell align="center"><h2>{age}</h2></TableCell>
                            <TableCell align="center"><h2>{baptised}</h2></TableCell>
                            <TableCell align="center"><h2>{t(active)}</h2></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    } else {
        return <span />
    }
};

export default PublisherSummary;