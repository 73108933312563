import Panel from '../../panel/Panel';
import { useTranslation } from "react-i18next";
import MyTerritoriesContent from './MyTerritoriesContent';

const MyTerritoriesPage = () => {
    const { t } = useTranslation();
    return (
        <Panel title={t("my_territories")} headerItems={[]}>
            <MyTerritoriesContent size="lg" />
        </Panel >
    );
};

export default MyTerritoriesPage;