import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { ServerStore } from './ServerStore';

export class CongregationStateStore {
    rootStore: RootStore;
    serverStore: ServerStore;

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        makeAutoObservable(this);
    }

    getCongregationState = (callback: (state: any) => void) => {
        this.serverStore.get('/congregation-state', action((data) => {
            callback(data);
        }));
    }
}