import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { useStores } from '../../../stores';
import { IconButton, LinearProgress } from '@mui/material';
import { Publisher } from '../../../domain/Publisher';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FieldServiceReportDialog from '../dialogs/FieldServiceReportDialog';
import { DataGrid, GridFilterModel, GridSortModel, GridToolbar } from '@mui/x-data-grid';
import { useTranslation } from "react-i18next";
import { useOpenDialog } from '../../../hooks/useOpenDialog';
import GenerateS21Dialog from '../../../components/GenerateS21Dialog';
import { getGridLocale } from '../../../utils/localeUtils';
import { isMobile, isMobileOnly } from 'react-device-detect';

const PublishersTab = observer(() => {
    const { t } = useTranslation();
    const { serverStore, publisherStore, panelStore } = useStores();
    const navigate = useNavigate();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [selectedPublisher, setSelectedPublisher] = React.useState<Publisher>();

    const handlePublisherClick = (id: number) => {
        navigate(`/publishers/${id}`);
    }

    const selectPublisherAndOpenDialog = (id: number, dialog: string) => {
        const publisher = publisherStore.publishers.find(publ => publ.id === id);
        setSelectedPublisher(publisher);
        openDialog(dialog)
    }

    const handleCloseDialog = () => {
        closeDialog()
        setSelectedPublisher(undefined);
    };

    const handleOnCellClick = (params: any) => {
        if (params.field === 'firstName' || params.field === "lastName") {
            handlePublisherClick(params.id);
        }
    };

    const getGroupName = (publisher: Publisher): string => {
        if (publisher.serviceGroup) {
            if (publisherStore.groups) {
                const group = publisherStore.groups.find(group => group.id === publisher.serviceGroup.id);
                if (group) {
                    return group.name;
                }
            }
        }
        return '';
    }

    const handleFilterChange = (model: GridFilterModel) => {
        panelStore.setPublishersPageFilter(model);
    }

    const handleSortChange = (model: GridSortModel) => {
        panelStore.setPublishersPageSort(model);
    }

    const handlePageChange = (page: number) => {
        panelStore.setPublishersPagePage(page);
    }

    const visibleColumns = () => {
        let columns = {
            address: false,
            phoneNumber: false,
            gender: false,
            anointed: false,
            inactive: false
        } as any;
        if (isMobileOnly) {
            columns = {
                ...columns,
                baptisedAt: false,
                birthDate: false,
                serviceGroup: false,
                appointments: false,
                actions: false
            }
        }
        return columns;
    }

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                {serverStore.serverRequestLoading && <LinearProgress />}
                <div style={{ height: 700, width: '100%' }} >
                    <DataGrid
                        localeText={getGridLocale().components.MuiDataGrid.defaultProps.localeText}
                        rowHeight={30}
                        onCellClick={handleOnCellClick}
                        onFilterModelChange={handleFilterChange}
                        onSortModelChange={handleSortChange}
                        onPageChange={handlePageChange}
                        filterModel={panelStore.publishersPage.filter}
                        sortModel={panelStore.publishersPage.sort}
                        page={panelStore.publishersPage.page}
                        pageSize={60}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'lastName', sort: 'asc' }],
                            },
                            columns: {
                                columnVisibilityModel: visibleColumns(),
                            },
                        }}
                        rows={
                            publisherStore.publishers && publisherStore.publishers
                                .map((publisher: Publisher) => {
                                    return {
                                        id: publisher.id,
                                        firstName: publisher.firstName,
                                        lastName: publisher.lastName,
                                        birthDate: publisher.birthDate && publisher.birthDate.toLocaleString(),
                                        gender: t(publisher.gender),
                                        anointed: publisher.anointed ? t('yes') : t('no'),
                                        inactive: publisher.inactive ? t('yes') : t('no'),
                                        address: publisher.address,
                                        phoneNumber: publisher.phoneNumber,
                                        serviceGroup: getGroupName(publisher),
                                        appointments: publisher.appointments.map(app => t(app.name)).join(', '),
                                        baptisedAt: publisher.baptisedAt && publisher.baptisedAt.toLocaleString()
                                    }
                                })
                        }
                        columns={[
                            { field: 'lastName', headerName: t('surname'), flex: 1 },
                            { field: 'firstName', headerName: t('name'), flex: 1 },
                            { field: 'birthDate', headerName: t('birth_date'), flex: 1 },
                            { field: 'baptisedAt', headerName: t('baptised_at'), flex: 1 },
                            { field: 'gender', headerName: t('gender'), flex: 1 },
                            { field: 'anointed', headerName: t('anointed'), flex: 1 },
                            { field: 'inactive', headerName: t('inactive'), flex: 1 },
                            { field: 'address', headerName: t('address'), flex: 1 },
                            { field: 'phoneNumber', headerName: t('phone_number'), flex: 1 },
                            { field: 'serviceGroup', headerName: t('service_group'), flex: 1 },
                            { field: 'appointments', headerName: t('appointments'), flex: 1 },
                            {
                                field: 'actions', headerName: t('actions'), flex: 1,
                                renderCell: (cellValues) => (<>
                                    <IconButton onClick={() => handlePublisherClick(+cellValues.id)}>
                                        <InfoIcon />
                                    </IconButton>
                                    <IconButton onClick={() => selectPublisherAndOpenDialog(+cellValues.id, "submit-s4")} >
                                        <SummarizeIcon />
                                    </IconButton>
                                    <IconButton onClick={() => selectPublisherAndOpenDialog(+cellValues.id, "generate-s21")} >
                                        <AssessmentIcon />
                                    </IconButton>
                                </>)
                            },
                        ]}
                        components={{ Toolbar: isMobile ? undefined : GridToolbar }}
                    />
                </div>
            </Paper>
            <FieldServiceReportDialog
                isOpen={isOpen("submit-s4")}
                publisher={selectedPublisher!}
                onClose={handleCloseDialog} />
            <GenerateS21Dialog
                isOpen={isOpen("generate-s21")}
                publisherId={selectedPublisher && selectedPublisher!.id}
                onClose={handleCloseDialog} />
        </Container>
    );
});

export default PublishersTab;