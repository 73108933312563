import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../stores';
import { useSnackbar } from 'notistack';

const Notifier = observer(() => {
    const { t } = useTranslation();
    const { notificationsStore } = useStores();
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        notificationsStore.notifications.forEach((notification) => {
            enqueueSnackbar(t(notification.message, notification.values), {
                variant: notification.type,
                autoHideDuration: 3000,
            });
            notificationsStore.remove(notification.id);
        });
    }, [notificationsStore.notifications.length]);

    return null;
});

export default Notifier;