import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Autocomplete } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from "react-i18next";
import { AccountingEntry } from '../../../domain/AccountingEntry';
import { getLocale } from '../../../utils/localeUtils';
import { withLazyMounting } from '../../../components/hoc/LazyDialog';
import { useStores } from '../../../stores';
import { Publisher } from '../../../domain/Publisher';

interface Props {
    isOpen: boolean;
    entries: AccountingEntry[];
    onClose: () => void;
}

const AccountingS24Dialog = observer((props: Props) => {
    const { t } = useTranslation();
    const { publisherStore, accountingStore, notificationsStore } = useStores();

    let formData = {
        date: moment(props.entries[0].date).format('YYYY-MM-DD'),
        amount1: props.entries[0].amount,
        amount2: props.entries[1].amount,
    } as any;

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formData,
        onSubmit: (values) => {

            if (!values.receiptNumber || !values.publisher1 || !values.publisher2) {
                notificationsStore.add(t("no_enough_receipt_data"), "error");
                return;
            }

            accountingStore.downloadS24({
                date: moment(values.date).utcOffset(0, true).toDate(),
                receiptNumber: values.receiptNumber,
                worldwideAmount: values.amount1,
                congregationAmount: values.amount2,
                publisher1Id: values.publisher1.id,
                publisher2Id: values.publisher2.id
            });

            props.onClose();
        }
    });

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("receipt")}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>

                    <Grid container>
                        <Grid item xs={6} padding={1}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                                <DatePicker
                                    label={t("date")}
                                    maxDate={new Date()}
                                    value={formik.values.date}
                                    onChange={value => {
                                        formik.setFieldValue('date', value ? moment(value).format('YYYY-MM-DD') : value);
                                    }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            error={!!formik.errors.date}
                                            helperText={formik.errors.date && formik.errors.date as string}
                                            margin="normal"
                                            name="date"
                                        />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} padding={1}>
                            <TextField
                                error={!!formik.errors.receiptNumber}
                                margin="normal"
                                id="receiptNumber"
                                label={t("receipt_number")}
                                fullWidth
                                variant="standard"
                                helperText={formik.errors.receiptNumber && formik.errors.receiptNumber as string}
                                value={formik.values.receiptNumber || ''}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={6} padding={1}>
                            <TextField
                                margin="normal"
                                id="amount1"
                                label={t("worldwide")}
                                type="number"
                                variant="standard"
                                disabled
                                value={formik.values.amount1 || '0.00'}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={6} padding={1}>
                            <TextField
                                margin="normal"
                                id="amount2"
                                label={t("congregation_expenses")}
                                type="number"
                                variant="standard"
                                disabled
                                value={formik.values.amount2 || '0.00'}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    </Grid>

                    <Autocomplete
                        disablePortal
                        id="publisher1"
                        options={publisherStore.findLocalAppointedPublishers()}
                        getOptionLabel={(publisher: Publisher) => publisher.firstName ? `${publisher.lastName} ${publisher.firstName}` : ''}
                        value={formik.values.publisher}
                        renderInput={(params) => <TextField {...params} margin="normal" label={t("publisher")} />}
                        onChange={(e, publisher) => {
                            formik.setFieldValue("publisher1", publisher)
                        }}
                    />

                    <Autocomplete
                        disablePortal
                        id="publisher2"
                        options={publisherStore.findLocalAppointedPublishers()}
                        getOptionLabel={(publisher: Publisher) => publisher.firstName ? `${publisher.lastName} ${publisher.firstName}` : ''}
                        value={formik.values.publisher}
                        renderInput={(params) => <TextField {...params} margin="normal" label={t("publisher")} />}
                        onChange={(e, publisher) => {
                            formik.setFieldValue("publisher2", publisher)
                        }}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button type='submit'>{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});

export default withLazyMounting(AccountingS24Dialog);