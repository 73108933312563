import { configure } from 'mobx';
import { createContext } from 'react';
import { UserStore } from './UserStore';
import { PanelStore } from './PanelStore';
import { PublisherStore } from './PublisherStore';
import { ServerStore } from './ServerStore';
import { CongregationInfoStore } from './CongregationInfoStore';
import { AppointmentStore } from './AppointmentStore';
import { ReportStore } from './ReportStore';
import { AttendanceStore } from './AttendanceStore';
import { ApplicationStore } from './ApplicationStore';
import { NotificationsStore } from './NotificationStore';
import { AccountingStore } from './AccountingStore';
import { CartWitnessingStore } from './CartWitnessingStore';
import { ScheduleStore } from './ScheduleStore';
import { ActivityStore } from './ActivityStore';
import { LiteratureStore } from './LiteratureStore';
import { AlertStore } from './AlertStore';
import { KnowledgeStore } from './KnowledgeStore';
import { CongregationEventsStore } from './CongregationEvents';
import { TerritoryStore } from './TerritoryStore';
import { FilesStore } from './FilesStore';
import { LocalStorageStore } from './LocalStorageStore';
import { WebPushStore } from './WebPushStore';
import { CongregationStateStore } from './CongregationStateStore';
configure({ enforceActions: "always" })

export class RootStore {
    notificationsStore: NotificationsStore;
    serverStore: ServerStore;
    applicationStore: ApplicationStore;
    userStore: UserStore;
    localStorageStore: LocalStorageStore;
    panelStore: PanelStore;
    publisherStore: PublisherStore;
    appointmentStore: AppointmentStore;
    attendanceStore: AttendanceStore;
    congregationInfoStore: CongregationInfoStore;
    congregationEventsStore: CongregationEventsStore;
    reportStore: ReportStore;
    literatureStore: LiteratureStore;
    accountingStore: AccountingStore;
    cartWitnessingStore: CartWitnessingStore;
    scheduleStore: ScheduleStore;
    activityStore: ActivityStore;
    alertStore: AlertStore;
    knowledgeStore: KnowledgeStore;
    territoryStore: TerritoryStore;
    filesStore: FilesStore;
    webPushStore: WebPushStore;
    congregationStateStore: CongregationStateStore;

    constructor() {
        this.notificationsStore = new NotificationsStore(this);
        this.serverStore = new ServerStore(this, this.notificationsStore);
        this.applicationStore = new ApplicationStore(this, this.serverStore);
        this.userStore = new UserStore(this, this.serverStore);
        this.localStorageStore = new LocalStorageStore();
        this.panelStore = new PanelStore(this.localStorageStore);
        this.publisherStore = new PublisherStore(this, this.serverStore);
        this.appointmentStore = new AppointmentStore(this, this.serverStore);
        this.attendanceStore = new AttendanceStore(this, this.serverStore);
        this.congregationInfoStore = new CongregationInfoStore(this, this.serverStore);
        this.congregationEventsStore = new CongregationEventsStore(this, this.serverStore);
        this.reportStore = new ReportStore(this, this.serverStore);
        this.literatureStore = new LiteratureStore(this, this.serverStore);
        this.accountingStore = new AccountingStore(this, this.serverStore);
        this.cartWitnessingStore = new CartWitnessingStore(this, this.serverStore);
        this.scheduleStore = new ScheduleStore(this, this.serverStore);
        this.activityStore = new ActivityStore(this, this.serverStore);
        this.alertStore = new AlertStore(this, this.serverStore);
        this.knowledgeStore = new KnowledgeStore(this, this.serverStore);
        this.territoryStore = new TerritoryStore(this, this.serverStore);
        this.filesStore = new FilesStore(this, this.serverStore);
        this.webPushStore = new WebPushStore(this, this.serverStore);
        this.congregationStateStore = new CongregationStateStore(this, this.serverStore);

        if (this.userStore.userData.loggedIn) {
            this.prepareData();
        }
    }

    prepareData = () => {
        this.webPushStore.registerServiceWorker();
        this.publisherStore.getAllServiceGroups();
        this.publisherStore.getMyPublisher();
        this.publisherStore.getAllPublishers();
        this.userStore.getAllRoles();
        this.appointmentStore.getAll();
        this.alertStore.getAlerts();
    }
}

export const rootStoreContext = createContext(new RootStore());