import { useStores } from '../../../stores';
import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Autocomplete } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Role, User } from '../../../domain/User';
import { Publisher } from '../../../domain/Publisher';
import { useTranslation } from "react-i18next";
import Can from '../../../components/Can';
import * as yup from 'yup';
import { withLazyMounting } from '../../../components/hoc/LazyDialog';

interface Props {
    isOpen: boolean;
    user: User;
    onClose: () => void;
}

interface FormValues {
    username: string;
    email: string;
    roles: Role[];
    linkedPublisher?: Publisher | undefined;
}

const UpdateUserDialog = observer((props: Props) => {
    const { t } = useTranslation();
    const { userStore, publisherStore } = useStores();

    const initialValues = {
        username: props.user.username,
        email: props.user.email || '',
        roles: props.user.roles,
        linkedPublisher: publisherStore.publishers.find(p => p.id === props.user!.publisherId)
    } as FormValues;

    const schema = yup.object().shape({
        username: yup.string().required(t("field_is_required", { name: t("username") })),
        email: yup.string().email(),
        roles: yup.array().min(1, t('add_at_least_one_role')).required(),
        linkedPublisher: yup.object().nullable(),
    });
    const formik = useFormik({
        validationSchema: schema,
        enableReinitialize: true,
        initialValues,
        onSubmit: (values) => {
            const userData = { ...values } as any;
            if (values.linkedPublisher) {
                userData.linkedPublisher = values.linkedPublisher.id
            }
            if (props.user) {
                userData.id = props.user.id;
            }

            userStore.updateUser(userData, () => {
                props.onClose();
            });
        },
    });

    const { errors, values } = formik;
    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>{t("update_user")}</DialogTitle>
                <DialogContent>

                    <TextField
                        margin="normal"
                        id="username"
                        label={t("username")}
                        type="text"
                        fullWidth
                        variant="standard"
                        error={!!errors.username}
                        helperText={errors.username && errors.username as string}
                        value={values.username || ''}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        margin="normal"
                        id="email"
                        label={t("email")}
                        type="email"
                        fullWidth
                        variant="standard"
                        error={!!errors.email}
                        helperText={errors.email && errors.email as string}
                        value={values.email || ''}
                        onChange={formik.handleChange}
                    />
                    <Can do="update" on='UserRole'>
                        <Autocomplete
                            disablePortal={false}
                            id="roles"
                            multiple
                            options={userStore.roles}
                            getOptionLabel={(role: Role) => role.name}
                            value={formik.values.roles || []}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    error={!!errors.roles}
                                    helperText={errors.roles && errors.roles as string}
                                    margin="normal"
                                    label={t("roles")}
                                />}
                            onChange={(e, roles) => {
                                formik.setFieldValue("roles", roles)
                            }}
                        />
                    </Can>
                    <Can do="update" on='UserRole'>
                        <Autocomplete
                            disablePortal={false}
                            id="linkedPublisher"
                            options={publisherStore.publishers}
                            getOptionLabel={(publisher: Publisher) => publisher.firstName ? `${publisher.firstName} ${publisher.lastName}` : ''}
                            value={formik.values.linkedPublisher || {} as Publisher}
                            renderInput={(params) => <TextField {...params} margin="normal" label={t("linked_publisher")} />}
                            onChange={(e, publisher) => {
                                formik.setFieldValue("linkedPublisher", publisher)
                            }}
                        />
                    </Can>

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button color="primary" type="submit">{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});

export default withLazyMounting(UpdateUserDialog);