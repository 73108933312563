import * as React from 'react';
import Panel from '../panel/Panel';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { Container, Paper } from '@mui/material';
import { useOpenDialog } from '../../hooks/useOpenDialog';
import { useStores } from '../../stores';
import { useConfirm } from 'material-ui-confirm';
import { Calendar, Event, momentLocalizer, SlotInfo } from 'react-big-calendar';
import { isMobileOnly } from 'react-device-detect';
import moment from 'moment';
import { CongregationEvent } from '../../domain/CongregationEvent';
import CongregationEventDialog from './CongregationEventDialog';
import { AbilityContext } from '../../components/Can';
import "./CongregationEventsCalendar.css"
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

const CongregationEventsPage = observer(() => {
    const { t } = useTranslation();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const { congregationEventsStore, publisherStore } = useStores();
    const confirm = useConfirm();
    const [from, setFrom] = React.useState<Date>(new Date());
    const [to, setTo] = React.useState<Date>(new Date());
    const [events, setEvents] = React.useState<Event[]>([]);
    const [selectedEvent, setSelectedEvent] = React.useState<CongregationEvent | undefined>();
    const ability = React.useContext(AbilityContext);

    React.useEffect(() => {
        congregationEventsStore.getAll();
    }, [congregationEventsStore]);

    React.useEffect(() => {
        const events = congregationEventsStore.events.map(event => {
            return {
                link: event,
                start: moment(event.from).toDate(),
                end: moment(event.to).toDate(),
                title: event.title
            }
        })
        setEvents(events);
    }, [congregationEventsStore.events]);

    const saveEvent = (data: any) => {
        congregationEventsStore.createEvent(data);
    }

    const updateEvent = (data: any) => {
        congregationEventsStore.updateEvent(data.id, data);
    }

    const deleteEvent = (event: CongregationEvent) => {
        confirm({
            title: t("are_you_sure"),
            description: `${t("this_will_permanently_delete")}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            closeDialog();
            setSelectedEvent(undefined);
            congregationEventsStore.deleteEvent(event.id);
        });
    }

    const handleSelectSlot = React.useCallback((slotInfo: SlotInfo) => {
        if (ability.can("create", "CongregationEvent")) {
            const from = moment(slotInfo.start);
            const to = moment(slotInfo.end);
    
            if (from.isAfter(moment())) {
                setFrom(from.toDate());
                setTo(to.toDate());
                openDialog("new-event");
            }
        }
    }, [setEvents]);

    const handleSelectEvent = React.useCallback((event: any) => {
        setSelectedEvent(event.link);
        openDialog("update-event");
    }, []);

    const className = isMobileOnly ? 'custom-calendar' : undefined;
    const prevText = isMobileOnly ? "<" : t("previous");
    const nextText = isMobileOnly ? ">" : t("next");

    return (
        <Panel
            title={t("events")}
            headerItems={[]}>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ height: 600 }}>
                        <Calendar
                            className={className}
                            dayLayoutAlgorithm="no-overlap"
                            localizer={localizer}
                            events={events}
                            formats={{ eventTimeRangeFormat: () => '' }}
                            onSelectEvent={handleSelectEvent}
                            onSelectSlot={handleSelectSlot}
                            selectable
                            startAccessor="start"
                            endAccessor="end"
                            defaultView={'month'}
                            views={{ month: true, week: true, day: true }}
                            messages={{
                                date: t("date"),
                                today: t("today"),
                                time: t("time"),
                                event: t("event"),
                                allDay: t("all_day"),
                                week: t("week"),
                                work_week: t("work_week"),
                                day: t("day"),
                                month: t("month"),
                                previous: prevText,
                                next: nextText,
                                yesterday: t("yesterday"),
                                tomorrow: t("tomorrow"),
                                agenda: t("agenda"),
                                noEventsInRange: t("no_events_in_range"),
                            }}
                        />
                    </div>
                </Paper>
            </Container>
            <CongregationEventDialog
                isOpen={isOpen("new-event")}
                from={from}
                to={to}
                publishers={publisherStore.publishers}
                onSubmit={saveEvent}
                onClose={closeDialog}
            />
            <CongregationEventDialog
                isOpen={isOpen("update-event")}
                event={selectedEvent}
                publishers={publisherStore.publishers}
                onSubmit={updateEvent}
                onClose={closeDialog}
                onDelete={deleteEvent}
            />
        </Panel >
    );
});

export default CongregationEventsPage;