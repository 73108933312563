import { AccountingEntry } from "../../../domain/AccountingEntry";

export const border = { borderRight: 1, borderColor: "#0000001A" };

export const format = (value: any) => {
    if (value) {
        return (+value).toFixed(2);
    } else return '';
}

export const color = (entry: AccountingEntry) => {
    if (entry.side === "transfer") {
        return "#e0fa191A";
    } else if (entry.code === "EDI" || entry.code === "DI") {
        return "#fa19190D";
    } else if (entry.code === "RM" || entry.code === "R" || entry.code === "PMN") {
        return "#2419fa0D";
    } else if (entry.code === "ZE") {
        return "#1dfa190A";
    }
}