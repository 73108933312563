import { ScheduleEntry } from '../../../../domain/ScheduleEntry';
import ScheduleTableBlockHeader from '../ScheduleTableBlockHeader';
import { ScheduleWeek } from '../../../../domain/ScheduleWeek';
import ScheduleTableMobileSingleRow from './ScheduleTableMobileSingleRow';
import { TableCell, TableRow } from '@mui/material';
import ScheduleTableMobileCollapseRow from './ScheduleTableMobileCollapseRow';
import { EntryObject } from '../ScheduleTableEntry';

interface Props {
    name: string;
    week?: ScheduleWeek;
    entries: EntryObject[];
    assigned: number[];
    editable?: boolean;
    onEntryUpdate?: (entry: ScheduleEntry) => void;
    onEntryEdit?: (entry: ScheduleEntry) => void;
    onEntryHistory?: (entry: ScheduleEntry) => void;
}

const ScheduleTableBlockMobile = (props: Props) => {
    return (
        <>
            {props.name === "intro" ? (
                <TableRow>
                    <TableCell colSpan={1} style={{ color: "white", backgroundColor: "#000", fontSize: "1.3em" }}>{props.week && props.week.name}</TableCell>
                </TableRow>)
                : <ScheduleTableBlockHeader name={props.name!} />}
            {props.entries.map((object: EntryObject) => {
                if (object.entries.length === 1) {
                    return (
                        <ScheduleTableMobileSingleRow
                            key={object.name}
                            entry={object.entries[0]}
                            assigned={props.assigned}
                            editable={props.editable}
                            onEntryUpdate={props.onEntryUpdate}
                            onEntryEdit={props.onEntryEdit}
                            onEntryHistory={props.onEntryHistory}
                        />)
                } else {
                    return (
                        <ScheduleTableMobileCollapseRow
                            key={object.name}
                            entries={object.entries}
                            assigned={props.assigned}
                            editable={props.editable}
                            onEntryUpdate={props.onEntryUpdate}
                            onEntryEdit={props.onEntryEdit}
                            onEntryHistory={props.onEntryHistory}
                        />
                    )
                }
            })}
        </>
    );
};

export default ScheduleTableBlockMobile;