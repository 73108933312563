import * as React from 'react';
import { Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from "react-i18next";
import { FieldServiceGroup } from '../../../domain/FieldServiceGroup';
import Can from '../../../components/Can';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box } from '@mui/system';
import { Publisher } from '../../../domain/Publisher';
import { LiteratureType } from '../../../domain/LiteratureType';
import { LiteratureRequest } from '../../../domain/LiteratureRequest';
import { sum } from '../../../utils/dateUtils';
import { useOpenDialog } from '../../../hooks/useOpenDialog';
import UpdateLiteratureRequestDialog from '../dialogs/UpdateLiteratureRequestDialog';

interface Props {
    group: FieldServiceGroup;
    publishers: Publisher[];
    types: LiteratureType[];
    requests: LiteratureRequest[];
}

const LiteratureTableRow = (props: Props) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [selectedPublisher, setSelectedPublisher] = React.useState<Publisher>();

    const openEditDialog = (publisher: Publisher) => {
        setSelectedPublisher(publisher);
        openDialog("edit-literature-requests")
    }

    const handleCloseDialog = () => {
        setSelectedPublisher(undefined);
        closeDialog();
    }

    const publisherIds = props.publishers.map(pub => pub.id);
    const groupRequests = props.requests ? props.requests.filter(req => publisherIds.includes(req.publisherId)) : [];
    const requestSum = props.types ? props.types.map(type => {
        return sum(groupRequests.filter(req => req.literatureTypeId === type.id), "number");
    }) : [];

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton size="small" onClick={() => setOpen(!open)} >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{props.group.name}</TableCell>
                {requestSum && requestSum.map((req, i) => (
                    <TableCell key={i} align="center"><h3>{req}</h3></TableCell>
                ))}

            </TableRow>
            <TableRow>
                <TableCell style={{ backgroundColor: "#00000008" }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box  sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                {t("publishers")}
                            </Typography>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell />
                                        {props.types && props.types.map(type => (
                                            <TableCell key={type.name} align="center">{type.name}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.publishers.map((publisher, i) => {
                                        const requests = groupRequests.filter(req => req.publisherId === publisher.id);
                                        return (
                                            <TableRow key={publisher.id} >
                                                <TableCell>{i + 1}</TableCell>
                                                <TableCell>
                                                    {`${publisher.lastName} ${publisher.firstName}`}
                                                </TableCell>
                                                {props.types && props.types.map(type => {
                                                    const request = requests.find(req => req.literatureTypeId === type.id);
                                                    let value = request ? request.number : "";
                                                    value = value !== 0 ? value : "";
                                                    return <TableCell key={type.id} align="center">{value}</TableCell>;
                                                })}
                                                <Can do="manage" on='Literature'>
                                                    <TableCell>
                                                        <IconButton size='small' onClick={() => openEditDialog(publisher)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </Can>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <UpdateLiteratureRequestDialog
                requests={props.requests}
                types={props.types}
                publisher={selectedPublisher}
                isOpen={isOpen("edit-literature-requests")}
                onClose={handleCloseDialog}
            />
        </>
    );
};

export default LiteratureTableRow;