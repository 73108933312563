import SummarizeTwoToneIcon from '@mui/icons-material/SummarizeTwoTone';
import Can from '../../../components/Can';
import { useTranslation } from "react-i18next";
import SideBarSubMenuItem from '../SideBarSubMenuItem';
import SideBarMenuCollapseItem from '../SideBarMenuCollapseItem';

const SideBarReportsMenu = () => {
    const { t } = useTranslation();
    return (
        <SideBarMenuCollapseItem icon={<SummarizeTwoToneIcon />} type="reports" text={t("reports")}>
            <SideBarSubMenuItem
                id="side_menu_item_my_reports"
                to="/my-reports"
                text={t("my_reports")} />

            <Can do="read" on='GroupReports'>
                <SideBarSubMenuItem
                    id="side_menu_item_group_reports"
                    to="/group-reports"
                    text={t("group_reports")} />
            </Can>
            
            <Can do="read" on='OverallReport'>
                <SideBarSubMenuItem
                    id="side_menu_item_overall_report"
                    to="/overall-report"
                    text={t("overall_report")} />
            </Can>
        </SideBarMenuCollapseItem>
    )
}

export default SideBarReportsMenu;