import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from "react-i18next";
import { FieldServiceGroup } from '../../../domain/FieldServiceGroup';
import Can from '../../../components/Can';
import FieldServiceGroupTableRow from './FieldServiceGroupTableRow';
import { Publisher } from '../../../domain/Publisher';

interface Props {
    groups: FieldServiceGroup[];
    publishers: Publisher[];
    onGroupEdit: (group: FieldServiceGroup) => void;
    onPublisherEdit: (publisher: Publisher) => void;
    onGoToReports: (group: FieldServiceGroup) => void;
}

const FieldServiceGroupTable = (props: Props) => {
    const { t } = useTranslation();

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>{t("name")}</TableCell>
                        <TableCell>{t("overseer")}</TableCell>
                        <TableCell>{t("Assistant")}</TableCell>
                        <Can do="manage" on='Groups'>
                            <TableCell>{t("actions")}</TableCell>
                        </Can>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.groups && props.groups
                        .slice() // copy the array because sorting mutates original array
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((group: FieldServiceGroup) => (
                            <FieldServiceGroupTableRow
                                key={group.id}
                                group={group}
                                publishers={props.publishers.filter(publisher => publisher.serviceGroup && publisher.serviceGroup.id === group.id)}
                                onGroupEdit={props.onGroupEdit}
                                onPublisherEdit={props.onPublisherEdit}
                                onGoToReports={props.onGoToReports}
                            />
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default FieldServiceGroupTable;