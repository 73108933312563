import { Table, TableBody } from '@mui/material';
import { Box } from '@mui/system';
import { Territory } from '../../../domain/Territory';
import TerritoryTableRow from './TerritoryTableRow';

interface Props {
    territories: Territory[];
    onTerritoryFocus: (territory: Territory) => void;
    onTerritoryAssign: (territory: Territory) => void;
    onTerritoryEdit?: (territory: Territory) => void;
}

const TerritoriesTable = (props: Props) => {

    const handleTerritoryEdit = (territory: Territory) => {
        props.onTerritoryEdit && props.onTerritoryEdit(territory)
    }

    return (
        <Box>
            <Table>
                <TableBody>
                    {props.territories.map((territory, i) => (
                        <TerritoryTableRow
                            key={territory.id}
                            index={i}
                            territory={territory}
                            onTerritoryFocus={props.onTerritoryFocus}
                            onTerritoryEdit={(territory) => handleTerritoryEdit(territory)}
                            onTerritoryAssign={props.onTerritoryAssign}
                        />
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};

export default TerritoriesTable;