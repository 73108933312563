import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from "react-i18next";
import { AccountingAnalysis } from '../../../domain/AccountingAnalysis';

interface Props {
    analysis?: AccountingAnalysis[];
}

const AccountingAnalysisTable = (props: Props) => {
    const { t } = useTranslation();

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("date")}</TableCell>
                                <TableCell>{t("congregation_expenses")}</TableCell>
                                <TableCell>{t("worldwide")}</TableCell>
                                <TableCell>{t("income")}</TableCell>
                                <TableCell>{t("kingdom_hall")}</TableCell>
                                <TableCell>{t("resolution")}</TableCell>
                                <TableCell>{t("other")}</TableCell>
                                <TableCell>{t("expenses")}</TableCell>
                                <TableCell>{t("difference")}</TableCell>
                                <TableCell>{t("total")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.analysis && props.analysis.slice().reverse().map(row => (
                                <TableRow key={row.date}>
                                    <TableCell><h2>{row.date}</h2></TableCell>
                                    <TableCell>{row.congr}</TableCell>
                                    <TableCell>{row.ww}</TableCell>
                                    <TableCell><b>{row.income}</b></TableCell>
                                    <TableCell>{row.hall}</TableCell>
                                    <TableCell>{row.resolution}</TableCell>
                                    <TableCell>{row.otherExpenses}</TableCell>
                                    <TableCell><b>{row.expenses}</b></TableCell>
                                    <TableCell>{row.diff}</TableCell>
                                    <TableCell><h3>{row.total.toFixed(2)}</h3></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Container>
    );
};

export default AccountingAnalysisTable;