import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { FieldServiceGroup } from '../../../domain/FieldServiceGroup';
import { Publisher } from '../../../domain/Publisher';
import { LiteratureRequest } from '../../../domain/LiteratureRequest';
import { LiteratureType } from '../../../domain/LiteratureType';
import { sum } from '../../../utils/dateUtils';
import { Fragment } from 'react';

interface Props {
    groups: FieldServiceGroup[];
    publishers: Publisher[];
    types: LiteratureType[];
    requests: LiteratureRequest[];
}

const MobileLiteratureTable = (props: Props) => {

    const requestSum = (group: FieldServiceGroup) => {
        const publisherIds = props.publishers
            .filter(publisher => publisher.serviceGroup && publisher.serviceGroup.id === group.id)
            .map(pub => pub.id);
        const groupRequests = props.requests.filter(req => publisherIds.includes(req.publisherId));
        return props.types.map(type => ({ 
            name: type.name, 
            sum: sum(groupRequests.filter(req => req.literatureTypeId === type.id), "number") }));
    }

    return (
        <TableContainer>
            <Table size="small">
                <TableBody>
                    {props.groups
                        .slice() // copy the array because sorting mutates original array
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((group: FieldServiceGroup) => (
                            <Fragment key={group.id}>
                                <TableRow>
                                    <TableCell><b>{group.name}</b></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                {requestSum(group).map((req) => (
                                    <TableRow key={req.name}>
                                        <TableCell>{req.name}</TableCell>
                                        <TableCell>{req.sum}</TableCell>
                                    </TableRow>
                                ))}
                            </Fragment>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default MobileLiteratureTable;