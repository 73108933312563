import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { LiteratureRequest } from '../../../domain/LiteratureRequest';
import { LiteratureType } from '../../../domain/LiteratureType';
import { sum } from '../../../utils/dateUtils';
import { useTranslation } from 'react-i18next';

interface Props {
    types: LiteratureType[];
    requests: LiteratureRequest[];
}

const MobileLiteratureSummary = (props: Props) => {
    const { t } = useTranslation();

    const requestSum = props.types.map(type => {
        return { name: type.name, sum: sum(props.requests.filter(req => req.literatureTypeId === type.id), "number") };
    });

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell><b>{t("overall")}</b></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {requestSum.map(type => (
                        <TableRow key={type.name}>
                            <TableCell>{type.name}</TableCell>
                            <TableCell>{type.sum}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default MobileLiteratureSummary;