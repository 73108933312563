import Container from '@mui/material/Container';
import PreferencesScheduleCard from './PreferencesScheduleCard';
import PreferencesDefaultTabCard from './PreferencesDefaultTabCard';
import PreferencesWebPushCard from './PreferencesWebPushCard';

interface Props {
    index: number;
    tab: number;
}

const PreferencesTab = (props: Props) => {
    return (
        <div role="tabpanel" hidden={props.tab !== props.index} >
            <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
                <PreferencesScheduleCard />
                <br />
                <PreferencesDefaultTabCard />
                <br />
                <PreferencesWebPushCard />
                <br />
            </Container>
        </div>
    );
};

export default PreferencesTab;