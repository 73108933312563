import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { useStores } from '../../stores';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from '@mui/material';
import { observer } from 'mobx-react-lite';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from "react-i18next";
import Can from '../../components/Can';
import { useConfirm } from 'material-ui-confirm';
import { Attendance } from '../../domain/Attendance';
import moment from 'moment';

interface Props {
    onEdit: (attendance: Attendance) => void;
    onDelete: (attendId: number) => void;
}

const ROWS_PER_PAGE = 15;

const AttendanceTableTab = observer((props: Props) => {
    const { t } = useTranslation();
    const { attendanceStore } = useStores();
    const confirm = useConfirm();
    const [page, setPage] = React.useState(0);

    const deleteAttendance = (attendance: Attendance) => {
        confirm({
            title: t("are_you_sure"),
            description: t("this_will_permanently_delete"),
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            props.onDelete(attendance.id);
        });
    }

    let attendances = [...attendanceStore.workdays, ...attendanceStore.weekends];
    if (attendances.length) {
        attendances = attendances.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    }

    const handleChangePage = (_: any, newPage: number) => {
        setPage(newPage);
    };

    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("date")}</TableCell>
                                <TableCell>{t("day_of_week")}</TableCell>
                                <TableCell>{t("count")}</TableCell>
                                <Can do="manage" on='Attendance'>
                                    <TableCell>{t("actions")}</TableCell>
                                </Can>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {attendances
                                .slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE)
                                .map((attendance: Attendance) => (
                                    <TableRow key={attendance.id}>
                                        <TableCell>{moment(attendance.date).format("DD.MM.YYYY")}</TableCell>
                                        <TableCell>{moment(attendance.date).format("dddd")}</TableCell>
                                        <TableCell><b>{attendance.number}</b></TableCell>
                                        <TableCell>
                                            <Can do="manage" on='Attendance'>
                                                <IconButton onClick={() => props.onEdit(attendance)}>
                                                    <EditIcon color="primary" />
                                                </IconButton>
                                                <IconButton onClick={() => deleteAttendance(attendance)}>
                                                    <DeleteIcon color="error" />
                                                </IconButton>
                                            </Can>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={attendances.length}
                    rowsPerPage={ROWS_PER_PAGE}
                    page={page}
                    rowsPerPageOptions={[]}
                    onPageChange={handleChangePage}
                />
            </Paper>
        </Container>
    );
});

export default AttendanceTableTab;