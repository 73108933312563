import * as React from 'react';
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../stores';

const PreferencesWebPushCard = observer(() => {
    const { webPushStore, userStore } = useStores();
    const { t } = useTranslation();
    const [permitted, setPermitted] = React.useState<boolean>(false);
    const isWebPushEnabled = process.env.REACT_APP_WEB_PUSH_FEATURE_ENABLED === "true";

    React.useEffect(() => {
        const getPermission = async () => {
            const permission = Notification.permission === "granted";
            setPermitted(permission);
        }
        getPermission();
    }, []);

    const subscribe = async () => {
        await webPushStore.subscribeUser();
    };

    const unsubscribe = async () => {
        await webPushStore.unsubscribeUser();
    };

    if (isWebPushEnabled) {
        return (
            <Card>
                <CardContent>
                    <Typography variant="h5" component="div">
                        {t("push_notifications")}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {permitted ? t("push_notifications_enabled") : t("push_notifications_disabled")}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {t("go_to_settings_to_change")}
                    </Typography>
                </CardContent>
                <CardActions>
                    {userStore.userData.webPushSubscriptionId ?
                        (<Button
                            variant='contained'
                            color='error'
                            onClick={unsubscribe}>
                            {t("unsubscribe")}
                        </Button>) :
                        (<Button
                            variant='contained'
                            color='primary'
                            onClick={subscribe}>
                            {t("subscribe")}
                        </Button>)}
                </CardActions>
            </Card>
        );
    } else return null;
});

export default PreferencesWebPushCard;