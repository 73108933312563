import * as React from 'react';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { IconButton } from '@mui/material';
import { useOpenDialog } from '../../hooks/useOpenDialog';
import { useConfirm } from 'material-ui-confirm';

interface Props {
    onAutoArrangementSelect: (type: string) => void;
}

const ScheduleAutoArrangementButton = observer((props: Props) => {
    const { t } = useTranslation();
    const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
    const confirm = useConfirm();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();

    const handleMenuButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        openDialog("menu");
        setAnchorMenu(event.currentTarget);
    }

    const handleCloseMenu = () => {
        closeDialog();
        setAnchorMenu(null);
    }

    const handleMenuItem = (type: string) => {
        confirm({
            title: t("are_you_sure"),
            description: `${t("this_will_rewrite_selected_schedule_entries")}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            props.onAutoArrangementSelect(type);
            handleCloseMenu();
        });
    }

    return (
        <>
            <Tooltip title={t("autoarrangement")}>
                <IconButton onClick={handleMenuButton}>
                    <AutoFixHighIcon />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorMenu}
                open={isOpen("menu")}
                onClose={handleCloseMenu}
            >
                <MenuItem onClick={() => handleMenuItem("workday")}>{t("workday")}</MenuItem>
                <MenuItem disabled onClick={() => handleMenuItem("school")}>{t("school")}</MenuItem>
                <MenuItem onClick={() => handleMenuItem("weekend")}>{t("weekend")}</MenuItem>
                <MenuItem onClick={() => handleMenuItem("assignment")}>{t("assignment")}</MenuItem>
                <MenuItem onClick={() => handleMenuItem("field-service")}>{t("field_service_meetings")}</MenuItem>
            </Menu>
        </>
    );
});

export default ScheduleAutoArrangementButton;