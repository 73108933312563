import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Territory } from '../../../../domain/Territory';
import { Publisher } from '../../../../domain/Publisher';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { TerritoryAssignment } from '../../../../domain/TerritoryAssignment';
import { useOpenDialog } from '../../../../hooks/useOpenDialog';

interface Props {
    publisher: Publisher;
    onShowTerritory: (territory: Territory) => void;
    onTerritoryReturn: (territory: Territory) => void;
}

const MobileTerritoryAssignmentsTable = (props: Props) => {
    const { t } = useTranslation();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [selectedAssignment, setSelectedAssignment] = React.useState<TerritoryAssignment | undefined>();

    const handleReturn = () => {
        closeDialog();
        if (selectedAssignment) {
            props.onTerritoryReturn(selectedAssignment.territory!)
        }
    }

    const title = (assign: TerritoryAssignment) => `${assign.territory!.territoryGroup!.name} - ${assign.territory!.title}`;

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("territory")}</TableCell>
                        <TableCell>{t("actions")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.publisher.territoryAssignments!.map(assign => {
                        return <TableRow key={assign.id}>
                            <TableCell onClick={() => props.onShowTerritory(assign.territory!)}>
                                {title(assign)}
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => {
                                    setSelectedAssignment(assign);
                                    openDialog("assignment-dialog");
                                }}>
                                    <KeyboardReturnIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
            {selectedAssignment && <Dialog open={isOpen("assignment-dialog")} fullWidth={true} onClose={closeDialog}>
                <DialogTitle>{t("territory")}</DialogTitle>
                <DialogContent>
                    <div><b>{`${t("territory")}: `}</b>{selectedAssignment.territory!.title}</div>
                    {selectedAssignment.territory!.territoryGroup && <div><b>{`${t("group")}: `}</b>{selectedAssignment.territory?.territoryGroup.name}</div>}
                    <br />
                    <div><b>{`${t("taken_at")}: `}</b>{moment(selectedAssignment.takenAt).format("YYYY-MM")}</div>
                    <div><b>{`${t("days_gone")}: `}</b>{moment().diff(moment(selectedAssignment.takenAt), 'days')}</div>
                    <br />
                    {selectedAssignment.notes && <div><b>{`${t("notes")}: `}</b>{selectedAssignment.notes}</div>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>{t("cancel")}</Button>
                    <Button onClick={handleReturn}>{t("return")}</Button>
                </DialogActions>
            </Dialog>}
        </TableContainer>
    );
};

export default MobileTerritoryAssignmentsTable;