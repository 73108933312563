import { Route, Routes } from 'react-router-dom';
import { AbilityContext } from '../../components/Can';
import { buildAbilityFor } from '../../permissions/ability';
import { useStores } from '../../stores';
import ActivityPage from '../activity/ActivityPage';
import AttendancePage from '../attendance/AttendancePage';
import PivotAttendancePage from '../attendance/PivotAttendancePage';
import CartsPage from '../carts/CartsPage';
import GroupReportsPage from '../group-reports/GroupReportsPage';
import OverallReportPage from '../group-reports/OverallReportPage';
import GroupsPage from '../groups/GroupsPage';
import HomePage from '../home/HomePage';
import LiteraturePage from '../literature/LiteraturePage';
import LoginPage from '../login/LoginPage';
import MyReportsPage from '../my-reports/MyReportsPage';
import AlertsPage from '../alerts/AlertsPage';
import PivotReportsPage from '../pivot-reports/PivotReportsPage';
import PublisherDetailsPage from '../publishers/details/PublisherDetailsPage';
import PublishersPage from '../publishers/PublishersPage';
import ProtectedRoutes from '../routes/ProtectedRoutes';
import SchedulePage from '../schedule/SchedulePage';
import SettingsPage from '../settings/SettingsPage';
import UsersPage from '../users/UsersPage';
import ScheduleAnalysisPage from '../schedule/analysis/ScheduleAnalysisPage';
import KnowledgePage from '../knowledge/KnowledgePage';
import AccountingAnalysisPage from '../accounting/analysis/AccountingAnalysisPage';
import CongregationEventsPage from '../congregation-events/CongregationEventsPage';
import ScheduleSpeechPage from '../schedule/speech/ScheduleSpeechPage';
import CartsAnalysisPage from '../carts/CartsAnalysisPage';
import TerritoriesPage from '../territories/TerritoriesPage';
import MyTerritoriesPage from '../territories/my/MyTerritoriesPage';
import TerritoriesAssignmentPage from '../territories/assignments/TerritoriesAssignmentPage';
import AccountingPage from '../accounting/AccountingPage';
import TerritoryRequestPage from '../territories/request/TerritoryRequestPage';
import FilesPage from '../files/FilesPage';
import CongregationStatePage from '../congregation-state/CongregationStatePage';

const Content = () => {
    const { userStore } = useStores();
    const roles = userStore.userData && userStore.userData.roles;
    const ability = buildAbilityFor(roles);
    return (
        <div className='app-content'>
            <AbilityContext.Provider value={ability}>
                <Routes>
                    <Route path='/login' element={<LoginPage />} />
                    <Route element={<ProtectedRoutes ability={ability} />} >
                        <Route path='/' element={<HomePage />} />
                        <Route path='/home' element={<HomePage />} />
                        <Route path='/publishers' element={<PublishersPage />} />
                        <Route path='/publishers/:id' element={<PublisherDetailsPage />} />
                        <Route path='/carts' element={<CartsPage />} />
                        <Route path='/carts/analysis' element={<CartsAnalysisPage />} />
                        <Route path='/events' element={<CongregationEventsPage />} />
                        <Route path='/groups' element={<GroupsPage />} />
                        <Route path='/my-reports' element={<MyReportsPage />} />
                        <Route path='/group-reports' element={<GroupReportsPage />} />
                        <Route path='/group-reports/:id' element={<GroupReportsPage />} />
                        <Route path='/overall-report' element={<OverallReportPage />} />
                        <Route path='/pivot-reports' element={<PivotReportsPage />} />
                        <Route path='/attendance' element={<AttendancePage />} />
                        <Route path='/pivot-attendance' element={<PivotAttendancePage />} />
                        <Route path='/users' element={<UsersPage />} />
                        <Route path='/literature' element={<LiteraturePage />} />
                        <Route path='/accounting/analysis' element={<AccountingAnalysisPage />} />
                        <Route path='/schedule' element={<SchedulePage />} />
                        <Route path='/schedule/analysis' element={<ScheduleAnalysisPage />} />
                        <Route path='/schedule/speech' element={<ScheduleSpeechPage />} />
                        <Route path='/alerts' element={<AlertsPage />} />
                        <Route path='/files' element={<FilesPage />} />
                        <Route path='/knowledge' element={<KnowledgePage />} />
                        <Route path='/my-territory' element={<MyTerritoriesPage />} />
                        <Route path='/territory' element={<TerritoriesPage />} />
                        <Route path='/territory-assignments' element={<TerritoriesAssignmentPage />} />
                        <Route path='/territory-requests' element={<TerritoryRequestPage />} />
                        <Route path='/activity' element={<ActivityPage />} />
                        <Route path='/settings' element={<SettingsPage />} />
                        <Route path='/accounting' element={<AccountingPage />} />
                        <Route path='/congregation-state' element={<CongregationStatePage />} />
                    </Route>
                </Routes >
            </AbilityContext.Provider>
        </div>
    );
}

export default Content;