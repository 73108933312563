import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { LiteratureRequest } from '../../../domain/LiteratureRequest';
import { LiteratureType } from '../../../domain/LiteratureType';
import { sum } from '../../../utils/dateUtils';

interface Props {
    types: LiteratureType[];
    requests: LiteratureRequest[];
}

const LiteratureSummary = (props: Props) => {
    const requestSum = props.types ? props.types.map(type => {
        return sum(props.requests.filter(req => req.literatureTypeId === type.id), "number");
    }) : [];

    return (
        <TableContainer>
            <Table size="medium">
                <TableHead>
                    <TableRow>
                        {props.types && props.types.map(type => (
                            <TableCell key={type.name} align="center"><h3>{type.name}</h3></TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        {requestSum && requestSum.map((req, i) => (
                            <TableCell key={i} align="center"><h1>{req}</h1></TableCell>
                        ))}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default LiteratureSummary;