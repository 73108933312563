import moment from 'moment';
import { useFormik } from 'formik';
import { Autocomplete, Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, TextField, Tooltip } from '@mui/material';
import { styled, lighten, darken } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Appointment, Assignment, Publisher, ServiceAssignment } from '../../domain/Publisher';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from '../props';
import { useStores } from '../../stores';
import { FieldServiceGroup } from '../../domain/FieldServiceGroup';
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { getLocale } from '../../utils/localeUtils';

interface Props {
    publisher?: Publisher
    onSubmit: (publisher: Publisher) => void
}

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});

const PublisherForm = observer((props: PropsWithChildren<Props>) => {
    const { t } = useTranslation();
    const { appointmentStore, publisherStore } = useStores();

    const schema = yup.object().shape({
        firstName: yup.string().required(t("field_is_required", { name: t("name") })),
        lastName: yup.string().required(t("field_is_required", { name: t("surname") })),
        address: yup.string().nullable(),
        phoneNumber: yup.string().nullable(),
        gender: yup.string(),
    });
    const formik = useFormik({
        validationSchema: schema,
        enableReinitialize: true,
        initialValues: props.publisher || {
            anointed: false,
            inactive: false,
            appointments: [] as Appointment[],
            assignments: [] as Assignment[],
        } as Publisher,
        onSubmit: props.onSubmit
    });

    const { errors, values } = formik;
    return (
        <form onSubmit={formik.handleSubmit}>
            <TextField
                margin="normal"
                id="firstName"
                label={t("name")}
                type="text"
                fullWidth
                variant="standard"
                error={!!errors.firstName}
                helperText={errors.firstName && errors.firstName as string}
                value={values.firstName || ''}
                onChange={formik.handleChange}
            />
            <TextField
                margin="normal"
                id="lastName"
                label={t("surname")}
                type="text"
                fullWidth
                variant="standard"
                error={!!errors.lastName}
                helperText={errors.lastName && errors.lastName as string}
                value={values.lastName || ''}
                onChange={formik.handleChange}
            />
            <TextField
                margin="normal"
                id="address"
                label={t("address")}
                type="text"
                fullWidth
                variant="standard"
                value={values.address || ''}
                onChange={formik.handleChange}
            />
            <TextField
                margin="normal"
                id="phoneNumber"
                label={t("phone_number")}
                type="text"
                fullWidth
                variant="standard"
                value={values.phoneNumber || ''}
                onChange={formik.handleChange}
            />
            <RadioGroup
                row
                id="gender"
                value={values.gender}
                onChange={(e, v) => {
                    formik.setFieldValue('gender', v);
                }}
            >
                <FormControlLabel
                    value="female"
                    control={<Radio />}
                    checked={values.gender === "female"}
                    label={t("female")} />
                <FormControlLabel
                    value="male"
                    checked={values.gender === "male"}
                    control={<Radio />}
                    label={t("male")} />
            </RadioGroup>

            <FormGroup
                onChange={formik.handleChange} >
                <FormControlLabel
                    control={<Checkbox id="anointed" checked={values.anointed} />}
                    label={t("anointed")} />
            </FormGroup>

            <FormGroup
                onChange={formik.handleChange} >
                <FormControlLabel
                    control={<Checkbox id="inactive" checked={values.inactive} />}
                    label={t("inactive")} />
            </FormGroup>

            <Autocomplete
                disablePortal
                id="group"
                options={publisherStore.groups}
                value={values.serviceGroup || []}
                getOptionLabel={(group: FieldServiceGroup) => {
                    const groupInfo = publisherStore.groups.find(gr => gr.id === group.id);
                    return groupInfo ? groupInfo.overseer.lastName : '';
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField {...params} margin="normal" label={t("service_group")} />}
                onChange={(e, serviceGroup) => {
                    formik.setFieldValue("serviceGroup", serviceGroup)
                }}
            />
            <Autocomplete
                disablePortal
                id="appointments"
                multiple
                sx={{ display: values.gender === 'female' ? 'none' : undefined }}
                options={appointmentStore.appointments}
                value={values.appointments || []}
                getOptionLabel={(appointment: Appointment) => appointment && t(appointment.name)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField {...params} margin="normal" label={t("appointments")} />}
                onChange={(e, appointment) => {
                    formik.setFieldValue("appointments", appointment)
                }}
            />
            <Autocomplete
                disablePortal
                multiple={false}
                id="serviceAssignment"
                options={Object.values(ServiceAssignment)}
                value={values.serviceAssignment || null}
                getOptionLabel={(assignment: ServiceAssignment) => assignment && t(assignment)}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => <TextField {...params} margin="normal" label={t("service_assignment")} />}
                onChange={(e, serviceAssignment) => {
                    formik.setFieldValue("serviceAssignment", serviceAssignment)
                }}
            />
            <Autocomplete
                disablePortal
                id="assignments"
                multiple
                options={appointmentStore.assignments}
                value={values.assignments || []}
                groupBy={(assignment: Assignment) => assignment.category}
                getOptionLabel={(assignment: Assignment) => assignment && t(assignment.name)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField {...params} margin="normal" label={t("assignments")} />}
                renderGroup={(params) => (
                    <li key={params.group}>
                        <GroupHeader>{t(params.group)}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                    </li>
                )}
                onChange={(e, assignment) => {
                    formik.setFieldValue("assignments", assignment)
                }}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                <DatePicker
                    label={t("birth_date")}
                    value={values.birthDate}
                    onChange={value => {
                        formik.setFieldValue('birthDate', value ? moment(value).format('YYYY-MM-DD') : value);
                    }}
                    renderInput={(params) => <TextField {...params} margin="normal" name={t("birth_date")} />}
                />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                <DatePicker
                    label={t("baptised_at")}
                    value={values.baptisedAt}
                    onChange={value => {
                        formik.setFieldValue('baptisedAt', value ? moment(value).format('YYYY-MM-DD') : value);
                    }}
                    renderInput={(params) => <TextField {...params} margin="normal" name={t("baptised_at")} />}
                />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                <Tooltip title={t("started_at_text")}>
                    <div>
                        <DatePicker
                            label={t("started_at")}
                            value={values.startedAt}
                            views={['year', 'month']}
                            onChange={value => {
                                formik.setFieldValue('startedAt', value ? moment(value).format('YYYY-MM-DD') : value);
                            }}
                            renderInput={(params) => <TextField {...params} margin="normal" name={t("started_at")} />}
                        />
                    </div>
                </Tooltip>
            </LocalizationProvider>
            <TextField
                margin="normal"
                id="notes"
                label={t("notes")}
                type="text"
                fullWidth
                variant="standard"
                value={values.notes || ''}
                onChange={formik.handleChange}
            />
            {props.children}
        </form>
    );
});

export default PublisherForm;