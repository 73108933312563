import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translations: {
                    "cancel": "Cancel",
                    "submit": "Submit",
                    "home": "Home",
                    "publishers": "Publishers",
                    "users": "Users",
                    "settings": "Settings",
                    "logout": "Logout",
                    "publisher_details": "Publisher Details",
                    "Congregation Name": "Congregation Name",
                    "Congregation Number": "Congregation Number",
                    "City": "City",
                    "Province": "Province",
                    "Secretary Name": "Secretary Name",
                    "Congregation Reg Name": "Congregation Registration Name",
                    "Congregation Reg Number": "Congregation Registration Number",
                    "Meeting Workday Day": "Meeting Workday Day",
                    "Meeting Workday Time": "Meeting Workday Time",
                    "Meeting Weekend Day": "Meeting Weekend Day",
                    "Meeting Weekend Time": "Meeting Weekend Time",
                    "Accountant Name": "Accountant Name",
                    "username": "Username",
                    "email": "Email",
                    "no_email": "No email",
                    "roles": "Roles",
                    "publisher": "Publisher",
                    "actions": "Action",
                    "password": "Password",
                    "linked_publisher": "Linked Publisher",
                    "update_user": "Update User",
                    "new_user": "New User",
                    "add_new_user": "Add new User",
                    "name": "Name",
                    "surname": "Surname",
                    "birth_date": "Birth Date",
                    "address": "Address",
                    "phone_number": "Phone Number",
                    "service_group": "Service Group",
                    "appointments": "Apointments",
                    "baptised_at": "Baptised At",
                    "submit_a_report": "Submit a Report",
                    "new_publisher": "New publisher",
                    "add_new_publisher": "Add New Publisher",
                    "date": "Date",
                    "placements": "Placements",
                    "hours": "Hours",
                    "return_visits": "Return Visits",
                    "video_showings": "Video Showings",
                    "minutes": "Minutes",
                    "bible_studies": "Bible Studies",
                    "notes": "Notes",
                    "Elder": "Elder",
                    "Ministerial Servant": "Ministerial Servant",
                    "Coordinator": "Coordinator",
                    "Secretary": "Secretary",
                    "Auxiliary Pioneer": "Auxiliary Pioneer",
                    "Regular Pioneer": "Regular Pioneer",
                    "Special Pioneer": "Special Pioneer",
                    "Missionary": "Missionary",
                    "Circuit Overseer": "Circuit Overseer",
                    "Operating Committee Member": "Operating Committee Member",
                    "Service Overseer": "Service Overseer",
                    "Public Talk Coordinator": "Public Talk Coordinator",
                    "Watchtower Study Conductor": "Watchtower Study Conductor",
                    "Meeting overseer": "Meeting overseer",
                    "Accountant": "Accountant",
                    "Counselor": "Counselor",
                    "Attendant Overseer": "Attendant Overseer",
                    "Literature Servant": "Literature Servant",
                    "Cleaning Servant": "Cleaning Servant",
                    "Stage Worker": "Stage Worker",
                    "Territory Servant": "Territory Servant",
                    "Hospitality Servant": "Hospitality Servant",
                    "Audio/Video Support": "Audio/Video Support",
                    "Recording Attendance": "Recording Attendance",
                    "field_service_report": "Field Service Report",
                    "you_are_admin": "You are an administrator",
                    "please_submit_your_report": "Please submit your report",
                    "your_report_is_submitted": "Your report is submitted. Thanks!",
                    "download_s_1": "Download S-1",
                    "download_s_21": "Download S-21",
                    "download_s_88": "Download S-88",
                    "download_s_26": "Download S-26",
                    "download_s_30": "Download S-30",
                    "download_to_62": "Download TO-62",
                    "update_user_info": "Update User Info",
                    "sign_in": "Sign in",
                    "active_publishers": "Active Publishers",
                    "average_attendance": "Average Attendance",
                    "none": "None",
                    "groups": "Groups",
                    "number": "Number",
                    "overseer": "Overseer",
                    "gender": "Gender",
                    "male": "Male",
                    "female": "Female",
                    "anointed": "Anointed",
                    "yes": "Yes",
                    "no": "No",
                    "add_attendance": "Add attendance",
                    "amount": "Amount",
                    "from": "From",
                    "to": "To",
                    "delete_publisher": "Delete Publisher",
                    "are_you_sure": "Are you sure?",
                    "this_will_permanently_delete": "This will permanently delete",
                    "import_a_report": "Import a Report",
                    "attendance": "Attendance",
                    "workdays": "Workdays",
                    "weekends": "Weekends",
                    "workday": "Workday",
                    "weekend": "Weekend",
                    "year": "Year",
                    "publisher_is_updated": "Publisher {{name}} is updated",
                    "hello": "Hello",
                    "group_reports": "Group Reports",
                    "save_all": "Save all",
                    "reports": "Reports",
                    "overall_report": "Overall Report",
                    "congregation": "Congregation",
                    "backup": "Backup",
                    "download": "Download",
                    "upload": "Upload",
                    "change_password": "Change Password",
                    "repeat_password": "Repeat Password",
                    "new_user_created": "New User {{name}} Created",
                    "new_user_creation_failed": "New User Creation Failed",
                    "update_group": "Update Group",
                    "create_new_group": "Create New Group",
                    "delete_group": "Delete Group",
                    "import_publishers": "Import Publishers",
                    "passwords_are_different": "Passwords are different!",
                    "password_changed_successfully": "Password changed successfully",
                    "failed_to_change_password": "Failed to change password",
                    "year_is_not_specified": "Year is not specified",
                    "user_is_deleted": "User {{name}} is deleted",
                    "report_is_deleted": "Report is deleted",
                    "list": "List",
                    "user_is_updated": "User {{name}} is updated",
                    "Session is over": "Session is over",
                    "age": "Age",
                    "baptised": "Baptised",
                    "hours_avg": "Hours (avg)",
                    "returns_avg": "Returns (avg)",
                    "user_logged_in": "User {{name}} is logged in",
                    "service_report_is_submitted": "Service Report is sumbitted",
                    "service_report_is_updated": "Service Report is updated",
                    "service_reports_are_updated": "Service Reports are updated",
                    "service_report_is_deleted": "Service Report is deleted",
                    "publisher_is_created": "Publisher {{name}} is created",
                    "publisher_is_deleted": "Publisher is deleted",
                    "service_group_is_created": "Service Group is created",
                    "service_group_is_updated": "Service Group is updated",
                    "service_group_is_deleted": "Service Group is deleted",
                    "publishers_are_imported": "Publishers are imported",
                    "average": "Average",
                    "overall": "Overall",
                    "active": "Active",
                    "publisher_didnt_submit_the_report": "Publisher didn't submit the report",
                    "was_preaching": "Was Preaching?",
                    "no_report": "No report",
                    "submitted_reports": "Submitted reports",
                    "submitted_reports_num": "Submitted: {{submitted}}/{{overall}}",
                    "inactives": "Inactive",
                    "inactive": "Inactive",
                    "deactivated": "Deactivated",
                    "deactivate_publisher": "Deactivate Publisher",
                    "publisher_is_deactivated": "Publisher is deactivated",
                    "restore_publisher": "Restore Publisher",
                    "publisher_is_restored": "Publisher is restored",
                    "this_will_permanently_delete_the_publisher": "This will permanently delete the publisher and all his reports. This action will affect on the whole congregation history and on generated reports for previous years",
                    "pivot_reports": "Pivot Reports",
                    "pioneers": "Pioneers",
                    "auxiliary_pioneer_short": "AP",
                    "auxiliary_pioneers": "Auxiliary Pioneers",
                    "special_pioneers": "Special Pioneers",
                    "all": "All",
                    "count": "Count",
                    "day_of_week": "Day of a week",
                    "graph": "Graph",
                    "table": "Table",
                    "accounting": "Accounting",
                    "document": "Document",
                    "counterparty": "Counterparty",
                    "description": "Description",
                    "code": "Code",
                    "cash": "Cash",
                    "bank": "Bank",
                    "total": "Total",
                    "import_xml": "Import XML",
                    "side": "Side",
                    "submit_and_open_again": "Submit and open again",
                    "income": "Income",
                    "expenses": "Expenses",
                    "in.": "in.",
                    "exp.": "exp.",
                    "add_entry": "Add Entry",
                    "save": "Save",
                    "other": "Other",
                    "accounting_entry": "Accounting Entry",
                    "add_starting_point": "Add Starting Point",
                    "type": "Type",
                    "accounting_entries_are_updated": "Accounting Entries are updated",
                    "transfer": "Transfer",
                    "transfer_direction": "Transfer Direction",
                    "selected_date_and_xml_date_are_not_same": "Selected date and XML date are not same",
                    "add_entry_from_contribution_box": "Add Entry from Contribution Box",
                    "worldwide": "Worldwide",
                    "congregation_expenses": "Congregation Expenses",
                    "contribution_boxes": "Contribution Boxes",
                    "carts": "Carts",
                    "add_new_cart_witnessing_place": "Add New Cart Witnessing Place",
                    "update_place": "Update Place",
                    "delete_place": "Delete Place",
                    "cart_witnessing_place": "Cart Witnessing Place",
                    "cart_witnessing": "Cart Witnessing",
                    "delete": "Delete",
                    "today": "Today",
                    "time": "Time",
                    "event": "Events",
                    "all_day": "All day",
                    "week": "Week",
                    "work_week": "Work week",
                    "day": "Day",
                    "month": "Month",
                    "previous": "Previous",
                    "next": "Next",
                    "yesterday": "Yesterday",
                    "tomorrow": "Tomorrow",
                    "agenda": "Agenda",
                    "no_events_in_range": "There are no events in this range",
                    "started_at": "Started at",
                    "started_at_text": "In this field you need to indicate in what year and month this publisher transferred to your congregation (this is important only if he transferred recently). This is taken into account for the formation of tables with reports",
                    "field_is_required": "Field {{name}} is required",
                    "password_is_too_short": "Password is too short - should be {{number}} chars minimum",
                    "add_at_least_one_role": "Add at least one role",
                    "profile": "Profile",
                    "schedule": "Schedule",
                    "schedule_short": "Schedule",
                    "import_epub": "Import EPub",
                    "intro": "Intro",
                    "treasures": "Treasures",
                    "apply-yourself": "Apply Yourself",
                    "living-as-christians": "Living as Christians",
                    "speaker": "Speaker",
                    "companion": "Companion",
                    "number_hall": "{{number}} hall",
                    "participants": "Participants",
                    "schedule_entry": "Schedule Entry",
                    "position": "Position",
                    "room": "Room",
                    "empty": "Empty",
                    "convention": "Convention",
                    "update_week_schedule": "Update Week Schedule",
                    "skip": "Skip",
                    "field_service_meetings": "Field Service Meetings",
                    "cleaning": "Cleaning",
                    "epub_is_uploaded": "Epub file is uploaded",
                    "schedule_entry_is_updated": "Schedule Entry is updated",
                    "schedule_week_is_updated": "Schedule Week is updated",
                    "congregation_class_count": "Congegation Class count",
                    "download_excel": "Download Excel",
                    "download_assignment_excel": "Download Excel (assignment)",
                    "download_range_excel": "Download Excel (time range)",
                    "plac.": "Plac",
                    "video": "Video",
                    "elders": "Elders",
                    "ministerial_servants": "Ministerial Servants",
                    "appointed": "Appointed",
                    "brothers": "Brothers",
                    "sisters": "Sisters",
                    "field-service": "Field Service",
                    "field_service": "Field Service",
                    "my_reports": "My reports",
                    "publisher_is_not_linked_to_this_user": "Publisher is not linked to this user",
                    "activity": "Activity",
                    "records": "Records",
                    "user": "User",
                    "group": "Group",
                    "assignments": "Assignments",
                    "assignment": "Assignment",
                    "service_assignment": "Service Assignment",
                    "Attendant": "Attendant",
                    "Microphones": "Microphones",
                    "Media": "Media",
                    "Prayer": "Prayer",
                    "Reading": "Reading",
                    "speach": "Speach",
                    "watchtower": "Watchtower",
                    "sub-type": "Sub-Type",
                    "create_entry": "Create Entry",
                    "schedule_entry_is_created": "Schedule Entry is created",
                    "schedule_entry_is_deleted": "Schedule Entry is deleted",
                    "Carts": "Carts",
                    "add_companion": "Add companion",
                    "prev.": "Prev.",
                    "next.": "Next",
                    "literature": "Literature",
                    "edit_literature": "Edit Literature",
                    "edit_literature_requests": "Edit Literature Requests",
                    "manage_literature": "Manage Literature",
                    "new_literature_type_is_saved": "Literature is saved",
                    "literature_type_is_updated": "Literature is updated",
                    "literature_type_is_deleted": "Literature is deleted",
                    "literature_request_is_saved": "Literature Request is saved",
                    "literature_request_is_updated": "Literature Request is updated",
                    "literature_requests_are_saved": "Literature Requests are saved",
                    "notifications": "Notifications",
                    "notification": "Notification",
                    "all_pioneers": "All Pioneers",
                    "service": "Service",
                    "Chairman": "Chairman",
                    "Student": "Student",
                    "Assistant": "Assistant",
                    "Conversation": "Conversation",
                    "BibleStudy": "Bible Study",
                    "Talk": "Talk",
                    "Watchtower": "Watchtower",
                    "BibleReading": "Bible Reading",
                    "BibleStudyCongregation": "Bible Study in Congregation",
                    "Treasures": "Treasures",
                    "Gems": "Gems",
                    "school": "School",
                    "autoarrangement": "Autoarrangement",
                    "this_will_rewrite_selected_schedule_entries": "This will rewrite selected schedule entries",
                    "schedule_entries_were_generated_successfully": "Schedule entries were generated successfully",
                    "month_start": "Month Start",
                    "month_end": "Month End",
                    "summary": "Summary",
                    "difference": "Difference",
                    "sent_for_worldwide_work": "Sent for worldwide work",
                    "history": "History",
                    "its_field_service_meeting_time": "It's field service meeting time!",
                    "speaker_from_a_congregation": "Speaker from a congregation",
                    "schedule_analysis": "Schedule Analysis",
                    "knowledge": "Knowledge",
                    "manage_note": "Manage Note",
                    "manage_topic": "Manage Topic",
                    "create_knowledge_topic": "Create Knowledge Topic",
                    "new_knowledge_topic_is_saved": "New knowledge topic is saved",
                    "knowledge_topic_is_updated": "Knowledge Topic is updated",
                    "knowledge_topic_is_deleted": "Knowledge Topic is deleted",
                    "knowledge_note_is_saved": "Knowledge Note is saved",
                    "knowledge_note_is_updated": "Knowledge Note is updated",
                    "knowledge_note_is_deleted": "Knowledge Note is deleted",
                    "impromptu": "Impromptu",
                    "sorting": "Sorting",
                    "default": "Default",
                    "close": "Close",
                    "role": "Role",
                    "events": "Events",
                    "congregation_event": "Congregation Event",
                    "title": "Title",
                    "owner": "Owner",
                    "who_has_access": "Who has access",
                    "schedule_public_speeches": "Schedule of public speeches",
                    "transfer_to_branch_date": "Transfer to the branch date",
                    "from_box": "From Box",
                    "monthly_resolution": "Monthly Resolution",
                    "exceed_resolution": "Exceed Resolution",
                    "extra_resolution": "Extra Resolution",
                    "enter_confirmation_to_branch_accountant": "Confirmation Number",
                    "resolution": "Resolution",
                    "kingdom_hall": "Kingdom Hall",
                    "download_reports_for_circuit_overseer": "Download reports for circuit overseer",
                    "backup_short_description": "Database backup",
                    "backup_long_description": "",
                    "export_publishers_short_description": "Publishers info export",
                    "export_publishers_long_description": "",
                    "export_all_reports_short_description": "All reports",
                    "export_all_reports_long_description": "",
                    "circuit_overseer_reports_short_description": "Documents for circuit overseer",
                    "circuit_overseer_reports_long_description": "",
                    "latvian": "Latvian",
                    "russian": "Russian",
                    "english": "English",
                    "should_be_flatten": "Should be flatten?",
                    "personal_data": "Personal Data",
                    "privacy_policy": "Privacy Policy",
                    "personal_data_long_text_1": "Every preacher in the congregation signed the document S-290, which gives consent to the fact that the organization of Jehovah's Witnesses, including the congregation to which the preacher belongs, could use personal data to carry out religious activities. If you have any questions about what exactly data is used and how, you can contact the secretary of the meeting. It is also possible to request deletion or limit the processing of information.",
                    "personal_data_long_text_2": "To have a complete idea of how the Jehovah's Witnesses' privacy policy works, read the relevant materials on jw.org, the link to which can be found at the very bottom of the jw.org main page.",
                    "cart_witnessing_confirmation_long_text": "Two publishers have already been recorded for the selected time. Before recording, make sure (please call) that they are not against. If you have already called, feel free to press the \"Already called\" button",
                    "already_called": "Already called",
                    "havent_called_yet": "Haven't called yet",
                    "analysis": "Analysis",
                    "territory": "Territory",
                    "territories": "Territories",
                    "you_arent_allowed_to_put_your_record_here": "You aren't allowed to put your record here",
                    "create_territory": "Create Territory",
                    "territory_group": "Territory Group",
                    "territory_groups": "Territory Groups",
                    "create_territory_group": "Create Territory Group",
                    "color": "Color",
                    "manage_territory_group": "Manage Territory Group",
                    "manage_territory": "Manage Territory",
                    "assign_territory": "Assign Territory",
                    "this_territory_assignment_history": "This territory assignment history",
                    "taken_at": "Taken at",
                    "returned_at": "Returned at",
                    "assign": "Assign",
                    "return": "Return",
                    "do_you_want_to_return_the_territory": "Do you want to return the territory?",
                    "do_you_want_to_assign_the_territory": "Do you want to assign the territory?",
                    "upload_json": "Upload JSON",
                    "my_territories": "My territories",
                    "the_territory_is_assigned_to_someone": "The territory is assigned to someone",
                    "the_territory_was_created_successfully": "The territory was created successfully",
                    "the_territory_was_updated_successfully": "The territory was updated successfully",
                    "the_territory_was_deleted_successfully": "The territory was deleted successfully",
                    "the_territory_group_was_created_successfully": "The territory group was created successfully",
                    "the_territory_group_was_updated_successfully": "The territory group was updated successfully",
                    "the_territory_group_was_deleted_successfully": "The territory group was deketed successfully",
                    "the_territory_was_assigned_successfully": "The territory was created successfully",
                    "the_territory_assignment_was_updated_successfully": "The territory assignment was updated successfully",
                    "the_territory_assignment_was_deleted_successfully": "The territory assignment was deleted succesfully",
                    "failed_to_assign_the_territory": "Failed to assign the territory",
                    "the_territory_was_returned_successfully": "The territory was created successfully",
                    "failed_to_return_the_territory": "Failed to return the territory",
                    "the_territory_was_requested_successfully": "The territory was requested successfully",
                    "failed_to_request_the_territory": "Failed to request the territory",
                    "the_territory_request_was_deleted_successfully": "The territory request was deleted sucessfully",
                    "show_route": "Show Route",
                    "do_you_have_google_maps_app": "Do you have a Google Maps app installed?",
                    "json_is_uploaded": "JSON is uploaded",
                    "return_unworked": "Return unworked",
                    "you_dont_have_assigned_territories": "You don't have assigned territories",
                    "publisher_without_service_assignment_should_not_have_hours_in_report": "Publisher without a service assignment shouldn't have hours in a report",
                    "upload_to_territory_helper": "Upload to Territory Heleper",
                    "upload_to_territory_helper_long_text": "If you check this box, the changes you made will be sent to a common map for all meetings. Make sure it's really needed",
                    "days_gone": "Days gone",
                    "number_of_territories": "{{number}} of territories",
                    "filter": "Filter",
                    "assigned_only": "Assigned only",
                    "unassigned_only": "Unassigned only",
                    "never_worked": "Never worked",
                    "rarely_worked": "Rarely worked",
                    "recently_worked": "Recently worked",
                    "extra": "Extra",
                    "speeches": "Speeches",
                    "students": "Students",
                    "request": "Request",
                    "requests": "Requests",
                    "my_requests": "My Requests",
                    "request_territory": "Request Territory",
                    "request_territory_text": "I want to request this territory: {{title}}",
                    "assigned_already": "Assigned Already",
                    "you_are_not_able_to_request_territories": "You are not able to request territories",
                    "no_territory_requests_yet": "No territory requests yet",
                    "preferences": "Preferences",
                    "new_schedule_view": "New Schedule View",
                    "change": "Change",
                    "only_for_mobile": "Only for mobile phones",
                    "old": "Old",
                    "new": "New",
                    "need_to_upload_meeting_workbook": "Need to upload a meeting workbook",
                    "someone": "Someone",
                    "statistics": "Statistics",
                    "total_territories": "Total territories",
                    "do_you_want_to_fetch_assignment_data_from_th": "Do you want to fetch assignment data from TH",
                    "assigned_territories_at_range": "Assigned territories at range ({{type}})",
                    "worked_territories_at_range": "Worked territories at range ({{type}})",
                    "assigned_and_worked_territories_at_range": "Assigned and worked territories at range ({{type}})",
                    "currently_assigned_territories": "Currently assigned territories",
                    "history_of_assignments": "History of assignments",
                    "restore": "Restore",
                    "file": "File",
                    "files": "Files",
                    "upload_file": "Upload file",
                    "file_is_uploaded": "File is uploaded",
                    "file_is_deleted": "File is deleted",
                    "rename_file": "Rename file",
                    "new_file_name": "New file name",
                    "wrong_file_type": "Wrong file type",
                    "info": "Info",
                    "errors": "Errors",
                    "search": "Search",
                    "subscribe": "Subscribe",
                    "unsubscribe": "Unsubscribe",
                    "enable": "Enable",
                    "disable": "Disable",
                    "push_notifications": "Push Notifications",
                    "push_notifications_enabled": "Push notifications enabled",
                    "push_notifications_already_enabled": "Push notifications already enabled",
                    "push_notifications_disabled": "Push notifications disabled",
                    "not_able_to_set_up_push_notifications": "Not able to set up Push notifications",
                    "permission_is_not_granted_for_push_notifications": "Permission is not granted for push notifications",
                    "configuration_for_push_notifications_is_not_provided": "Configuration for push notifications is not provided",
                    "default_tab_in_home_page": "Default tab in Home Page",
                    "message": "Message",
                    "go_to_settings_to_change": "Go to device settings to change this option",
                    "send_notification": "Send notification",
                    "message_is_sent": "Сообщение отправлено",
                    "loading": "Loading",
                    "basic_schedule_entry": "Basic Entry",
                    "this_feature_is_disabled": "This feature is disabled",
                    "congregation_state": "Congregation State",
                    "congregation_state_text": "Congregation state taken from previous month. Field Service data taken from pre-previous month",
                    "monday": "Monday",
                    "tuesday": "Tuesday",
                    "wednesday": "Wednesday",
                    "thursday": "Thursday",
                    "friday": "Friday",
                    "saturday": "Saturday",
                    "sunday": "Sunday",
                    "meeting_time": "Meeting Time",
                    "severity": "Severity",
                    "low": "Low",
                    "medium": "Medium",
                    "high": "High",
                    "more": "more",
                    "receipt": "Receipt",
                    "receipt_number": "Receipt №",
                    "no_enough_receipt_data": "Not enough data for a receipt",
                }
            },
            ru: {
                translations: {
                    "cancel": "Отменить",
                    "submit": "Подтвердить",
                    "home": "Главная",
                    "publishers": "Возвещатели",
                    "users": "Пользователи",
                    "settings": "Настройки",
                    "logout": "Выход",
                    "publisher_details": "Возвещатель",
                    "Congregation Name": "Название собрания",
                    "Congregation Number": "Номер собрания",
                    "City": "Населённый пункт",
                    "Province": "Регион",
                    "Secretary Name": "ФИО секретаря",
                    "Congregation Reg Name": "Регистрационное название собрания",
                    "Congregation Reg Number": "Регистрационный номер собрания",
                    "Meeting Workday Day": "День недели собрания в будни",
                    "Meeting Workday Time": "Время собрания в будний день",
                    "Meeting Weekend Day": "День недели собрания в выходной",
                    "Meeting Weekend Time": "Время собрания в выходной",
                    "Accountant Name": "ФИО Бухгалтера",
                    "username": "Имя пользователя",
                    "email": "Э-почта",
                    "no_email": "Нет э-почты",
                    "roles": "Роли",
                    "publisher": "Возвещатель",
                    "actions": "Действие",
                    "password": "Пароль",
                    "linked_publisher": "Возвещатель",
                    "update_user": "Обновить пользователя",
                    "new_user": "Новый пользователь",
                    "add_new_user": "Добавить нового пользователя",
                    "name": "Имя",
                    "surname": "Фамилия",
                    "birth_date": "Дата рождения",
                    "address": "Адрес",
                    "phone_number": "Номер телефона",
                    "service_group": "Группа",
                    "appointments": "Назначения",
                    "baptised_at": "Дата крещения",
                    "submit_a_report": "Сдать отчёт",
                    "new_publisher": "Новый возвещатель",
                    "add_new_publisher": "Добавить нового возвещателя",
                    "date": "Дата",
                    "placements": "Публикации",
                    "hours": "Часы",
                    "return_visits": "Повторы",
                    "video_showings": "Показы видео",
                    "minutes": "Минуты",
                    "bible_studies": "Изучения",
                    "notes": "Заметки",
                    "Elder": "Старейшина",
                    "Ministerial Servant": "Помощник собрания",
                    "Coordinator": "Координатор",
                    "Secretary": "Секретарь",
                    "Auxiliary Pioneer": "Подсобный пионер",
                    "Regular Pioneer": "Пионер",
                    "Special Pioneer": "Специальный пионер",
                    "Missionary": "Миссионер",
                    "Circuit Overseer": "Районный",
                    "Operating Committee Member": "Член ЭК",
                    "Service Overseer": "Отв. за служение",
                    "Public Talk Coordinator": "Отв. за речи",
                    "Watchtower Study Conductor": "Рук. Сторожевой Башни",
                    "Meeting overseer": "Отв. за график встреч",
                    "Accountant": "Отв. за счета",
                    "Counselor": "Дающий совет",
                    "Attendant Overseer": "Отв. за распорядителей",
                    "Literature Servant": "Отв. за литературу",
                    "Cleaning Servant": "Отв. за уборку",
                    "Stage Worker": "Отв. за сцену",
                    "Territory Servant": "Отв. за участки",
                    "Hospitality Servant": "Отв. за гостеприимство",
                    "Audio/Video Support": "Отв. за аппаратуру",
                    "Recording Attendance": "Отв. за подсчёт",
                    "field_service_report": "Отчёт проп. служения",
                    "you_are_admin": "Вы администратор",
                    "please_submit_your_report": "Пожалуйста сдайте отчёт",
                    "your_report_is_submitted": "Ваш отчёт сдан. Спасибо!",
                    "download_s_1": "Скачать S-1",
                    "download_s_21": "Скачать S-21",
                    "download_s_88": "Скачать S-88",
                    "download_s_26": "Скачать S-26",
                    "download_s_30": "Скачать S-30",
                    "download_to_62": "Скачать TO-62",
                    "update_user_info": "Обновить данные пользователя",
                    "sign_in": "Вход",
                    "active_publishers": "Активные возвещатели",
                    "average_attendance": "Среднее число присутствующих",
                    "none": "Нет",
                    "groups": "Группы",
                    "number": "Номер",
                    "overseer": "Ответственный",
                    "gender": "Пол",
                    "male": "Мужчина",
                    "female": "Женщина",
                    "anointed": "Помазанник",
                    "yes": "Да",
                    "no": "Нет",
                    "add_attendance": "Добавить кол-во",
                    "amount": "Кол-во",
                    "from": "С",
                    "to": "По",
                    "delete_publisher": "Удалить возвещателя",
                    "are_you_sure": "Вы уверены?",
                    "this_will_permanently_delete": "Это навсегда удалит",
                    "import_a_report": "Импортировать отчёт",
                    "attendance": "Посещение",
                    "workdays": "Рабочие дни",
                    "weekends": "Выходные",
                    "workday": "Рабочий день",
                    "weekend": "Выходной",
                    "year": "Год",
                    "publisher_is_updated": "Возвещатель {{name}} обновлён",
                    "hello": "Добрый день",
                    "group_reports": "Отчёты группы",
                    "save_all": "Сохранить всё",
                    "reports": "Отчёты",
                    "overall_report": "Общий отчёт",
                    "congregation": "Собрание",
                    "backup": "Бэкап",
                    "download": "Скачать",
                    "upload": "Загрузить",
                    "change_password": "Поменять пароль",
                    "repeat_password": "Повторите пароль",
                    "new_user_created": "Новый пользователь {{name}} создан",
                    "new_user_creation_failed": "Не получилось создать нового пользователя ",
                    "update_group": "Изменить группу",
                    "create_new_group": "Добавить новую группу",
                    "delete_group": "Удалить группу",
                    "import_publishers": "Импортировать возвещателей",
                    "passwords_are_different": "Пароли разные!",
                    "password_changed_successfully": "Пароль поменян успешно",
                    "failed_to_change_password": "Не получилось поменять пароль",
                    "year_is_not_specified": "Год не указан",
                    "user_is_deleted": "Пользователь {{name}} удалён",
                    "report_is_deleted": "Отчёт удалён",
                    "list": "Список",
                    "user_is_updated": "Пользователь {{name}} обновлён",
                    "Session is over": "Сессия завершилась",
                    "age": "Возраст",
                    "baptised": "Крещён",
                    "hours_avg": "Часы (ср.)",
                    "returns_avg": "Повторы (ср.)",
                    "user_logged_in": "Пользователь {{name}} залогинился",
                    "service_report_is_submitted": "Отчёт о служении сдан",
                    "service_report_is_updated": "Отчёт о служении обновлён",
                    "service_reports_are_updated": "Отчёты о служении обновлены",
                    "service_report_is_deleted": "Отчёт о служении удалён",
                    "publisher_is_created": "Возвещатель {{name}} создан",
                    "publisher_is_deleted": "Возвещатель удалён",
                    "service_group_is_created": "Группа для служения создана",
                    "service_group_is_updated": "Группа для служения обновлена",
                    "service_group_is_deleted": "Группа для служения удалена",
                    "publishers_are_imported": "Возвещатели импортированы",
                    "average": "Среднее",
                    "overall": "Всего",
                    "active": "Активный",
                    "publisher_didnt_submit_the_report": "Возвещатель не сдал отчёт",
                    "was_preaching": "Проповедовал?",
                    "no_report": "Нет отчёта",
                    "submitted_reports": "Сдано отчётов",
                    "submitted_reports_num": "Сдано: {{submitted}}/{{overall}}",
                    "inactives": "Неактивные",
                    "inactive": "Неактивный",
                    "deactivated": "Деактивированные",
                    "deactivate_publisher": "Деактивировать возвещателя",
                    "publisher_is_deactivated": "Возвещатель деактивирован",
                    "restore_publisher": "Восстановить возвещателя",
                    "publisher_is_restored": "Возвещатель восстановлен",
                    "this_will_permanently_delete_the_publisher": "Это навсегда удалит возвещателя и все его отчёты. Это действие повлияет на всю историю собрания и сгенерированные отчёты за предыдущие годы",
                    "pivot_reports": "Сводные отчёты",
                    "pioneers": "Пионеры",
                    "auxiliary_pioneer_short": "ПП",
                    "auxiliary_pioneers": "Подсобные",
                    "special_pioneers": "Специальные",
                    "all": "Все",
                    "count": "Кол-во",
                    "day_of_week": "День недели",
                    "graph": "График",
                    "table": "Таблица",
                    "accounting": "Бухгалтерия",
                    "document": "Документ",
                    "counterparty": "Контрагент",
                    "description": "Описание",
                    "code": "Код",
                    "cash": "Касса",
                    "bank": "Счёт",
                    "total": "Всего",
                    "import_xml": "Импортировать XML",
                    "side": "Сторона",
                    "submit_and_open_again": "Подвтердить и открыть ещё раз",
                    "income": "Доход",
                    "expenses": "Расход",
                    "in.": "д.",
                    "exp.": "тр.",
                    "add_entry": "Добавить запись",
                    "save": "Сохранить",
                    "other": "Другое",
                    "accounting_entry": "Бухгалтерская запись",
                    "add_starting_point": "Добавить начальные значения",
                    "type": "Тип",
                    "accounting_entries_are_updated": "Бухгалтерские записи сохранены",
                    "transfer": "Перевод",
                    "transfer_direction": "Перевод куда",
                    "selected_date_and_xml_date_are_not_same": "Выбранная дата и дата из файла не совпадают",
                    "add_entry_from_contribution_box": "Добавить из ящика для пожертвований",
                    "worldwide": "Всемирное",
                    "congregation_expenses": "Нужды собрания",
                    "contribution_boxes": "Ящики для пожертвований",
                    "carts": "Стенды",
                    "add_new_cart_witnessing_place": "Добавить новое место для стендов",
                    "update_place": "Обновить место",
                    "delete_place": "Удалить место",
                    "cart_witnessing_place": "Место служения со стендом",
                    "cart_witnessing": "Служение со стендом",
                    "delete": "Удалить",
                    "today": "Сегодня",
                    "time": "Время",
                    "event": "События",
                    "all_day": "Весь день",
                    "week": "Неделя",
                    "work_week": "Рабочая неделя",
                    "day": "День",
                    "month": "Месяц",
                    "previous": "Предыдущий",
                    "next": "Следующий",
                    "yesterday": "Вчера",
                    "tomorrow": "Завтра",
                    "agenda": "Повестка",
                    "no_events_in_range": "Нет событий в этом промежутке",
                    "started_at": "Начал в",
                    "started_at_text": "В этом поле надо указать в каком году и месяце этот возвещатель перешел в ваше собрание (это важно только в том случае если он перешел недавно). Это учитывается для формирования таблиц с отчётами",
                    "field_is_required": "Поле '{{name}}' обязательно",
                    "password_is_too_short": "Пароль слишком короткий, должно быть как минимум {{number}} симвлов",
                    "add_at_least_one_role": "Нужна хотя бы одна роль",
                    "profile": "Профиль",
                    "schedule": "График встреч",
                    "schedule_short": "График",
                    "import_epub": "Импортировать EPub",
                    "intro": "Вступление",
                    "treasures": "Сокровища",
                    "apply-yourself": "Оттачиваем навыки служения",
                    "living-as-christians": "Христианская жизнь",
                    "speaker": "Выступающий",
                    "companion": "Напарник",
                    "number_hall": "{{number}} зал",
                    "participants": "Участники",
                    "schedule_entry": "Запись в графике",
                    "position": "Позиция",
                    "room": "Зал",
                    "empty": "Пусто",
                    "convention": "Конгресс",
                    "update_week_schedule": "Обновить недельный график",
                    "skip": "Пропуск",
                    "field_service_meetings": "Встречи для проповеди",
                    "cleaning": "Уборка",
                    "epub_is_uploaded": "Epub файл загружен успешно",
                    "schedule_entry_is_updated": "Запись в графике обновлена",
                    "schedule_week_is_updated": "Недельный график обновлён",
                    "congregation_class_count": "Количество учебных классов",
                    "download_excel": "Скачать Excel",
                    "download_assignment_excel": "Скачать Excel (по назначениям)",
                    "download_range_excel": "Скачать Excel (за период времени)",
                    "plac.": "Публ",
                    "video": "Видео",
                    "elders": "Старейшины",
                    "ministerial_servants": "Помощники собрания",
                    "appointed": "Назначенные",
                    "brothers": "Братья",
                    "sisters": "Сёстры",
                    "field-service": "Проповедническая",
                    "field_service": "Проповедническая",
                    "my_reports": "Мои отчёты",
                    "publisher_is_not_linked_to_this_user": "Возвещатель не привязан к этому пользователю",
                    "activity": "Активность",
                    "records": "Записи",
                    "user": "Пользователь",
                    "group": "Группа",
                    "assignments": "Поручения",
                    "assignment": "Назначение",
                    "service_assignment": "Служебное назначение",
                    "Attendant": "Распорядитель",
                    "Microphones": "Микрофоны",
                    "Media": "Медиа",
                    "Prayer": "Молитва",
                    "Reading": "Чтение",
                    "speech": "Речь",
                    "watchtower": "Сторожевая Башня",
                    "sub-type": "Подтип",
                    "create_entry": "Создать запись",
                    "schedule_entry_is_created": "Запись для графика создана",
                    "schedule_entry_is_deleted": "Запись для графика удалена",
                    "Carts": "Стенды",
                    "add_companion": "Добавить напарника",
                    "prev.": "Пред.",
                    "next.": "След.",
                    "literature": "Литература",
                    "edit_literature": "Редактировать литературу",
                    "edit_literature_requests": "Редактировать заказы литературы",
                    "manage_literature": "Управление литературой",
                    "new_literature_type_is_saved": "Литература сохранена",
                    "literature_type_is_updated": "Литература обновлена",
                    "literature_type_is_deleted": "Литература удалена",
                    "literature_request_is_saved": "Заказ литературы сохранён",
                    "literature_request_is_updated": "Заказ литературы обновлён",
                    "literature_requests_are_saved": "Заказ литературы сохранён",
                    "notifications": "Уведомления",
                    "notification": "Уведомление",
                    "all_pioneers": "Все пионеры",
                    "service": "Служение",
                    "Chairman": "Председатель",
                    "Student": "Ученик",
                    "Assistant": "Помощник",
                    "Conversation": "Разговор",
                    "BibleStudy": "Изучение Библии",
                    "Talk": "Речь",
                    "Watchtower": "Сторожевая Башня",
                    "BibleReading": "Чтение Библии",
                    "BibleStudyCongregation": "Изучение Библии в собрании",
                    "Treasures": "Сокровища",
                    "Gems": "Жемчужины",
                    "school": "Школа",
                    "autoarrangement": "Авторасстановка",
                    "this_will_rewrite_selected_schedule_entries": "Это перепишет существующие записи графика (если они есть)",
                    "schedule_entries_were_generated_successfully": "Записи графика встреч успешно сгенерированы",
                    "month_start": "Начало месяца",
                    "month_end": "Конец месяца",
                    "summary": "Сводка",
                    "difference": "Разница",
                    "sent_for_worldwide_work": "Отправлено на всемирное дело",
                    "history": "История",
                    "its_field_service_meeting_time": "В это время идёт встреча для проповеди!",
                    "speaker_from_a_congregation": "Выступающий из нашего собрания",
                    "schedule_analysis": "Анализ графика",
                    "knowledge": "Знания",
                    "manage_note": "Управлять записью",
                    "manage_topic": "Управлять темой",
                    "create_knowledge_topic": "Создать новую тему",
                    "new_knowledge_topic_is_saved": "Новая тема создана",
                    "knowledge_topic_is_updated": "Тема обновлена",
                    "knowledge_topic_is_deleted": "Тема удалена",
                    "knowledge_note_is_saved": "Запись сохранена",
                    "knowledge_note_is_updated": "Запись обновлена",
                    "knowledge_note_is_deleted": "Запись удалена",
                    "impromptu": "Экспромт",
                    "sorting": "Сортировка",
                    "default": "По умолчанию",
                    "close": "Закрыть",
                    "role": "Роль",
                    "events": "События",
                    "congregation_event": "Событие собрания",
                    "title": "Название",
                    "owner": "Пользователь",
                    "who_has_access": "Для кого доступ",
                    "schedule_public_speeches": "График публичных речей",
                    "transfer_to_branch_date": "Дата перевода в филиал",
                    "from_box": "Из ящиков",
                    "monthly_resolution": "Ежемесячное пож. по резолюции",
                    "exceed_resolution": "Пож. по рез. сверх остатка ",
                    "extra_resolution": "Доп. резолюция",
                    "enter_confirmation_to_branch_accountant": "Номер подтверждения",
                    "resolution": "Резолюция",
                    "kingdom_hall": "Зал Царства",
                    "download_reports_for_circuit_overseer": "Скачать отчёты для районного",
                    "backup_short_description": "Бэкап базы данных",
                    "backup_long_description": "Бэкап всей базы данных нужен для того чтобы можно было восстановить все данные программы в случае критической ошибки программы или компьютера. Желательно делать его время от времени",
                    "export_publishers_short_description": "Экспорт данных возвещателей",
                    "export_publishers_long_description": "ФИО, адрес, телефон, дата рождения и крещения всех возвещателей в формате Excel",
                    "export_all_reports_short_description": "Все отчёты",
                    "export_all_reports_long_description": "Архив всех отчётов всех возвещателей в формате PDF на случай отказа от программы и избежания потери данных",
                    "circuit_overseer_reports_short_description": "Документы для районного",
                    "circuit_overseer_reports_long_description": "S-21 для всех возвещателей, S-88 (отчёт о посещаемости) и данные возвещателей. Документы которые нужно подготовить перед приездом районного",
                    "latvian": "Латышский",
                    "russian": "Русский",
                    "english": "Английский",
                    "should_be_flatten": "Нужно закрепить?",
                    "personal_data": "Личные данные",
                    "privacy_policy": "Политика конфиденциальности",
                    "personal_data_long_text_1": "Каждый возвещатель в собрании подписывал документ S-290, который дает согласие на то чтобы организация Свидетелей Иеговы, в том числе и собрание к которому возвещатель относится, могла использовать персональные данные для осуществления религиозной деятельности. Если есть какие-то вопросы о том какие именно данные используются и каким образом, то можно обратиться к секретарю собрания. Также можно запросить удаление или наложить ограничение на обработку информации.",
                    "personal_data_long_text_2": "Чтобы иметь полное представление как работает политика конфиденциальности организации Свидетелей Иеговы, изучите соответствующие материалы на сайте jw.org, ссылку на которую можно найти в самом низу главной страницы jw.org",
                    "cart_witnessing_confirmation_long_text": "На выбранное время уже записано два возвещателя. Перед тем как записаться, убедись (желательно позвонить), что они не против. Если ты уже позвонил и всё выяснил, то смело нажимай кнопку \"Уже позвонил\"",
                    "already_called": "Уже позвонил",
                    "havent_called_yet": "Еще не позвонил",
                    "analysis": "Анализ",
                    "territory": "Участок",
                    "territories": "Участки",
                    "you_arent_allowed_to_put_your_record_here": "Вы не можете сделать свою запись здесь",
                    "create_territory": "Создать участок",
                    "territory_group": "Группа участков",
                    "territory_groups": "Группы участков",
                    "create_territory_group": "Создать группу участков",
                    "color": "Цвет",
                    "manage_territory_group": "Управлять группой участков",
                    "manage_territory": "Управлять участком",
                    "assign_territory": "Назначить участок",
                    "this_territory_assignment_history": "История назначений этого участка",
                    "taken_at": "Взят",
                    "returned_at": "Возвращен",
                    "assign": "Назначить",
                    "return": "Вернуть",
                    "return_back": "Сдать",
                    "do_you_want_to_return_the_territory": "Вы хотите сдать этот участок?",
                    "do_you_want_to_assign_the_territory": "Вы хотите назначить этот участок?",
                    "upload_json": "Загрузить JSON",
                    "my_territories": "Мои участки",
                    "the_territory_is_assigned_to_someone": "Этот участок уже на кого-то назначен",
                    "the_territory_was_created_successfully": "Новый участок создан успешно",
                    "the_territory_was_updated_successfully": "Участок обновлен",
                    "the_territory_was_deleted_successfully": "Участок удален",
                    "the_territory_group_was_created_successfully": "Новая группа для участков создана",
                    "the_territory_group_was_updated_successfully": "Группа для участков обновлена",
                    "the_territory_group_was_deleted_successfully": "Группа для участков удалена",
                    "the_territory_was_assigned_successfully": "Участок назначен",
                    "the_territory_assignment_was_updated_successfully": "Назначение участка изменено",
                    "the_territory_assignment_was_deleted_successfully": "Назначение участка удалено",
                    "failed_to_assign_the_territory": "Не получилось назначить участок",
                    "the_territory_was_returned_successfully": "Участок возвращен",
                    "failed_to_return_the_territory": "Не получилось сдать участок",
                    "the_territory_was_requested_successfully": "Участок запрошен",
                    "failed_to_request_the_territory": "Не получилось запросить участок",
                    "the_territory_request_was_deleted_successfully": "Запрос на участок удалён",
                    "show_route": "Показать маршрут",
                    "do_you_have_google_maps_app": "Установлено ли приложение Google Maps?",
                    "json_is_uploaded": "JSON загружен",
                    "return_unworked": "Сдать необработав",
                    "you_dont_have_assigned_territories": "У вас нет ни одного участка",
                    "publisher_without_service_assignment_should_not_have_hours_in_report": "Возвещатель без служебного назначения не должен содержать часы в отчёте",
                    "upload_to_territory_helper": "Загрузить в Territory Helper",
                    "upload_to_territory_helper_long_text": "Если вы отметите эту галочку, то изменения, которые вы сделали отправятся на общую карту для всех собраний. Удостоверьтесь, что это действительно нужно",
                    "days_gone": "Прошло дней",
                    "number_of_territories": "{{number}} участков",
                    "filter": "Фильтр",
                    "assigned_only": "Только назначенные",
                    "unassigned_only": "Только неназначенные",
                    "never_worked": "Никогда",
                    "rarely_worked": "Редко",
                    "recently_worked": "Недавно",
                    "extra": "Дополнит.",
                    "speeches": "Речи",
                    "students": "Ученики",
                    "request": "Запрос",
                    "requests": "Запросы",
                    "my_requests": "Мои запросы",
                    "request_territory": "Запросить участок",
                    "request_territory_text": "Я хочу чтобы мне назначили участок: {{title}}",
                    "assigned_already": "Уже назначен",
                    "you_are_not_able_to_request_territories": "Вам нельзя назначать участки",
                    "no_territory_requests_yet": "Запросов на участки еще нет",
                    "preferences": "Настройки",
                    "new_schedule_view": "Новый вид графика встреч",
                    "change": "Изменить",
                    "only_for_mobile": "Только для телефонов",
                    "old": "Старый",
                    "new": "Новый",
                    "need_to_upload_meeting_workbook": "Нужно загрузить рабочую тетрадь",
                    "someone": "Кто-то",
                    "statistics": "Статистика",
                    "total_territories": "Всего участков",
                    "do_you_want_to_fetch_assignment_data_from_th": "Вы точно хотите скачать данные из TH?",
                    "assigned_territories_at_range": "Назначеные участки за этот период времени ({{type}})",
                    "worked_territories_at_range": "Обработанные участки за этот период ({{type}})",
                    "assigned_and_worked_territories_at_range": "Назначеные и обработанные участки за этот период ({{type}})",
                    "currently_assigned_territories": "На данный момент на руках",
                    "history_of_assignments": "История назначений",
                    "restore": "Восстановить",
                    "file": "Файл",
                    "files": "Файлы",
                    "upload_file": "Загрузить файл",
                    "file_is_uploaded": "Файл загружен",
                    "file_is_deleted": "Файл удален",
                    "rename_file": "Переименовать файл",
                    "new_file_name": "Новое название файла",
                    "wrong_file_type": "Неправильный тип файла",
                    "info": "Инфо",
                    "errors": "Ошибки",
                    "search": "Поиск",
                    "subscribe": "Подписаться",
                    "unsubscribe": "Отписаться",
                    "enable": "Включить",
                    "disable": "Выключить",
                    "push_notifications": "Push уведомления",
                    "push_notifications_enabled": "Push уведомления включены",
                    "push_notifications_already_enabled": "Push уведомления уже были включены",
                    "push_notifications_disabled": "Push уведомления выключены",
                    "not_able_to_set_up_push_notifications": "Нет возможности настроить Push уведомления",
                    "permission_is_not_granted_for_push_notifications": "Не дано разрешение на Push уведомления",
                    "configuration_for_push_notifications_is_not_provided": "Нет конфигурации для Push уведомлений",
                    "default_tab_in_home_page": "Таб по умолчанию на заглавной странице",
                    "go_to_settings_to_change": "Чтобы поменять настройки уведомлений, иди в настройки твоего устройства",
                    "message": "Сообщение",
                    "send_notification": "Отправить уведомление",
                    "message_is_sent": "Сообщение отправлено",
                    "loading": "Загрузка",
                    "basic_schedule_entry": "Обычная запись",
                    "this_feature_is_disabled": "Эта функциональность отключена",
                    "congregation_state": "Состояние",
                    "baptised_publishers": "Крещеные",
                    "anointed_publishers": "Помазанники",
                    "inactive_publishers": "Неактивные возвещатели",
                    "average_age": "Средний возраст",
                    "average_baptised": "В среднем крещены",
                    "service_groups": "Группы для служения",
                    "accounting_overall_amount": "Всего денег",
                    "accounting_income": "Доход",
                    "accounting_expenses": "Расходы",
                    "literature_requested_overall": "Всего запрошено литературы",
                    "territories_taken": "Взято участков",
                    "territories_returned": "Сдано участков",
                    "cart_records": "Кол-во записей на стенды",
                    "cart_publishers_approved": "Одобрено возвещателей на стенды",
                    "cart_publishers_served": "Служило возвещателей со стендами",
                    "congregation_state_text": "Статистика собрания собранная за прошлый месяц. Данные о служении за позапрошлый месяц, так как отчёты собираются в течении месяца",
                    "monday": "Понедельник",
                    "tuesday": "Вторник",
                    "wednesday": "Среда",
                    "thursday": "Четверг",
                    "friday": "Пятница",
                    "saturday": "Суббота",
                    "sunday": "Воскресенье",
                    "meeting_time": "Время встреч",
                    "severity": "Важность",
                    "low": "Низкая",
                    "medium": "Средняя",
                    "high": "Высокая",
                    "more": "ещё",
                    "receipt": "Квитанция",
                    "receipt_number": "№ квитанции",
                    "no_enough_receipt_data": "Недостаточно данных для квитанции",
                }
            }
        },
        fallbackLng: "en",
        debug: false,
        lng: process.env.REACT_APP_LANG,
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
